import { Component, ElementRef, EventEmitter, HostListener, Output, ViewChild, inject } from '@angular/core';
import { NotificationComponent } from './notification/notification.component';
import { NgClass, NgIf } from '@angular/common';
import { UserDropDownComponent } from './user-drop-down/user-drop-down.component';
import { AppHelper, LocalStorageService, SessionStorageService, StorageConstants, TabGetService } from '@techextensor/tab-core-utility';
import { FetchResult, HeadlessService, ISession } from '@novu/headless';
import { Constants } from '../../core/const/constants';
import { HelperFunctionService } from '../../core/services/helper-function.service';
import { MenuComponent, MenuModule } from '@syncfusion/ej2-angular-navigations';
import { MenuItemModel } from '@syncfusion/ej2-navigations';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AppSwtichComponent } from './tab-app-switch/tab-app-switch.component';
import { GlobalSearchComponent } from "./global-search/global-search.component";
import { TranslateService } from '@ngx-translate/core';
import { TabLanguageService } from '../../core/services/formio/tab-language.service';
import { TranslatePipe } from '../../core/pipes/translate-language.pipe';
import { PermissionService } from '../../core/services/permission.service';
import { OrgAppInfoHelperService } from '../../core/services/org-app-info-helper.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [NotificationComponent, UserDropDownComponent, NgIf, NgClass, MenuModule, AppSwtichComponent, TranslatePipe, GlobalSearchComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  panelStatus: boolean = true;
  isNotificationListVisible: boolean = false;
  isUserDropDownVisible: boolean = false;
  notificationCount: number = 0;
  showLoader:boolean =false;
  private _appHelper = inject(AppHelper);
  private _sessionStorageService: SessionStorageService = inject(SessionStorageService);
  private _localStorageService: LocalStorageService = inject(LocalStorageService);
  public headlessService: HeadlessService;
  private readonly _helperFunctionService: HelperFunctionService = inject(HelperFunctionService);
  public translateService: TranslateService = inject(TranslateService);
  public tabLanguageService: TabLanguageService = inject(TabLanguageService);
  public tabGetService: TabGetService = inject(TabGetService);
  private readonly permissionService: PermissionService = inject(PermissionService);
  private readonly router: Router = inject(Router);
  public readonly route: ActivatedRoute = inject(ActivatedRoute);
  public readonly _tabOrgAppInfoHelperService: OrgAppInfoHelperService = inject(OrgAppInfoHelperService);
  @Output() panelOpenClose = new EventEmitter<boolean>();
  @ViewChild('popup') popup: ElementRef | undefined;
  @ViewChild('menuObj') menuObj!: MenuComponent;
  @ViewChild('globalSearch') globalSearch!: GlobalSearchComponent;
  menuItems: any[] = [];
  isHorizontalLayout: boolean = false;
  orgInfo: any;
  enablescroll!: boolean;
  menuCssClass: string = '';
  fullScreen: boolean = false;
  selectedLanguage = Constants.Default_Language;
  languageList: any = [];
  activeScreen: any;
  // Listen for window resize events
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth(event);
  }

  /**
   * Creates a new instance of the HeadlessService.
   * This method is called only once, during the component's initialization.
   * It sets the application identifier, subscriber ID, backend URL, and socket URL
   * for the HeadlessService.
   * @returns {HeadlessService} The instance of the HeadlessService.
   */
  constructor() {
    this._sessionStorageService.setSessionStorage(StorageConstants.environmentMode, "Development");
   this.orgInfo = JSON.parse(this._localStorageService.getLocalStorage(StorageConstants.orgInfo))?.LocaleSettings;
    this.isHorizontalLayout = Boolean(this.orgInfo?.ShowHorizontalLayout);
    this.headlessService = new HeadlessService({
      applicationIdentifier: Constants.applicationIdentifier,
      subscriberId: this.getPersonIdFromLocalStorage(),
      backendUrl: Constants.backendUrl,
      socketUrl: Constants.socketUrl,
    });
    if(window.innerWidth <= 1600){
      this.enablescroll =  true;
      this.menuCssClass = this.enablescroll ? 'e-custom-scroll' : '';
    }
    this.getMenuLogo();
  }

  ngOnInit(): void {
    this.getLanguageList();
    // this.initializeSession().then(this.loadUnreadCount); 
    this.initializeSession().then(() => this.loadUnreadCount());
    if(this.isHorizontalLayout){
      this.loadHorizontalMenuitems();
      this.router.events.subscribe(event => { 
        if (event instanceof NavigationEnd) {
          this.updateActiveScreen();
        }
      });
    }
    this.selectedLanguage = this.tabLanguageService.getLanguage();
  }
  
  //get menu logo
  async getMenuLogo(){
    this.orgInfo.Org_Menu_Logo = await this._tabOrgAppInfoHelperService.getOrgLogo(this.orgInfo?.Org_Menu_Logo)
  }

  /**
   * Gets the language list from the API.
   * This method is called only once, during the component's initialization.
   */
  getLanguageList(){
    this._helperFunctionService.getDSQData(Constants.FK_TABD_SystemEnumDetails_TABD_SystemEnum_Id, {Id : Constants.Language_Enum_Id}).subscribe(
      (response: any) => {
        this.languageList = response.Result;
      }
    );
  }

  private updateActiveScreen() {
    let currentRoute = this.route;
    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
    }
    
    const screenId = currentRoute.snapshot.paramMap.get('screenId');
        
    if (screenId && screenId !== '') {
      this.activeScreen = screenId;
      this.clearPreviousActiveMenu();
      this.processActiveMenu();
    } else if (!screenId && this.activeScreen) {
      delete this.activeScreen;
      this.clearPreviousActiveMenu();
    }
  }

  /**
   * Checks the screen width and updates the enablescroll flag accordingly.
   * This method is called when the window is resized.
   * @param event The window resize event.
   */
  checkScreenWidth(event: any) {
    // Get the current screen width
    const screenWidth = window.innerWidth;

    // Check if the screen width is less than 1600px
    // If it is, enable scrolling for the menu
    this.enablescroll = screenWidth <= 1600;

    // Update the menu CSS class based on the enablescroll flag
    this.menuCssClass = this.enablescroll ? 'e-custom-scroll' : '';
  }
  /**
  * Initializes the session for the HeadlessService.
  * This method is called only once, during the component's initialization.
  * It returns a Promise that resolves to the session object.
  * If the session initialization fails, it rejects the Promise with the error.
  * @returns {Promise<ISession>}
  */
  private initializeSession(): Promise<ISession> {
    return new Promise((resolve, reject) => {
      this.headlessService.initializeSession({
        listener: (res: FetchResult<ISession>) => { },
        onSuccess: resolve,
        onError: (error) => {
          console.error('Session initialization error:', error);
          reject(error);
        }
      });
    });
  }

  /**
   * Retrieves the person ID of the currently logged in user from local storage.
   * If the user is not logged in, it returns null.
   * @returns {string | null} The person ID of the currently logged in user, or null if the user is not logged in.
   */
  getPersonIdFromLocalStorage() {
    let loginUserData = JSON.parse(this._localStorageService.getLocalStorage(StorageConstants.userInfo))?.user;
    return loginUserData?.Attributes?.PersonId?.toLowerCase() || null;
  }

  leftMenuSlide() {
    this.panelStatus = !this.panelStatus;
    this.panelOpenClose.emit(this.panelStatus);
  }

  /**
   * Toggles the visibility of the global search component. 
   */
  showSearch(){
    this.globalSearch.isShowGlobalSearch = true;
  }

  /**
   * Toggles the visibility of the notification list.
   */
  showNotification() {
    this.isNotificationListVisible = !this.isNotificationListVisible;
  }

  /**
   * Toggles the visibility of the user drop down list.
   */
  userDropDown() {
    this.isUserDropDownVisible = !this.isUserDropDownVisible;
  }
  /**
   * Rebuilds the application by calling the rebuildApp method from _appHelper service.
   * If the rebuild is successful (status code 200), it clears the IndexedDB and stores the schema into IndexedDB.
   */
  rebuildApp() {
    this.showLoader = true;
    this._appHelper.rebuildApp()?.subscribe((res) => {
      if (res && res.StatusCode == '200') {
        this.showLoader = false;
        // this.idbService.clearIndexedDB();
        // this._appHelper.storeSchemaIntoIDB();
        this._helperFunctionService.loadApp();
      } else {
        console.error('Rebuild failed or returned an invalid status code.');
      }
    }, (error) => {
      this.showLoader = false;
      console.error('Error occurred during rebuildApp subscription:', error);
    });
  }

  onChangeEnvironment(event: any) {
    this._sessionStorageService.setSessionStorage(StorageConstants.environmentMode, event.target.value);
  }

  /**
   * Removes a notification from the server.
   * @param notification {IMessage} The notification object to be removed.
   */
  public handleRemoveNotification(notification: any): void {
    if (notification.id) {
      this.headlessService.removeNotification({
        listener: () => {
          // Removal result processing if needed
        },
        onSuccess: () => {
          this.loadUnreadCount();
        },
        onError: (error: unknown) => {
          console.error('Error removing notification:', error);
        },
        messageId: notification.id,
      });
    }
  }

  /**
   * Marks a notification as read on the Novu server.
   * @param notification {IMessage} The notification object to be marked as read.
   */
  public handleMarkAsRead(notification: any): void { 
    if (!notification.read) {
      this.headlessService.markNotificationsAsRead({
        listener: () => {
          // Mark as read result processing if needed
        },
        onSuccess: () => {
          notification.read = true;
          this.loadUnreadCount();
        },
        onError: (error: unknown) => {
          console.error('Error marking as read:', error);
        },
        messageId: notification.id,
      });
    }
  }


  /**
   * Fetches the unread notification count from the Novu server.
   *
   * This method calls the `fetchUnreadCount` method of the `headlessService` with the appropriate parameters.
   * It then updates the `notificationCount` property with the received count.
   */
  public loadUnreadCount(): void {
    this.headlessService.fetchUnreadCount({
      listener: () => {
        // Unread count result processing if needed
      },
      onSuccess: (data: { count: number }) => {
        this.notificationCount = data.count;
      },
      onError: (error: unknown) => {
        console.error('Error fetching unread count:', error);
      },
    });
  }

    //Rebuild button and developer mode button hide/show
    isBuildAndDevelopeModeUsercanAccess() {
      // const userData = JSON.parse(this._localStorageService.getLocalStorage(StorageConstants.userInfo))?.user;
      // if (
      //   userData &&
      //   userData.Roles !== null &&
      //   userData.Roles.length > 0 &&
      //   userData.Roles[0]?.RoleName === Constants.System_Administrator_Role
      // ) {
      //     return true;
      // }

      // return false;
      //Permission has been added for the system administrator to display the rebuild button for the new tenant.
      return this.permissionService.doesUserIsTabAdministrator() || this.permissionService.doesUserIsSystemAdministrator();
  }

  /**
   * Loads the horizontal menu items from the DSQ data source.
   *
   * This method calls the `getDSQData` method of the `helperFunctionService` with the `Default_TABMD_Menu_Items_Id` constant as the parameter.
   * It then updates the `menuItems` property with the received data by calling the `generateMenuItemsFromData` method.
   */
  loadHorizontalMenuitems() {
    this._helperFunctionService.getDSQData(Constants.Default_TABMD_Menu_Items_Id)
    .subscribe((response: any) => {
      // Generate the menu items from the received data
      this.menuItems = this.generateMenuItemsFromData(response?.Result ?? []);
      setTimeout(() => {
        this.updateActiveScreen();
      },500);
    });
  }
  /**
   * Generates the menu items from the given data.
   *
   * This method maps the given data to an array of `MenuItemModel` objects.
   * It then sorts the menu items by their sequence and returns the sorted array.
   * @param data - The data used to generate the menu items.
   * @returns The generated menu items.
   */
  generateMenuItemsFromData(data: any[]): MenuItemModel[] {
    const menuMap = {} as Record<string, MenuItemModel>;
    const result: MenuItemModel[] = [];

    //Handle translations
    data?.forEach((menuItemData) => {
      this.translateService.get(menuItemData.DisplayName || menuItemData.MenuName).subscribe((translatedMenuName) => {
        const menuItem = {
          id: menuItemData.Id,
          screenId: menuItemData.ScreenID ?? null,
          text: translatedMenuName, // Use translated text
          iconCss: `${menuItemData.Icon} e-icons`,
          items: [],
          isSelected: false,
        Sequence: menuItemData.Sequence,
        };
        menuMap[menuItemData.Id] = menuItem;
      });
    });

    data?.forEach((menuItemData) => {
      const menuItem = menuMap[menuItemData.Id];
      if (menuItemData.ParentID && menuMap[menuItemData.ParentID]) {
        menuMap[menuItemData.ParentID]?.items?.push(menuItem);
        menuMap[menuItemData.ParentID]?.items?.sort((a, b) => {
          const aItem = data?.find((d) => d.Id?.toString() === a.id);
          const bItem = data?.find((d) => d.Id?.toString() === b.id);
          return (aItem?.Sequence || 0) - (bItem?.Sequence || 0);
        });
      } else {
        result.push(menuItem);
      }
    });

    result?.sort((a, b) => {
      const aItem = data?.find((d) => d.Id?.toString() === a.id);
      const bItem = data?.find((d) => d.Id?.toString() === b.id);
      return (aItem?.Sequence || 0) - (bItem?.Sequence || 0);
    });

    return result;
  }

  /**
   * Handles the click event on a menu item.
   *
   * If the menu item has a link, navigates to that link.
   * @param args - The event arguments containing the clicked menu item.
   */
  public onMenuItemClicked(args: any) {
    if (args?.item?.screenId && args?.item?.screenId != '') {
      this._sessionStorageService.removeSessionStorage(StorageConstants.screenContext);
      const applicationCode = this._sessionStorageService.getSessionStorage(StorageConstants.applicationCode);
      this.router.navigate([`/${applicationCode}/screen/${args.item.screenId}`]);
    }
  }


  /**
   * Toggles the full screen mode of the application.
   *
   * If the application is not in full screen mode, it will request the full screen
   * mode. If the application is already in full screen mode, it will exit the full
   * screen mode.
   */
  onFullScreenClick() {
    const element =document.querySelector('body') as HTMLElement;
    this.fullScreen = !this.fullScreen;
    this._helperFunctionService.toggleFullScreen(element);
  }

  public onMenuItemBeforeOpen(event: any) {
    event?.items?.forEach((item: any) => {
      if(item.isSelected){
        this.highlightMenuItem(event.element, item.id);
      }
    })
  }

  private processActiveMenu() { 
    if (!this.activeScreen || !this.menuItems.length) return;
  
    const findAndHighlightMenuItem = (items: any[]): boolean => {
      for (const item of items) {
        if (item?.screenId?.toLowerCase() === this.activeScreen?.toLowerCase()) {
          item.isSelected = true;
          this.highlightMenuItem(this.menuObj.element, item.id);
          return true;
        }
        if (item?.items?.length && findAndHighlightMenuItem(item.items)) {
          item.isSelected = true;
          this.highlightMenuItem(this.menuObj.element, item.id);
          return true;
        }
      }
      return false;
    };
  
    findAndHighlightMenuItem(this.menuObj.items);
  }
  
  private highlightMenuItem(element: any, itemId: string) {    
    const activeItem = element?.querySelector(`li[id="${itemId}"]`);
    if (activeItem) {
      activeItem.classList.add('selected-item');
    }
  }

  private clearPreviousActiveMenu() {
    const clearHighlights = (items: any[]) => {
      for (const item of items) {
        item.isSelected = false;
        this.clearHighlightedMenuItem(this.menuObj.element, item.id);
        if (item.items?.length) {
          clearHighlights(item.items);
        }
      }
    };
  
    clearHighlights(this.menuObj.items);
  }

  private clearHighlightedMenuItem(element: any, itemId: string) {
    const activeItem = element?.querySelector(`li[id="${itemId}"]`);
    if (activeItem) {
      activeItem.classList.remove('selected-item');
    }
  }

  /**
   * Switches the language of the application.
   * @param event - The event containing the selected language.
   * @returns void
  **/
  onChangeLanguage(event: any): void {
    const selectedLanguage = this.languageList.find((language:any) => language.SystemValue === event.target.value);
    this.tabLanguageService.switchLanguage(selectedLanguage).then(() => {   this.loadHorizontalMenuitems(); }); //for testing not working from service
  }
}

// Get the data of the clicked menu item
// const ActiveMenuScreenId = menuItemData.screenId?.match(/\/screen\/(.*)/)[1];
// this._sessionStorageService.setSessionStorage('ActiveMenuScreenId', ActiveMenuScreenId);
// this.highlightSelectedMenuItem(args.item.id);

//  onMenuCreated() {
//   setTimeout(() => {
//     const domMenuItemslist = this.menuObj?.element?.querySelectorAll('li');
//     const ActiveMenuScreenId = this._sessionStorageService.getSessionStorage('ActiveMenuScreenId');
//     if (this.menuItems) {
//       this.menuItems?.forEach((menuItem) => {
//         if (menuItem.screenId?.includes(ActiveMenuScreenId) ) {
//           this.setSelectedclass(domMenuItemslist, menuItem)
//         } else {
//           this.processMenuWhileCreated(menuItem?.items, ActiveMenuScreenId, menuItem, domMenuItemslist)
//         }
//       });
//     }
//   }, 200)
// }

// /**
//  * This function is called when the menu is created and the selected item needs to be highlighted.
//  * It iterates over the menu items and checks if the item's id matches the given idFromUrl. If it does, it adds the
//  * If the item's id does not match the given idFromUrl, it recursively calls the function on the item's children.
//  * @param menuItems the list of menu items
//  * @param ActiveMenuScreenId the id of the selected screen
//  * @param menuItem the parent menu item
//  * @param domMenuItemslist the list of menu items in the DOM
//  */
// processMenuWhileCreated(menuItems: any, ActiveMenuScreenId: any, menuItem: any, domMenuItemslist?: any) {
//   if (menuItems?.length > 0) {
//     menuItems?.forEach((item: any) => {
//       if (item.screenId?.includes(ActiveMenuScreenId)) {
//         this.setSelectedclass(domMenuItemslist, menuItem)
//       } else {
//         this.processMenuWhileCreated(item?.items, ActiveMenuScreenId, menuItem, domMenuItemslist)
//       }
//     })
//   }
// }
// /**
//  * Sets the selected class on the menu item with the given id.
//  * This function iterates over the menu items and removes the 'selected-item' class from all items.
//  * If the item's id matches the given id, it adds the 'selected-item' class to the item.
//  * @param menuItems the list of menu items
//  * @param menuItem the menu item with the id to be selected
//  */
// setSelectedclass(menuItems: any, menuItem: { id: any; }) {
//   menuItems?.forEach((item: any) => {
//     item?.classList?.remove('selected-item');
//     if (item?.getAttribute('id') === menuItem?.id) {
//       item?.classList?.add('selected-item');
//     }else{
//     }
//   });
// }

// highlightSelectedMenuItem(selectedItemId: string): void {
//   const currentUrl = this.router.url;
//   const linkPart = currentUrl?.match(/\/screen\/[^\/]+/);
//   if (linkPart) {
//     const domMenuItemslist = this.menuObj.element.querySelectorAll('li');
//     domMenuItemslist?.forEach((menuItem) => {
//       menuItem?.classList?.remove('selected-item');
//       if (menuItem.getAttribute('id') === selectedItemId) {
//         menuItem.classList.add('selected-item');
//       } else {
//         if (this.menuItems) {
//           this.menuItems?.forEach((childMenu) => {
//             this.processMenuAfterClicked(childMenu.items, selectedItemId, childMenu, domMenuItemslist)
//           })
//         }
//       }
//     });
//   }
// }

// /**
//  * This function is called when the menu is created and the selected item needs to be highlighted.
//  * It iterates over the menu items and checks if the item's id matches the given idFromUrl. If it does, it adds the
//  * If the item's id does not match the given idFromUrl, it recursively calls the function on the item's children.
//  * @param menuItems the list of menu items
//  * @param selectedItemId the id of the selected screen
//  * @param childMenu the parent menu item
//  * @param domMenuItemslist the list of menu items in the DOM
//  */
// processMenuAfterClicked(menuItems: any, selectedItemId: any, childMenu: any, domMenuItemslist?: any) {
//   menuItems?.forEach((childItem: any) => {
//     if (childItem?.id === selectedItemId) {
//       domMenuItemslist?.forEach((menuItem: any) => {
//         menuItem?.classList?.remove('selected-item');
//         if (menuItem?.getAttribute('id') === childMenu?.id) {
//           menuItem?.classList?.add('selected-item');
//         }
//       })
//     } else {
//       this.processMenuAfterClicked(childItem?.items, selectedItemId, childMenu, domMenuItemslist)

//     }
//   })
// }