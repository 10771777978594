@if (isVisible) {
<div class="modal fade show d-block" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header" *ngIf="config.displayHeader">
        <h5 class="modal-title">{{ config.title }}</h5>
        <button type="button" class="close" (click)="onCancel()" *ngIf="config.displayCloseButton">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="config.displayBody">
        {{ config.message }}
      </div>
      <div class="modal-footer" *ngIf="config.displayFooter">
        <button type="button" class="btn btn-primary" (click)="onConfirm()" *ngIf="config.displayPrimaryButton">
          {{ config.primaryOption }}
        </button>
        <button type="button" class="btn btn-danger" (click)="onCancel()" *ngIf="config.displaySecondaryButton">
          {{ config.secondaryOption }}
        </button>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade show"></div>
</div>
}
