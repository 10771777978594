import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { SessionStorageService, StorageConstants } from '@techextensor/tab-core-utility';
import { environment } from '../../../environments/environment';

/**
 * Guard for routes that should only be accessible when the user is not authenticated.
 * If the user is not authenticated, allows access to the route.
 * If the user is authenticated, redirects to the main screen of the app.
 */
export const noAuthGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const sessionStorageService = inject(SessionStorageService);
  const router = inject(Router);

  return authService.check().pipe(
    map(authenticated => {
      if (!authenticated) {
        return true;
      } else {
        const match = document?.location?.pathname?.match(/^\/([^\/]+)/);
        const appCode = match?.[1] ?? sessionStorageService.getSessionStorage(StorageConstants.applicationCode) ?? environment.commonConfig.applicationCode;
        let url = `/${appCode}/screen/`;
        const redirectURL = window?.location?.pathname?.split('redirectURL=')?.[1];

        if (redirectURL && redirectURL.trim() !== '') {
          url += redirectURL;
        }

        return router.createUrlTree(['']);
      }
    })
  );
};