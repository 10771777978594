import { inject, Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Constants, i18n } from "../../const/constants";
import { TabGetService } from "@techextensor/tab-core-utility";
import { TranslateService } from "@ngx-translate/core";
import { L10n, setCulture } from "@syncfusion/ej2-base";

@Injectable({
  providedIn: 'root'
})
export class TabLanguageService {

  public currentLanguageSubject = new BehaviorSubject<string>(Constants.Default_Language); // Default language
  public tabGetService: TabGetService = inject(TabGetService);
  private translateService: TranslateService = inject(TranslateService);


  /**
   * Gets the current language
   **/
  getLanguage(): string {
    return this.currentLanguageSubject.getValue();
  }

  /**
   * Switches the language of the application.
   * @param event - The event containing the selected language.
   * @returns void
   **/
  switchLanguage(selectedLanguage: any): Promise<void> {
    return new Promise((resolve, reject) => {
      if (selectedLanguage && selectedLanguage?.EnumDetailId) {
        this.tabGetService.getResourceLanguageByLanguageCode(selectedLanguage.EnumDetailId).subscribe({
          next: (response: any) => {
            if (response?.Result) {
                this.updateSyncfusionLibrary(selectedLanguage.SystemValue, JSON.parse(response.Result));
                this.updateFromIoTranslations(selectedLanguage.SystemValue, JSON.parse(response.Result));

                this.translateService.use(selectedLanguage.SystemValue);
                this.currentLanguageSubject.next(selectedLanguage.SystemValue);

                resolve();
            } else {
              console.error('No result found in the response');
              reject(new Error('No result found in the response'));  // Reject when no result is found
            }
          },
          error: (error: any) => {
            console.error('Error fetching language resources:', error);
            reject(error);  // Reject the promise in case of an HTTP error
          }
        });
      } else {
        console.error('Invalid language selection or missing EnumDetailId');
        reject(new Error('Invalid language selection or missing EnumDetailId'));  // Reject the promise if input validation fails
      }
    });
  }
  

  /**
   * Updates the translations to L10n syncfusion library global object.
   * this global locale object commonly used for all syncfusion components.
   * @param languageCode - The code of the selected language.
   * @param translationJSON - The translation JSON object.
   * @returns void
   **/
  private updateSyncfusionLibrary(languageCode: string, translationJSON: any): void {
    if (L10n && typeof L10n.load === 'function') {
      L10n.load({
        [languageCode]: {
          'grid': {...translationJSON},
          'pager': {...translationJSON},
          'querybuilder': {...translationJSON}
        }
      });
      setCulture(languageCode);
    }
  }

  /**
   * Updates the translations to i18n global object for fromio current language.
   * @param languageCode - The code of the selected language.
   * @param translationJSON - The translation JSON object.
   * @returns void
   **/
  private updateFromIoTranslations(languageCode: string, translationJSON: any): void {
    i18n[languageCode] = {...translationJSON};
  }


}
