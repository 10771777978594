import { inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ApiService, LocalStorageService, SessionStorageService, StorageConstants, TabGetService } from '@techextensor/tab-core-utility';
import { Title } from '@angular/platform-browser';
import { Constants } from '../const/constants';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class OrgAppInfoHelperService {
    private readonly _title: Title = inject(Title);
    private readonly _tabGetService: TabGetService = inject(TabGetService);
    private readonly _sessionStorageService: SessionStorageService = inject(SessionStorageService);
    private readonly _localStorageService: LocalStorageService = inject(LocalStorageService);
    private readonly _tabToasterService: ToastrService = inject(ToastrService);
    private readonly _apiService: ApiService = inject(ApiService);


    /**
     * Constructor for OrgAppInfoHelperService
     * Initializes the service and checks for any invalid subdomains stored in local storage.
     */
    constructor() {
        // Check if there's an invalid subdomain stored in local storage
        const invalidSubdomain = this._localStorageService.getLocalStorage(Constants.invalidSubdomain);
        
        if (invalidSubdomain) {
            // Display an error toast notification if invalid subdomain exists
            this._tabToasterService.error(`${invalidSubdomain} does not exist.`, 'Error', { positionClass: 'toast-bottom-right' });
            
            // Remove the invalid subdomain entry from local storage
            this._localStorageService.removeLocalStorage(Constants.invalidSubdomain);
        }
    }

    /**
     * Loads organization information from the server.
     * @returns The organization information in JSON format.
     */
    public async loadOrgInfo(): Promise<any> {
        try {
            // Extract domain info
            const { subdomain, domain } = this.extractDomainInfo();

            // Prepare request tokens
            // If the subdomain is present, use it as the request token.
            // Otherwise, use the tenant ID.
            const reqtokens = subdomain ? { Subdomain: subdomain } : { Id: Constants.TenantId };

            // Execute API call and await response
            const orgInfoRes: any = await firstValueFrom(this._tabGetService.executeRAWDSQWithName({
                AppObjectName: Constants.TABD_OrgInfo,
                DSQName: Constants.Get_Subdomain_TABD_OrgInfo,
                Reqtokens: reqtokens,
            }));

            // Validate response
            if (!orgInfoRes?.IsSuccess || orgInfoRes?.StatusCode != '200') {
                throw new Error('Invalid or empty response');
            }

            const result = orgInfoRes?.Result?.[0];

            // Handle invalid subdomain
            // If the subdomain is invalid, store it in local storage and redirect to the main domain.
            if (subdomain && !result) {
                this._localStorageService.setLocalStorage(Constants.invalidSubdomain, subdomain);
                window.location.href = `https://${domain}`;
            }

            // Process and store organization info
            // Parse the locale settings and add the organization name to it.
            const infoData: any = result || {};
            infoData.OrganizationName = result?.OrganizationName;
            this.setOrgInfo(infoData);

            return result;

        } catch (error) {
            console.error('Error fetching organization information:', error);
            return null;
        }
    }


    /**
     * Sets the organization information to the local storage.
     * @param data Organization information as a JSON object.
     */
    public setOrgInfo(data: any) {
        // Process the organization information and set it to the local storage
        this.processOrgInfo(data);

        // Set the organization information in the local storage
        this._localStorageService.setLocalStorage(StorageConstants.orgInfo, data);
    }

    /**
     * Retrieves the organization information from the local storage.
     * @returns The organization information as a JSON object or null if not found.
     */
    public getOrgInfo(): any {
        return this._localStorageService.getLocalStorage(StorageConstants.orgInfo);
    }

    /**
     * Loads application information from the server.
     * @returns The application information in JSON format or throws an error if the app is not found.
     */
    public async loadAppInfo(): Promise<any> {
        try {
            // Extract app code from the URL path with fallback to the environment variable
            const appCode = document?.location?.pathname?.match(/^\/([^\/]+)/)?.[1] ?? environment.commonConfig.applicationCode;

            // Fetch the app detail using the app code
            const appDetailResponse: any = await firstValueFrom(this._tabGetService.executeRAWDSQWithName({
                AppObjectName: Constants.TABD_AppInfo,
                DSQName: Constants.Get_App_Detail,
                Reqtokens: {
                    AppName: appCode,
                },
            }));

            if (appDetailResponse?.IsSuccess && appDetailResponse?.StatusCode == '200' && appDetailResponse?.Result?.[0]) {
                // Set the app details in the session storage
                this.setAppInfo(appDetailResponse.Result[0]);
                return appDetailResponse.Result[0];
            } else {
                // Display an error message if the app is not found
                throw new Error('App not found');
            }

        } catch (error: any) {
            // Display an error message if there is an error
            this._tabToasterService.error(error.message, 'Error', { positionClass: 'toast-bottom-right' });
            
            // const appDetail = {
            //     AppName: environment.commonConfig.applicationCode,
            //     Id: environment.commonConfig.applicationId
            // }

            // Set the app details to default values if the app is not found
            // this.setAppInfo(appDetail);

            // return appDetail;
            return null;
        }
    }


    /**
     * Sets the application code, ID and details in the session storage.
     * @param appDetail The application details to set.
     */
    public setAppInfo(appDetail: any) {
        // Set the application code in the session storage
        this._sessionStorageService.setSessionStorage(StorageConstants.applicationCode, appDetail.AppName);
        
        // Set the application ID in the session storage
        this._sessionStorageService.setSessionStorage(StorageConstants.AppId, appDetail.Id);
        
        // Set the application details in the session storage
        this._sessionStorageService.setSessionStorage(Constants.appInfo, appDetail);
    }

    /**
     * Retrieves the application information from the session storage.
     * @returns The application information stored in the session storage.
     */
    public getAppInfo() {
        // Get the application information from the session storage
        return this._sessionStorageService.getSessionStorage(Constants.appInfo);
    }

    extractDomainInfo() {
        const hostname = window.location.hostname;

        // Check if it's an IP address
        const ipRegex = /^(\d{1,3}\.){3}\d{1,3}$/;
        if (ipRegex.test(hostname)) {
            return { subdomain: null, domain: hostname };
        }

        // Split the hostname by dots
        const parts = hostname.split('.');

        // Handle different cases based on the number of parts
        if (parts.length <= 2) {
            return { subdomain: null, domain: hostname };
        } else {
            const domain = parts.slice(-2).join('.');
            const subdomain = parts.slice(0, -2).join('.');
            return { subdomain, domain };
        }
    }

    /**
     * Processes the organization information and sets the title of the page,
     * favicon URL, and the organization information in the local storage.
     * @param data The organization information as a JSON object.
     */
    public async processOrgInfo(data: any) {
        if(data && data === 'string'){
            data = JSON.parse(data);
        }
        // Set the title of the page
        if(data?.OrganizationName || data?.TenantName){
            this._title.setTitle(data?.OrganizationName ||  data?.TenantName);
        }

        if(data && typeof data?.LocaleSettings === 'string' ){
            data.LocaleSettings = JSON.parse(data.LocaleSettings)
        }
        // Set the favicon URL
        const favIcon: any = document.querySelector('#appIcon');
        if (favIcon && data?.LocaleSettings?.FavIcon) {
            try {
                // Check if FavIcon is an object, convert to string if needed
                const favIconUrl = typeof data?.LocaleSettings?.FavIcon === 'object'
                    ? data?.LocaleSettings?.FavIcon?.url || data?.LocaleSettings?.FavIcon?.path || ''
                    : data?.LocaleSettings?.FavIcon;

                // Only proceed if favIconUrl is a non-empty string
                if (favIconUrl && typeof favIconUrl === 'string') {
                    // Check if the favicon URL contains GetImage?path=
                    if (favIconUrl.includes('GetImage?path=')) {
                        try {
                            // Fetch the favicon
                            const blob: any = await firstValueFrom(this._apiService.getByUrl(
                                `${favIconUrl}`,
                                { responseType: 'blob' }
                            ));
                            // Set the favicon
                            favIcon.href = URL.createObjectURL(blob);
                        } catch (error) {
                            // Log the error
                            console.error('Error fetching favicon:', error);
                            // Fallback to a default favicon or do nothing
                        }
                    } else {
                        // Set the favicon directly
                        favIcon.href = favIconUrl;
                    }
                }
            } catch (error) {
                console.error('Error processing favicon:', error);
            }
        }

        // Set the organization information in the local storage
        if (data?.LocaleSettings?.Org_Brand_Color &&
            typeof data?.LocaleSettings.Org_Brand_Color === 'string' &&
            /^#([0-9A-F]{3}){1,2}$/i.test(data?.LocaleSettings.Org_Brand_Color)) {
            document.documentElement.style.setProperty('--primary-color', data?.LocaleSettings.Org_Brand_Color, 'important');
        }

    }

    async getOrgLogo(Org_Logo: any) {
        if (Org_Logo && Org_Logo != undefined) {
            if (Org_Logo.includes('GetImage?path=')) {
                try {
                    const blob: any = await firstValueFrom(this._apiService.getByUrl(
                        `${Org_Logo}`,
                        { responseType: 'blob' }
                    ));
                  return URL.createObjectURL(blob);
                } catch (error) {
                    return console.error('Error fetching logo:', error);
                }
            } else {
                return Org_Logo = Org_Logo.Org_Logo;
            }
        }
    }
}