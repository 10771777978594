import { environment } from '../../../environments/environment';

export class Constants {
  // default value related stuff
  // public static DEFAULT_APPCODE = 'TAB_Rewrite';
  // public static DEFAULT_APPCODE = 'PMS_Clone';
  // public static DEFAULT_APPCODE = environment.commonConfig.DEFAULT_APPCODE;
  // public static DEFAULT_APPCODE = 'PMS';

  public static AppId = environment.commonConfig.applicationId;

  //default tenant id
  public static TenantId = environment.commonConfig.tenantId;
  // public static TenantId ="A5E7D0D1-0E92-422F-A85C-9DAC28375172"
  // public static TenantId ="F8B0261F-5862-460E-A324-2C04A9A1D876"

  // appobjects
  public static TABD_Comments = 'TABD_Comments';
  public static TABD_RecordInfo = 'TABD_RecordInfo';
  public static Tabd_CustomViews = 'TABD_CustomViews';
  // public static TABD_Templates = 'TABD_Templates';
  public static TABMD_DataSourceQueries_Fields = 'TABMD_DataSourceQueries_Fields';
  public static TABD_Transitions = 'TABD_Blueprint_Transitions';
  public static TABD_RecordInfo_Share = 'TABD_RecordInfo_Share';
  public static TABD_Custom_Scripting = 'TABD_Custom_Scripting';
  public static TABD_UserPersonalization = 'TABD_UserPersonalization';
  public static TABD_OrgInfo = 'TABD_OrgInfo';
  public static TABD_BlueprintStatus = 'TABD_BlueprintStatus';
  public static TABMD_DataSourceQueries = 'TABMD_DataSourceQueries';
  public static AspNetUsers = 'AspNetUsers';
  public static TABD_ImportDataConfig = 'TABD_ImportDataConfig';
  public static TABD_SystemEnumDetails = 'TABD_SystemEnumDetails';
  public static TABD_AppInfo = 'TABD_AppInfo';
  public static TABD_Reaction = 'TABD_Reaction';
  public static TABD_WorkFlows = 'TABD_WorkFlows';
  public static TABD_HttpRequests = 'TABD_HttpRequests';
  // DSQ
  // public static Detail_TABD_Templates = 'Detail_TABD_Templates';
  public static Default_TABD_RecordInfo_Share = 'Default_TABD_RecordInfo_Share';
  public static Detail_TABD_CustomViews = 'Detail_TABD_CustomViews';
  public static AppScreen_TABD_Custom_Scripting = 'AppScreen_TABD_Custom_Scripting';
  // public static Default_TABD_UserPersonalization = 'Default_TABD_UserPersonalization';
  public static Detail_TABD_OrgInfo = 'Detail_TABD_OrgInfo';
  public static Get_Subdomain_TABD_OrgInfo = 'Get_Subdomain_TABD_OrgInfo';
  public static List_CustomViewForPivot = 'List_CustomViewForPivot';
  public static Email_AspNetUser = 'Email_AspNetUser';
  public static FK_TABD_SystemEnumDetails_TABD_SystemEnum = 'FK_TABD_SystemEnumDetails_TABD_SystemEnum';
  public static Language_Enum_Id = '98a85ebe-d72e-4e10-89f8-31b46f196942';
  public static Get_App_Detail = 'Get_App_Detail';
  // public static List_Tenantwise_TABD_AppInfo = 'List_Tenantwise_TABD_AppInfo';
  public static TABD_HttpRequests_BY_ID = 'TABD_HttpRequests_BY_ID';
  // URLS
  public static LOGIN_URL = 'identity/login';
  public static SIGNUP_URL = 'Tenant/RegisterTenant';

  public static ORGINFO_SETTING_SCREEN = '/org-setup/1f7e0b27-99f7-4dc6-a182-be843586b766';
  public static MARKETPLACE_SCREEN = '/org-setup/fb0e2a89-85e7-4472-8e22-c0d7ef2153c9';
  // FILEMANAGER URL
  public static FILEMANAGER_URL = 'FileManager/';
  public static FILEOPERATIONS = 'FileOperations';
  public static GETIMAGE = 'GetImage';
  public static UPLOAD = 'Upload';
  public static DOWNLOAD = 'Download';
  // values
  // configurations values
  public static Design_CSS_Configuration_ID = '9243E77A-594F-4D4E-B7EF-E927FA2FCA24';

  // screens ids values
  public static SCREEN_DESIGNER_SCREENID = '3ab79148-f39b-43bf-9e3f-3fbe8ce86b3d';
  public static Custom_View_ScreenID = 'b4d08986-754f-42d3-bef1-620be4877a1f';
  // public static EventActionConfiguration_Configuration_ScreenID = 'fde329a6-4116-4d96-9b03-794fc098be7e';
  public static EventActionPreview_ScreenID = 'c0f9a719-a638-40c5-a943-6d85e6f454d5';
  public static Customview_Configuration_ScreenId = '78980d84-fce5-4e53-8f40-7e66c1c8b072';
  public static Transition_Preview_ScreenId = '50e06279-7a06-4181-9964-415a7b4db855';
  public static Comment_ScreenId = '653c261c-7fe6-4210-8cf3-93674d297245';
  public static History_ScreenId = '2dd85b9d-067b-4675-907e-4c9464eced07';
  public static Tab_Rewrite_Share_With_ScreenId = '8dadab5c-d498-412d-aaaf-535d24aad56e';
  public static Tab_Appinfo_ScreenId = '316415f0-91bc-46d8-8887-72e4148bae8d';
  public static Action_Triggerer_Configuration_ScreenId = 'b41ee19c-c3dd-4cf1-b1b3-be03b6a75cdb';
  public static TABD_ImportDataConfig_Id = 'BBAA59DB-B841-42BA-ADBA-CBEB8D36A0D6';
  // PMS Screen
  public static DashboardScreen = 'f2fbcbe0-73e4-4154-a368-d80e5d4c2974';

  // appobjects ids values
  public static TABD_Comments_Id = '5D738594-A105-4100-BF90-5D74CC63B8E4';
  public static TABMD_DataSourceQueries_Fields_Id = '80921CE9-71BA-4AED-89F9-517F0D9FFF3B';
  public static TABD_Transitions_Id = 'EC127870-79B4-4CCB-9478-E1AB8A87EC0F';
  public static TABD_BlueprintStatus_Id = 'D531A98A-64A4-4A12-A3B3-D0995A5FA147';
  public static TABD_RecordInfo_Share_Id = '658A097A-8C16-4793-A4E5-05439D29AC46';
  public static Tabd_CustomScripting_Id = 'B00CF923-DF04-484E-8088-E283BBD245D3';
  public static Tabd_CustomScripting_addScreenId = 'ee1274ff-0d7a-4576-a79d-340e7f10b673';
  public static Tabd_CustomViews_Id = 'BB51C753-B510-4F39-BA38-48CE94CC9E5F';
  public static TABD_UserPersonalization_Id = '259AC852-5961-40C4-B020-2C5D7A3B4D93';
  public static TABD_AppInfo_Id = 'FBF404DE-FB43-4AAC-9C3B-3C62275D39A2';
  public static TABD_Subscriptions_Users_Id = '60E3626D-F68F-44C1-8419-B774230F22BF';
  public static TABD_Reaction_Id = '463B4225-F868-4D0C-97E3-83702430D3AF';
  public static TABD_HttpRequests_Id = 'B0EB237C-D0B0-49FC-A072-24D2FFFCF0C2';

  // DSQ ID values
  public static TABMD_DataSourceQueries_Fields_Grid_FieldConfig_Id = '729df54b-80a1-4aa5-b004-bcfd3fb0adc2';
  public static Default_TABMD_Permissions_Id = '440914b8-85f5-4b5b-ab37-954bbb91c697';
  public static Default_TABD_Comments_Id = '4CF32034-5E58-4FB3-B98C-CEB685FDA1D7';
  public static Detail_Tabd_CustomViews_Id = '0CBC6060-2B7C-41CC-BAF6-F5B7776F9F34';
  public static Default_TABD_Transitions_Id = '7485A8B6-7AAF-4E1E-BB8C-F3C5AB7BA857';
  public static Default_TABD_BlueprintStatus_Id = '12D76EFA-B7A7-4A85-9167-49F9AF4B232D';
  public static Detail_TABD_RecordInfo_ID = 'c71bffdb-f45d-43b8-bdd8-b93fc41960b0';
  public static Default_TABD_CustomViews_Id = 'EAE18542-56D8-487F-B3CD-A254A7FC0C26';
  public static AppScreen_TABD_Custom_Scripting_Id = '7844a631-10d3-407d-a2f6-733629a464c6';
  public static Get_UserPersonalize_Data_Id = '5002c887-6d7d-4117-b110-d36cdc307ddc';
  // public static Default_TABD_UserPersonalization_Id = '6985A91F-ED03-4829-A0E2-767759736CA8';
  public static Get_UserWise_Personalize_Data_Id = '9df4a8ae-8300-4161-b118-a8c1934901a6';
  public static Grid_CustomViewList_Id = '1F348F40-011B-45B4-9D93-96A7243CCDF1';
  // public static Detail_TABMD_Configurations_Id = 'E5A6986D-4031-41AA-8EE0-137FA6C51929';
  public static List_Blueprint_Status_Details_Id = '578EAE60-1940-4F62-B857-ECCA3FC1B276';
  public static List_Blueprint_Transitions_Details_Id = 'EFACC7F3-18D1-4A9D-9C27-A0401610B3F2';
  public static List_TABD_Comments_Tab_Rewrite_Id = 'CA69A8D7-2B6F-49AE-B259-03442D445234';
  public static FK_TABMD_DataSourceQueries_Parameters_TABMD_DataSourceQueries_Id =
    'CDB70ED7-9A2B-4B11-92E3-433526056BC5';
  public static FK_TABD_RecordInfo_Transitions_TABD_Transitions_Id = '5247F8FC-440E-4547-8C49-353523155B0E';
  public static SLASchemes_TABD_Blueprint_SLASchemeDetails_Id = 'D04BA3BE-9B55-412E-9B31-021BAC38E33F';
  public static Default_TABMD_Menu_Items_Id = 'd72fb90b-e13c-4c4b-b192-2990e97dcc65';
  public static Detail_TABD_Templates_Id = '3628774d-b2f2-44a2-be9e-2b5e10b94dad';
  public static List_UserwiseTenantsView = '7175E305-A3BD-435C-8612-A6B9D82B8CF2';
  // public static List_Tenantwise_TABD_AppInfo_Id = 'C0647911-CA50-4BAA-9BD0-ED8A63308413';
  public static GetSubscribedApps_ActiveUserSubscriptionsView_Id = 'ba4d093b-2548-4e3b-80fb-d80861ff4075';
  public static FilterSort_TABMD_DataSourceQueries_Id = '075248f5-2d43-4ce0-8077-cdd14947e4e7';
  public static List_CustomViewForPivot_Id = '98673332-B04E-4D81-9B8D-58EDE267B7C8';
  public static Get_Default_CustomView_Id = 'cc802f18-0f71-491c-8d16-14c451658f5c';
  public static Get_Custom_Scripting_byScreenId = '386e131e-ef36-4710-8bd2-7da9110dc884';
  public static Default_CustomScriptingView_Id = '291C4EA8-441A-4A39-A926-3054D86800CD';
  public static Default_TABMD_DataSourceQueries_Fields_Id = '91275799-376F-45DF-8BEA-E51CF8A17021';
  // public static Default_TABD_RecordInfo_Share_Id = '9D27A129-60F2-4097-8488-2164F63A80C7';
  public static FK_TABD_RecordInfo_Share_TABD_RecordInfo = 'ee08f71d-12f4-42d5-ba4a-8efdd9f76324';
  public static FK_TABMD_DataSourceQueries_Fields_TABMD_DataSourceQueries_Id = 'C4DF2757-F309-4C40-98F2-5C500C1E11E4';
  public static Email_AspNetUser_Id = 'bb23f235-7f8a-4f16-a1df-e5bc38cf8166';
  public static List_TABD_BlueprintStatus_Id = 'df637847-fd37-4baf-b3d4-cd699a593c55';
  public static Detail_TABMD_Custom_Scripting_Id = 'd30b88ba-fad1-4054-ab2a-5311a7ddb2bd';
  public static FK_TABD_SystemEnumDetails_TABD_SystemEnum_Id = 'bba3da70-d120-46af-ac3f-babe13b7318c';
  public static GetConfigurations_Id = '67EB925B-D057-488A-8E30-7D395BFD69C2';
  public static List_TABD_ReactionsId = '2160a903-02dd-4ce9-a3ab-78f6a7031f5f';
  public static Get_Workflow_Detail_Id = 'e601cb36-97ed-412a-82fd-4333966ba801';
  public static Detail_TABD_ImportDataConfig_Id = 'da8f1d08-5ac3-4c22-a96b-d59b232042e0';
  public static Detail_TABD_OrgInfo_Id = '65FADA08-713E-4012-90A7-12D13C56C150';

  // session storage keys
  public static layoutConfigs = 'layoutConfigs';
  public static screenNavigationMapping = 'screenNavigationMapping';
  public static tabUserPermissions = 'tabUserPermissions';
  public static configurations = 'configurations';
  public static invalidSubdomain = 'invalidSubdomain';
  public static appInfo = 'appInfo';
  public static refreshToken = 'refreshToken';
  public static refreshTokenExpiry = 'refreshTokenExpiry';

  // System Administrator Role

  public static System_Administrator_Role = 'System Administrator';

  // static token
  // public static staticToken= 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJHdWVzdFVzZXIxMjM0NTY3QHlvcG1haWwuY29tIiwianRpIjoiNzk0ODMyMjgtYzMyOC00NjczLTk1MTAtNjZiMTUyMGYzNTIxIiwiZW1haWwiOiJHdWVzdFVzZXIxMjM0NTY3QHlvcG1haWwuY29tIiwiaWQiOiJkNmU5MjdmZi00MDY2LTQyYTctOWYyMi0xMTc4ZjU5OTFjYWMiLCJsb2NhbGVTZXR0aW5nIjoie1wiVGltZVpvbmVJZFwiOm51bGwsXCJMb2NhbGVcIjowLFwiTGFuZ3VhZ2VcIjowLFwiRGF0ZUZvcm1hdFwiOm51bGwsXCJUaW1lRm9ybWF0XCI6bnVsbCxcIk51bWJlckZvcm1hdFwiOm51bGwsXCJDdXJyZW5jeVwiOm51bGx9Iiwicm9sZSI6Ikd1ZXN0IiwiUm9sZUlkcyI6ImRkMzJiZTI0LTg0YTEtNDI3ZC1iOWVhLThiYjQxNGIxYmJkNiIsIm5iZiI6MTcyMDc4MzQyNSwiZXhwIjoxNzUyMzE5NDI1LCJpYXQiOjE3MjA3ODM0MjV9.KyjRXmrT9P6OY2dnrd4rAa1ujPcKVkGGDWhdrt_CQio';
  public static staticToken = environment.commonConfig.staticToken;

  // novu Notification configuration
  // public static subscriberId = '3184';

  // local
  // public static applicationIdentifier = 'qqmXweGB1DbG';
  // public static backendUrl = 'https://novuapi.techextensor.com';
  // public static socketUrl = 'https://novuws.techextensor.com';

  // public static applicationIdentifier = 'evhyJTadc5yl';
  // public static backendUrl = 'https://novuapi.phoenixassurance.co.in';
  // public static socketUrl = 'https://novuws.phoenixassurance.co.in';
  public static applicationIdentifier = environment.commonConfig.applicationIdentifier;
  public static backendUrl = environment.commonConfig.backendUrl;
  public static socketUrl = environment.commonConfig.socketUrl;

  //Forget Password
  // public static ApplicationCode = 'PMS_Clone';

  // Error Messages
  public static DeleteError = 'Unable to Delete Record. Please try again.';

  public static Default_Language = 'en';
}

// export const Language = {
//   English: 1,
//   Gujarati: 2,
//   Hindi: 3,
//   Tamil: 4,
//   Malayalam: 5,
//   Telugu: 6,
//   Kannada: 7,
//   Marathi: 8,
//   Bengali: 9
// };

export enum ComponentType {
  Grid = 1,
  PivotGrid = 2,
  KanbanBoard = 3,
  GanttChart = 4,
  BluePrint = 5,
  TreeView = 6
}

export enum UserType {
  Team = 1,
  User = 2,
  Role = 3
}
// enum for the Dsq Field Type
export enum DsqFieldType {
  WithOutLookupField = 1,
  LookupField = 2,
  ChildField = 6
}

export const ConfigurableActions = [
  {
    action: 'BroadcastEvent',
    screen: 'd595aaed-6f2a-4872-ae2c-e5014f8305a5'
  },
  {
    action: 'OpenScreen',
    screen: '3065355c-b0c1-4035-8ff4-fe8fda9b5d2d'
  },
  {
    action: 'InlineEditor'
  },
  {
    action: 'Submit'
  },
  {
    action: 'ExecuteElsaWorkFlow'
  },
  {
    action: 'ExecuteWorkFlow'
  },
  {
    action: 'ExecuteJSScript'
  },
  {
    action: 'ExecuteTSScript'
  }
];

export enum CustomScriptingType {
  Design = 3,
  Extension = 4,
  GridExtension = 5,
  KanbanExtension = 6,
  treeViewExtension = 8
}

export enum CustomScriptLanguageType {
  csharp = 1,
  TypeScript = 2,
  javascript = 3,
  css = 4,
  SQL = 5
}

export enum TabCoreScreens {
  ScreenDesignerScreen = '3ab79148-f39b-43bf-9e3f-3fbe8ce86b3d',
  ListLayoutConfigScreen = '6f6d38af-8187-4a3a-99b9-d8b1360a22f7',
  ListLayoutScreen = '72bbcc86-a1eb-404a-88af-094f0a275ef3',
  DetailLayoutConfigScreen = '61a0bd1a-1c57-4728-92eb-86e0e63e70d3',
  DetailLayoutScreen = 'ce71ed77-aa1e-4272-850d-8220d066c212',
  CustomScriptingScreen = 'c01fbe7c-4b8b-4183-b961-a5be8b876201',
  CustomScriptEditorScreen = 'a03e095a-e3ad-4ef7-b730-1f455a86fa4d',
  ActionConfigScreen = '9150c9ab-da71-435e-8ff9-0c2ee29b0a08',
  // EventConfigScreen = 'fde329a6-4116-4d96-9b03-794fc098be7e',
  EventActionPreviewScreen = 'c0f9a719-a638-40c5-a943-6d85e6f454d5',
  TabComponentBasedEventScreen = 'a41ca5e1-db3d-437f-aa40-e665b2d8af66',
  TabUserDefinedEventScreen = 'fca7a0e7-04e7-444e-b836-5dcfd3363598',
  ShareWithScreen = '8dadab5c-d498-412d-aaaf-535d24aad56e',
  BluePrintAddScreen = 'a752ef9d-7176-49e3-8cd6-f755b2f1c7de',
  BlueprintScreen = '8ad8620f-ed97-404f-a475-6839de5e9663',
  AppFieldLookupScreen = '1f4f2727-56c1-4988-947d-77d32e01e99a',
  ChildRelationShipScreen = '6447bfce-f58f-4520-9e5a-f7f1093a5495',
  ConfigurationsEntityScreen = '16e7ab25-9aa3-4721-800c-f1f2e424f749',
  DsqFieldsScreen = 'b9adeaa9-2ae3-4e4c-b5bb-fd5af105efde',
  DsqFilterSortScreen = '58ea0432-bb44-4e24-906d-c43681edce5b',
  KanbanConfigScreen = '1c2e44e6-b98f-4113-a001-0857a5e16baf',
  RegisterUserSceen = '7c9abd03-336c-4b69-96d3-186370f47200',
  TabAfterSignupSceen = '63a0fc7b-23ff-485e-a678-452e914ec297',
  TabCreateAppScreen = '2ded6314-77fe-46ce-a803-70a70c973c2c',
  TabConnectionScreen = 'a6f11ccf-ca5b-444f-87dc-7f0f64ae3d89',
  TabSubscribeAppScreen = 'bd9546b0-d684-490f-b55d-44d5d889357e',
  TabAppObjectAccessScreen = '80f5ce4b-774d-4913-a376-bc9bcafc4755',
  TabCustomActivityScreen = '1e09b4fb-2c9c-4a30-96f1-ecce5212c7d6',
  TabDynamicFormRendererScreen = '24d1ef5a-8d7f-48e3-804e-968ded4480b1',
  TabWorkflowDetailScreen = 'f18c54e2-2cbc-454d-9663-0cdfaf9c0d3f',
  TabBulkUpdateScreen = 'c797ee00-b994-485f-b0a8-b5d9c7a91420',
  TabImportScreen = '5b52e84f-2135-4870-80b4-1a06913df4e7',
  TabImportChildScreen = '29fcee06-3ab0-400c-ac85-ef079beae452',
  TabHTTPRequestScreen = 'fa3dda25-a292-4a27-8fdd-8189d142dbb8',
  TabHTTPRequestFullScreen = 'bb88b466-4f97-4466-973b-34e956f6e49c',
  TabHTTPManageEnvironmentScreen = 'b39684f9-9fb5-404d-97c9-9ff4acec30d2',
  TabHTTPManageFolderScreen = '6104e6c4-e2a6-40f0-bade-232562dc31a6',
  TabHTTPRunTimeDSQRequestGenerationScreen = '6d413dd9-9e26-4cbf-ae66-60e86cf86f32',
  TabHTTPSaveRequestScreen = '4c11e268-44b8-4aca-9e58-2c18aa477e7a',
  TabHTTPCreateCollectionScreen = 'a15c3830-6dca-4485-a3f9-401027cace3a',
  TabDataSourceQueriesListScreen = 'a00a28df-9be3-423e-a0cf-a5f9f60dcbe8',
  TabDetailAnaliticsScreen = 'd91974fb-4890-4871-aa3c-8267f76c7754',
  TabSelectQueryScreen = 'b97faeb4-20ac-4b7e-b32e-9e963d2ef2ce',
  TabSelectQueryDataFieldScreen = 'd5faae31-6b27-49cd-b626-35b8ed49aae3'
}

export const TabCoreComponents = [
  {
    screenId: 'a00a28df-9be3-423e-a0cf-a5f9f60dcbe8',
    componentKey: 'DatsourceQueryList',
    type: ComponentType.Grid
  },
  {
    screenId: TabCoreScreens.TabHTTPRequestFullScreen,
    componentKey: 'path',
    type: ComponentType.TreeView
  },
  {
    screenId: TabCoreScreens.TabBulkUpdateScreen,
    componentKey: 'verifyRecords',
    type: ComponentType.Grid
  }
];

export enum PermissionType {
  Configured = 1,
  DataAccess = 2
}

export const FileMIMEType = {
  Image: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'],
  Doc: [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/doc',
    'application/ms-doc',
    'application/msword'
  ],
  Audio: ['audio/mpeg', 'audio/mp3'],
  Video: ['video/mp4', 'video/mkv'],
  Pdf: ['application/pdf']
};

export enum UserRole {
  SystemAdministrator = 'a36720be-209b-49e9-ae80-b18d2de1e0bd',
  TABAdministrator = '01cda65b-d31b-433a-a0fb-88e0816c9f3d'
}

export enum MediaUploadType {
  Image = 1,
  Url = 2,
  Icon = 3
}
export interface LocalSettingsInfo {
  ShowHorizontalLayout: boolean;
  FavIcon: string;
  Org_Brand_Color: string;
  Org_Logo: string;
  Org_Menu_Logo: string;
  TagLine: string;
  workFlowName: string;
  TimeZoneId: string;
  Language: string;
  DateFormat: string;
  TimeFormat: string;
  NumberFormat: string;
  Currency: string;
}
export interface RegisterAppObject {
  ConnectionId: string;
  Tables: string[];
  Views: string[];
}
export interface SelectQueryConfig {
  QueryObjectID: string | undefined;
  RawSQL_AppfieldIds: any[];
  ResultField_AppfieldIds: string[] | undefined;
  GroupByFields: string[] | undefined;
  WhereClause: any[]; 
  Joins: any[]; 
  Sort: any[]; 
}
export const i18n: any = {};

export const operatorMapping: { [key: string]: number } = {
  greaterthan: 1,
  lessthan: 2,
  equal: 3,
  in: 4,
  notin: 5,
  isempty: 6,
  isnotempty: 7,
  notequal: 8,
  greaterthanorequal: 9,
  lessthanorequal: 10,
  contains: 11,
  doesnotcontain: 12,
  startswith: 13,
  doesnotstartwith: 14,
  endswith: 15,
  doesnotendwith: 16
};
export enum SummarizeType {
  SUM = 1,
  AVG = 2,
  MIN = 3,
  MAX = 4,
  STDEV = 5,
  STDEVP = 6,
  VAR = 7,
  VARP = 8,
  COUNT = 9
}

export enum AnaliticsType {
  DSQ = 'DSQ',
  SCREEN = 'SCREEN',
  INSERT = 'INSERT',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  SELECT = 'SELECT'
}