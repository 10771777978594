<div class="menu-top-logo-layout" #popup>
    <button *ngIf="isAppListVisible" class="app-list-button" (click)="toggleContentVisibility()">
      <svg width="30" height="30" viewBox="0 0 24 24" role="presentation"><path fill="currentcolor" fill-rule="evenodd" d="M4 5.01C4 4.451 4.443 4 5.01 4h1.98C7.549 4 8 4.443 8 5.01v1.98C8 7.549 7.557 8 6.99 8H5.01C4.451 8 4 7.557 4 6.99zm0 6c0-.558.443-1.01 1.01-1.01h1.98c.558 0 1.01.443 1.01 1.01v1.98C8 13.549 7.557 14 6.99 14H5.01C4.451 14 4 13.557 4 12.99zm6-6c0-.558.443-1.01 1.01-1.01h1.98c.558 0 1.01.443 1.01 1.01v1.98C14 7.549 13.557 8 12.99 8h-1.98C10.451 8 10 7.557 10 6.99zm0 6c0-.558.443-1.01 1.01-1.01h1.98c.558 0 1.01.443 1.01 1.01v1.98c0 .558-.443 1.01-1.01 1.01h-1.98c-.558 0-1.01-.443-1.01-1.01zm6-6c0-.558.443-1.01 1.01-1.01h1.98c.558 0 1.01.443 1.01 1.01v1.98C20 7.549 19.557 8 18.99 8h-1.98C16.451 8 16 7.557 16 6.99zm0 6c0-.558.443-1.01 1.01-1.01h1.98c.558 0 1.01.443 1.01 1.01v1.98c0 .558-.443 1.01-1.01 1.01h-1.98c-.558 0-1.01-.443-1.01-1.01zm-12 6c0-.558.443-1.01 1.01-1.01h1.98c.558 0 1.01.443 1.01 1.01v1.98C8 19.549 7.557 20 6.99 20H5.01C4.451 20 4 19.557 4 18.99zm6 0c0-.558.443-1.01 1.01-1.01h1.98c.558 0 1.01.443 1.01 1.01v1.98c0 .558-.443 1.01-1.01 1.01h-1.98c-.558 0-1.01-.443-1.01-1.01zm6 0c0-.558.443-1.01 1.01-1.01h1.98c.558 0 1.01.443 1.01 1.01v1.98c0 .558-.443 1.01-1.01 1.01h-1.98c-.558 0-1.01-.443-1.01-1.01z"></path></svg>
    </button>
    <div *ngIf="isPopupVisible" class="position-absolute sidebar" >
      <div class="sidebar-icon">
        <ul class="list-unstyled">
          <div *ngIf="isLoading" class="loader-container">
            <div class="loader-class"></div>
          </div>
          <li class="sidebar-icon-item" *ngFor="let orgName of tenantList"
            [ngClass]="{'selected': selectedTenant === orgName.TenantId}" (click)="getAppListByTenant(orgName.TenantId)">
            {{ getInitials(orgName.OrganizationName) }}
          </li>
        </ul>
      </div>

      <div class="main-sidebar-contents">
        <div class="profile">
          <div class="profile-info">
            <h2>{{this.appList[0]?.TenantId?.OrganizationName}}</h2>
          </div>
        </div>
        <div class="apps mt-2">
          <!-- <h3 class="app-main-title-layout">YOUR APPS</h3> -->
          <ul class="list-unstyled custom-list-box">
            <li class="p-2" *ngFor="let apps of appList" (click)="selectedApp(apps, selectedTenant)"
              [ngClass]="{'selected': selectedAppId === apps.Id}">
              <img src="../../assets/images/app/app-img.png" alt="App Logo">
               <p>{{apps.AppName}}</p>
            </li>
          </ul>
          <div class="add-custom-view-link" (click)="addApp()">
            <i class="e-icons e-plus"></i> 
            Add More Apps
          </div>
        </div>

      </div>
    </div>

    <!-- <img [src]="orgInfo?.Org_Menu_Logo"> -->
  </div>