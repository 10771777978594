import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class CustomComponentLogService {
    // Array to hold custom tags
    private tags: any = [''];

    // Array to hold custom component types
    private customType: any = [];

    // Method to retrieve custom component tags
    getTags() {
        return this.tags;
    }

    // Method to retrieve custom component types
    getCustomType() {
        return this.customType;
    }

    // Method to add custom component tags
    addTags(...tags: any) {
        this.tags.push(...tags);
    }

    // Method to add custom component types
    addCustomType(...customType: any) {
        this.customType.push(...customType);
    }
}
