import { inject, Injectable } from '@angular/core';
import { AppHelper, IdbService, SessionStorageService, StorageConstants } from '@techextensor/tab-core-utility';
import { Common } from '@techextensor/tab-core-utility';
import { firstValueFrom } from 'rxjs';
import { Constants, CustomScriptingType } from '../const/constants';
import { HelperFunctionService } from '../services/helper-function.service';

@Injectable({ providedIn: 'root' })
export class DataResolver {

    private appConfigLoaded = false;
    private readonly _appHelper: AppHelper = inject(AppHelper);
    private readonly _idbService: IdbService = inject(IdbService);
    private readonly _sessionStorageService: SessionStorageService = inject(SessionStorageService);
    private readonly _helperFunctionService: HelperFunctionService = inject(HelperFunctionService);
    private appConfiguration = false;

    constructor() {}

    /**
     * Resolves the user personalized data and schema from the database or server.
     * If the data does not exist in IndexedDB, it fetches the data from the server using the DSQ service.
     * It then stores the data in IndexedDB and sets it in the Common object.
     * @return {Promise<void>} This function does not return anything.
     */
    async resolve(): Promise<void> {

        // Load the schema from IndexedDB or server
        if (!Common?.tabJson) {
            await this.loadSchema();
        }

        // Load the user personalized data from IndexedDB or server
        if (!Common?.tabUserPersonalizedData) {
            await this.loadUserPersonalizedData();
        }

        // Load the user permissions from session storage or server
        if(!Common?.tabUserPermissions){
            await this.loadUserPermissions();
        }

        if(!this.appConfigLoaded){
            // Load the global css from server
            await this.loadGlobalCss();
        }
        if (!this.appConfiguration) {
            // Load the configuration
            await this.loadConfigurations()
        }

    }

    /**
     * Loads the schema data from the database.
     * If the data does not exist in IndexedDB, it fetches the data from the server using the AppHelper service.
     * It then stores the data in IndexedDB and sets it in the Common object.
     * @return {Promise<void>} This function does not return anything.
     */
    async loadSchema() {
        // Fetch the schema from IndexedDB
        let tabData = await this._idbService.getItem(StorageConstants.tabData);
        const appCode = this._sessionStorageService.getSessionStorage(StorageConstants.applicationCode);

        if(!tabData) {
            // Fetch the schema from the server using the AppHelper service
            const schemaResponse: any = await firstValueFrom(this._appHelper.getSchema() as any);
            tabData = JSON.parse(schemaResponse?.Result ?? '{}');
            // Store the schema in IndexedDB
            this._idbService.setItem(StorageConstants.tabData, tabData);
        } else {
            if(tabData?.AppName !== appCode){
                // Fetch the schema from the server using the AppHelper service
                const schemaResponse: any = await firstValueFrom(this._appHelper.getSchema() as any);
                Common.tabJson = JSON.parse(schemaResponse?.Result ?? '{}');
                // Store the schema in IndexedDB
                this._idbService.setItem(StorageConstants.tabData, Common.tabJson);

                const userPersonalizationResponse: any = await firstValueFrom(this._helperFunctionService.getDSQData(Constants.Get_UserWise_Personalize_Data_Id));
                Common.tabUserPersonalizedData = userPersonalizationResponse?.Result ?? [];
                // Store the data in IndexedDB
                this._idbService.setItem(StorageConstants.tabUserPersonalizedData, Common.tabUserPersonalizedData);

                // Fetch the user permissions from the server using the AppHelper service
                const userPermissionsResponse: any = await firstValueFrom(this._appHelper.getPermission() as any);
                Common.tabUserPermissions = userPermissionsResponse?.Result ?? [];
                // Store the data in session storage
                this._sessionStorageService.setSessionStorage(Constants?.tabUserPermissions, Common.tabUserPermissions);
                return;
            }
        }

        // Directly set the schema into the Common object
        Common.tabJson = tabData;
    }

    /**
     * Loads the user personalized data from the database.
     * If the data does not exist in IndexedDB, it fetches the data from the server using the DSQ service.
     * It then stores the data in IndexedDB and sets it in the Common object.
     * @return {Promise<void>} This function does not return anything.
     */
    async loadUserPersonalizedData(): Promise<void> {
        // Fetch the schema from IndexedDB
        let tabUserPersonalizedData = await this._idbService.getItem(StorageConstants.tabUserPersonalizedData);
            
        if(!tabUserPersonalizedData) {
            // Fetch the user personalized data from the server using the DSQ service
            const userPersonalizationResponse: any = await firstValueFrom(this._helperFunctionService.getDSQData(Constants.Get_UserWise_Personalize_Data_Id));
            tabUserPersonalizedData = userPersonalizationResponse?.Result ?? [];
            // Store the data in IndexedDB
            this._idbService.setItem(StorageConstants.tabUserPersonalizedData, tabUserPersonalizedData);
        }

        // Directly set the data into the Common object
        Common.tabUserPersonalizedData = tabUserPersonalizedData;    
    }

    /**
     * Loads the user permissions from the database.
     * If the data does not exist in session storage, it fetches the data from the server using the AppHelper service.
     * It then stores the data in session storage and sets it in the Common object.
     * @return {Promise<void>} This function does not return anything.
     */
    async loadUserPermissions(): Promise<void> {
        // Fetch the schema from session storage
        const userPermissionsString = this._sessionStorageService.getSessionStorage(Constants?.tabUserPermissions);
        if(!userPermissionsString) {
            // Fetch the user permissions from the server using the AppHelper service
            const userPermissionsResponse: any = await firstValueFrom(this._appHelper.getPermission() as any);
            Common.tabUserPermissions = userPermissionsResponse?.Result ?? [];
            // Store the data in session storage
            this._sessionStorageService.setSessionStorage(Constants?.tabUserPermissions, Common.tabUserPermissions);
        }else{
            // Parse the stored data from session storage
            Common.tabUserPermissions = JSON.parse(userPermissionsString);
        }
    }

   /**
     * Loads the global CSS from the database.
     * If the data does not exist in session storage, it fetches the data from the server using the DSQ service.
     * It then stores the data in session storage and sets it in the Common object.
     * The global CSS is used to apply styles to the application.
     * @return {Promise<void>} This function does not return anything.
     */
    async loadGlobalCss() {
        const appId: any = this._sessionStorageService.getSessionStorage(StorageConstants.AppId);
        // Fetch the global CSS from the server using the DSQ service
        const customScriptResponse: any = await firstValueFrom(this._helperFunctionService.getDSQData(
            Constants.Detail_TABMD_Custom_Scripting_Id,
            {
                AppId: appId,
                AppConfigType: CustomScriptingType.Design
            }
        ));

        // Get the content of the global CSS
        const content = customScriptResponse?.Result?.[0]?.Content;    
        if(content){
            // Create a style element and set the content of the global CSS
            const style = document?.createElement('style');
            style.textContent = content;
            // Append the style element to the head of the document
            document?.head?.appendChild(style);
        }

        // Set a flag to indicate that the app config has been loaded
        this.appConfigLoaded = true;
    }

    /**
     * Loads the application configurations from session storage or fetches them from the server.
     * If the configurations are not available in session storage, it retrieves them from the server using the DSQ service,
     * and then stores them in session storage.
     * This function updates the appConfiguration flag when configurations are fetched from the server.
     * 
     * @return {Promise<void>} This function does not return anything.
     */
    async loadConfigurations(): Promise<void> {
        // Retrieve configurations from session storage
        const configurations = this._sessionStorageService.getSessionStorage(Constants?.configurations);
        
        if (!configurations) {
            // Fetch the configurations from the server using the DSQ service
            const configurationsResponse: any = await firstValueFrom(this._helperFunctionService.getDSQData(Constants.GetConfigurations_Id));
            
            // Store the retrieved configurations in session storage
            this._sessionStorageService.setSessionStorage(Constants?.configurations, configurationsResponse?.Result ?? []);
            
            // Set the flag indicating that app configurations have been fetched from the server
            this.appConfiguration = true;
        }
    }
}
