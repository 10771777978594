import { CommonModule, NgClass, NgFor } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, inject, Input, Output, ViewChild } from '@angular/core';
import { IPaginatedResponse, IMessage } from '@novu/headless';
import { SessionStorageService, StorageConstants } from '@techextensor/tab-core-utility';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-notification',
  standalone: true,
  imports: [NgClass, NgFor, CommonModule],
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent {
  isPopupVisible = true;
  @ViewChild('popup') popup: ElementRef | undefined;
  public notifications: IMessage[]|any = [];
  public page = 0;
  @Input() public headlessService: any;
  @Output() public removeNotificationEvent = new EventEmitter<IMessage>();
  @Output() public markAsReadEvent = new EventEmitter<IMessage>();
  @Output() public markAllLoadUnreadCount = new EventEmitter<IMessage>();
  isLoading?: boolean;
  public hasMoreNotifications : boolean = false;
  public sessionStorageService = inject(SessionStorageService)
  private sanitizer : any = inject(DomSanitizer); // Inject DomSanitizer

  ngOnInit(): void {
    this.isLoading = true;
    this.headlessService.fetchNotifications({
      listener: () => { },
      onSuccess: (response: IPaginatedResponse<IMessage>) => {
        this.hasMoreNotifications = response.hasMore;
        this.notifications = response.data
        this.isLoading = false;
      },
      page: this.page,
    });
  }

  /**
   * Fetches more notifications from the Novu server.
   *
   * This function calls the `fetchNotifications` method of the `headlessService` with the appropriate parameters.
   * It then updates the `notifications` property with the received notifications.
   */
  public loadNotifications(): void {
    this.isLoading = true;
    this.headlessService.fetchNotifications({
      listener: () => { },
      onSuccess: (response: IPaginatedResponse<IMessage>) => {
        this.hasMoreNotifications = response.hasMore;
        this.notifications.push(...response.data);
        this.isLoading = false;
      },
      page: this.page,
    });
  }

  /**
   * Removes a notification from the server.
   * @param notification {IMessage} The notification object to be removed.
   */
  public removeNotification(notification: any): void {
    this.removeNotificationEvent.emit(notification);
  }

  /**
  * Marks a notification as read on the Novu server. 
  * @param notification {IMessage} The notification object to be marked as read.
  */
  public markAsRead(notification: any): void {
    this.markAsReadEvent.emit(notification);
  }

  /**
   * Closes the notification popup.
   *
   * This function sets the `isPopupVisible` property to `false`,
   * which hides the notification popup when called.
   */
  public closeNotificationPopup(): void {
    this.isPopupVisible = false;
  }

  /**
   * Listens for a click event on the document and checks if the click was outside the notification popup.
   * If the click was outside the popup, closes the popup.
   *
   * @param event - The click event.
   */
  @HostListener('document:mousedown', ['$event'])
  public onOutsideClick(event: MouseEvent): void {
    if (this.isPopupVisible && this.popup?.nativeElement && !this.popup.nativeElement.contains(event.target)) {
      this.closeNotificationPopup();
    }
  }

  /**
   * Listens for the scroll event on the notification list and checks if the user has scrolled to the bottom.
   * If the user has scrolled to the bottom, increments the page number and fetches more notifications.
   *
   * @param event - The scroll event.
   */
  public onScroll(event: Event): void {
    const element = event.target as HTMLElement;
    const atBottom = element.scrollHeight - element.scrollTop === element.clientHeight;

    if (atBottom && this.hasMoreNotifications) {
      this.page++;
      this.loadNotifications();
    }
  }

  /**
   * Opens the notification's URL in a new tab if the notification has a URL.
   * If the notification has a URL, it logs the URL to the console and opens the URL in a new tab.
   * It also closes the notification popup.
   *
   * @param notification - The notification object with the URL to open.
   */
  // openNotification(notification: any): void {
  //   this.markAsReadEvent.emit(notification);
  //   if (notification.cta.data.url) {
  //     window.open(notification.cta.data.url, '_blank');
  //     this.closeNotificationPopup();
  //   }

  // }
  openNotification(notification: any): void {
    this.markAsReadEvent.emit(notification);
    if (notification.cta.data.url) {
      const appCode = this.sessionStorageService.getSessionStorage(StorageConstants.applicationCode);
      const notificationurl = notification.cta.data.url.split('/screen')[1]
      const targetUrl = `${window.location.origin}/${appCode}/screen${notificationurl}`
      window.open(targetUrl, '_blank');
      this.closeNotificationPopup();
    }

  }

  getSafeHtml(content: string) {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }

  public handleMarkAllAsRead(): void {
    this.headlessService.markAllMessagesAsRead({
      listener: () => {
        // Removal result processing if needed
      },
      onSuccess: () => {
        this.markAllLoadUnreadCount.emit();
      },
      onError: (error: unknown) => {
        console.error('Error removing all notifications:', error);
      },
    });
  }
 
  public handleRemoveAllNotifications(): void {
    this.headlessService.removeAllNotifications({
      listener: () => {
        // Removal result processing if needed
      },
      onSuccess: () => {
        this.notifications = [];
        this.markAllLoadUnreadCount.emit();
      },
      onError: (error: unknown) => {
        console.error('Error removing all notifications:', error);
      },
    });
  }
}