import { inject, Injectable } from '@angular/core';
import {
  getAppObjectFromDataSourceQueryID,
  getAppObjectFromSystemDBTableName,
  getViewAppObjectDetailsFromDSQID,
  getDataFromDataSourceQueryID,
  getFieldsType,
  SessionStorageService,
  TabGetService,
  StorageConstants,
  getAppObject,
  AppHelper,
  Common,
  IdbService,
} from '@techextensor/tab-core-utility';
import { firstValueFrom, Observable } from 'rxjs';
import { ComponentType, Constants, TabCoreComponents } from '../const/constants';
import { ToastrService } from 'ngx-toastr';
import { FormioUtils } from '@formio/angular';
import { CustomComponentLogService } from './formio/custom-component-log.service';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { IGridExtensionRegistry, IKanBanExtensionRegistry, ITreeViewExtensionRegistry } from '@techextensor/tab-rewrite-infrastructure';
import { RuntimeCompilerService } from './runtime-compiler.service';

@Injectable({
  providedIn: 'root'
})
export class HelperFunctionService {
  private readonly tabGetService: TabGetService = inject(TabGetService);
  private readonly customComponentLogService: CustomComponentLogService = inject(CustomComponentLogService);
  private readonly sessionStorageService: SessionStorageService = inject(SessionStorageService);
  private readonly appHelper: AppHelper = inject(AppHelper);
  private readonly idbService: IdbService = inject(IdbService);
  private readonly _tabToasterService: ToastrService = inject(ToastrService);
  private readonly _router: Router = inject(Router);
  private readonly _activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly _runtimeCompilerService = inject(RuntimeCompilerService);

  constructor() { }

  /**
   * Retrieves data from the specified data source query (DSQ) asynchronously.
   *
   * @param {string} dsqId - The ID of the DSQ to fetch data from.
   * @param {any} reqtokens - The request tokens to include in the payload. Optional.
   * @param {any} params - Additional parameters to include in the payload. Optional.
   *
   * @returns {Observable<any>} A Promise that resolves to the result of the DSQ execution.
   */
  public getDSQData(dsqId: string, reqtokens?: any, params?: any): Observable<any> {
    return new Observable(observer => {
      try {
        const appObject = getAppObjectFromDataSourceQueryID(dsqId)?.ObjectName;
        const dsq = getDataFromDataSourceQueryID(dsqId)?.QueryName;
        if (!dsq || !appObject) {
          this._tabToasterService.error(
            `Failed to retrieve data for DSQ ID: ${dsqId}. ${!appObject ? 'AppObject not found.' : ''} ${appObject && !dsq ? 'QueryName not found.' : ''
            }`,
            'Error',
            { positionClass: 'toast-bottom-right' }
          );
          throw new Error('DSQ or AppObject not found');
        }
        // COMMON_FILTER_CODE ADVANCED_FILTER_CODE
        const { filters = '{}' } = this._activatedRoute?.snapshot?.queryParams || {};
        const filterJson = (JSON.parse(filters) || {})[dsqId] || {};

        // TE-42-7089 - Parth B Thakkar 2024-10-17 column search from filter
        let columnSearch = filterJson.columnSearch || [];
        // TE-42-7109 - Parth B Thakkar 2024-10-23 Bulk Update
        // advancedCriteria is reserved for queryBuilderResponse.Filters so we need to remove it from reqtokens and append it in Payload.Filters
        let newRequestTokens = reqtokens;
        let advancedCriteria = (reqtokens && reqtokens?.advancedCriteria) || [];
        let CustomViewID =
          reqtokens && reqtokens.DynamicCustomViewID
            ? {
              CustomViewID: reqtokens.DynamicCustomViewID
            }
            : {};
        if (reqtokens && reqtokens?.advancedCriteria) {
          delete newRequestTokens?.advancedCriteria; // Remove advancedCriteria from newRequestTokens if it exists
        }
        if (reqtokens && reqtokens?.DynamicCustomViewID) {
          delete newRequestTokens?.DynamicCustomViewID; // Remove DynamicCustomViewID from newRequestTokens if it exists
        }
        this.getFieldsFromDSQ(dsqId).subscribe((fields: any) => {
          const payload = {
            AppObjectName: appObject,
            DSQName: dsq,
            Reqtokens: newRequestTokens,
            Filters: [
              ...advancedCriteria,
              ...(filterJson.filterType == 'advancedFilters' ? (filterJson.advancedCriteria || {}).Filters || [] : []),
              ...(filterJson.filterType == 'additionalFilters'
                ? this.generateCriteriaForCommonFilter(filterJson.additionalCriteria || {}, fields)
                : []),
              ...this.generateCriteriaForColumnSearch(columnSearch || [], fields)
            ],
            ...params,
            ...CustomViewID
          };
          this.tabGetService.executeRAWDSQWithName(payload).subscribe((result: any) => {
            let data = result;
            if (!result?.IsSuccess || result?.StatusCode !== '200') {
              console.error('Invalid API Response');
              data = null;
            }
            observer.next(data);
            observer.complete();
          });
        });
      } catch (error) {
        console.error(error);
        observer.next(null);
        observer.complete();
      }
    });
  }

  /**
   * Retrieves fields from the specified data source query (DSQ) asynchronously.
   * It generates the application object fields from the DSQ and returns them
   * as an Observable.
   *
   * 2024-10-15 - Modified this to return null if the DSQ is not found
   * @param {string} dsqId - The ID of the DSQ to fetch fields from.
   * @returns {Observable<any>} An Observable that emits the fields or null if an error occurs.
   */
  public getFieldsFromDSQ(dsqId: string): Observable<any> {
    // Create an Observable that generates the application object fields from the DSQ
    if (dsqId) {
      return new Observable(observer => {
        this.generateAppObjectFields(dsqId, 'DSQ')
          .then((fields: any) => {
            // If the fields are generated successfully, emit the fields and complete the Observable
            observer.next(fields);
            observer.complete();
          })
          .catch((error: any) => {
            // If an error occurs, log the error and emit null and complete the Observable
            console.log('An error occurred while generating app object fields:', error);
            observer.next(null);
            observer.complete();
          });
      });
    }
    return new Observable(observer => {
      observer.next(null);
      observer.complete();
    });
  }

  /**
   * Asynchronously generates the application object fields from the DSQ specified by the given ID.
   *
   * @param {string} dsqId - The ID of the DSQ to generate the fields from.
   * @returns {Promise<any>} A Promise that resolves to the processed application object fields.
   * @throws {Error} If the app object or fields are invalid.
   */
  private async generateAppObjectFields(Id: string, type: string): Promise<any> {
    let appObject;

    // Get the app object
    if (type == 'DSQ') {
      appObject = getAppObjectFromDataSourceQueryID(Id);
    } else if (type == 'AppObject') {
      appObject = getAppObject(Id);
    }
    // Check if the app object or fields are invalid
    if (!appObject || !appObject?.Fields) {
      throw new Error('Invalid app object or fields not found');
    }
    // Process the application object fields and return the result
    else {
      return this.processAppObjectField(appObject.Fields, '', '');
    }
  }

  /**
   * Processes the application object fields and returns an array of field structures.
   * Recursively processes the fields if they have children (i.e., nested lookup fields).
   *
   * @param {any} fields - The application object fields to process.
   * @param {string} parent - The parent field ID used to generate the field structure ID.
   * @returns {any[]} An array of field structures.
   */
  private processAppObjectField(fields: any, parent: string, prefix?: any): any[] {
    if (!fields) return [];
    return fields.flatMap((field: any) => this.getFieldStructure(field, parent, prefix));
  }

  /**
   * Generates a single field structure from the given field object.
   * If the field has children (i.e., nested lookup fields), recursively processes them.
   *
   * @param {any} field - The field object to generate the structure from.
   * @param {string} parent - The parent field ID used to generate the field structure ID.
   * @returns {any[]} An array of field structures.
   */
  private getFieldStructure(field: any, parent: string, prefix?: any): any[] {
    const fieldStructure: any = {
      // Generate a unique ID for the field by appending the parent ID if it exists
      id: prefix !== '' ? parent + '_' + field.ID : field.ID,
      // Use the display name of the field
      name: field.FieldName,
      // Determine the type of the field based on its data type and whether it has a lookup
      fieldType: getFieldsType(field?.FieldType?.DataType, field.hasOwnProperty('LookUpDetails')),
      // Mark if the field has children (i.e., nested lookup fields)
      hasChildren: field.hasOwnProperty('LookUpDetails') && field?.LookUpDetails ? true : false,
      // Store the parent ID if it exists
      parentID: parent !== '' ? parent : null,
      lookUpText: prefix ? prefix + '.' + field.FieldName : field.FieldName
    };

    if (field?.LookUpDetails) {
      // If the field has children, retrieve the lookup fields
      const lookupFields = getAppObjectFromSystemDBTableName(field.LookUpDetails.LookupObject)?.Fields;
      if (lookupFields) {
        // Recursively process the lookup fields and add the results to the field structure
        return [
          fieldStructure,
          ...this.processAppObjectField(lookupFields, fieldStructure.id, fieldStructure.lookUpText)
        ];
      } else {
        console.warn('Lookup fields not found for:', field.LookUpDetails.LookupObject);
      }
    }

    // Return the single field structure if it has no children
    return [fieldStructure];
  }

  public manageScreenContextData(dsqId: string, selectedRowsData: any) {
    const viewAppObjectDetailsOfGrid = getViewAppObjectDetailsFromDSQID(dsqId);
    const screenContext = this.sessionStorageService.getSessionStorage(StorageConstants.screenContext) || '{}';
    const updatedScreenContext = {
      ...JSON.parse(screenContext),
      [viewAppObjectDetailsOfGrid?.SystemDBTableName]: {
        ...JSON.parse(screenContext)[viewAppObjectDetailsOfGrid?.SystemDBTableName],
        activeRowsData: selectedRowsData
      }
    };
    this.sessionStorageService.setSessionStorage(StorageConstants.screenContext, JSON.stringify(updatedScreenContext));
  }

  /**
   * Retrieves fields from the specified application object asynchronously.
   * It generates the application object fields from the application object ID and returns them
   * as an Observable.
   *
   * @param {string} appObjectId - The ID of the application object to fetch fields from.
   * @returns {Observable<any>} An Observable that emits the fields or null if an error occurs.
   */
  public getFieldsFromAppObject(appObjectId: string): Observable<any> {
    // Create an Observable that generates the application object fields from the application object ID
    return new Observable(observer => {
      // Generate the application object fields and handle the result
      this.generateAppObjectFields(appObjectId, 'AppObject')
        .then((fields: any) => {
          // If the fields are generated successfully, emit the fields and complete the Observable
          observer.next(fields);
          observer.complete();
        })
        .catch((error: any) => {
          // If an error occurs, log the error and emit null and complete the Observable
          console.log('An error occurred while generating app object fields:', error);
          observer.next(null);
          observer.complete();
        });
    });
  }

  /**
   * Retrieves the CRUD app object from either the given app object ID or DSQ ID.
   * It returns an Observable that emits the CRUD app object or null if an error occurs.
   *
   * @param {string} appObjectId - The ID of the app object to retrieve.
   * @param {string} dataSourceQueryId - The ID of the DSQ to retrieve the app object from. Optional.
   * @returns {Observable<any>} An Observable that emits the CRUD app object or null if an error occurs.
   */
  public getCRUDAppObject(appObjectId: any, dataSourceQueryId?: any) {
    let appObject: any;

    try {
      // Retrieve the app object from the app object ID or DSQ ID
      if (appObjectId) {
        appObject = getAppObject(appObjectId);
      } else if (dataSourceQueryId) {
        appObject = getAppObjectFromDataSourceQueryID(dataSourceQueryId);
      }

      // Check if the app object is invalid
      if (!appObject) {
        throw new Error('Invalid appObject');
      }

      // If the app object is a CRUD app object, retrieve the CRUD app object from the app object ID
      if (appObject?.ObjectType === 2) {
        appObject = getAppObject(appObject?.CRUDAppObjectId);
      }
    } catch (error) {
      console.error('An error occurred while getting app object details:', error);
      appObject = null;
    }

    return appObject;
  }

  public async loadApp() {
    // Step 1: Wait for the schema response
    const schemaResponse: any = await firstValueFrom(this.appHelper.getSchema() as any);
    Common.tabJson = JSON.parse(schemaResponse?.Result ?? '{}');
    this.idbService.setItem(StorageConstants.tabData, Common.tabJson);

    // Step 2: Wait for both user personalization and permissions in parallel
    const [userPersonalizationResponse, userPermissionsResponse]: any = await Promise.all([
      firstValueFrom(this.getDSQData(Constants.Get_UserWise_Personalize_Data_Id)),
      firstValueFrom(this.appHelper.getPermission() as any)
    ]);

    // Step 3: Handle user personalization data
    Common.tabUserPersonalizedData = userPersonalizationResponse?.Result ?? [];
    this.idbService.setItem(StorageConstants.tabUserPersonalizedData, Common.tabUserPersonalizedData);

    // Step 4: Handle user permissions data
    Common.tabUserPermissions = userPermissionsResponse?.Result ?? [];
    this.sessionStorageService.setSessionStorage(Constants?.tabUserPermissions, Common.tabUserPermissions);
    // Step 5: Handle Tenant Configurations data
    const configurations: any = await firstValueFrom(this.getDSQData(Constants.GetConfigurations_Id));
    this.sessionStorageService.setSessionStorage(Constants?.configurations, configurations?.Result ?? []);
  }

  /**
   * Toggles the full screen mode of the given HTML element.
   *
   * If the element is not in full screen mode, it will request the full screen
   * mode. If the element is already in full screen mode, it will exit the full
   * screen mode.
   *
   * @param element - The HTML element to toggle full screen mode for.
   */
  public toggleFullScreen(element: HTMLElement): void {
    try {
      if (!document.fullscreenElement) {
        element.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
    } catch (error) {
      console.error('Failed to toggle full screen mode', error);
    }
  }

  ////////////////////////////////////////////
  /**
   * moved below functions from tab-form-renderer service to here to use in multiple place
   * - Parth 2024-10-16
   *
   */

  /**
   * Retrieves an array of custom component keys from the given form.
   * The method iterates through each component in the form and checks
   * if the component is a custom type. If it is, the component key
   * is added to the array of custom component keys.
   *
   * @param form - The form object to retrieve custom component keys from.
   * @returns An array of custom component keys.
   */
  public getCustomComponentKeys(form: any) {
    // Get custom component types
    let customTypes: any = this.customComponentLogService.getCustomType();
    let keys: any = [];
    if (form) {
      // Iterate through each component in the form
      FormioUtils.eachComponent(
        form.components as any,
        (component: any) => {
          // Check if the component is a custom type
          if (customTypes.includes(component.type)) {
            const key = component.key.split('.');
            keys.push(key[key.length - 1]);
          }
        },
        true
      );
    }
    return keys;
  }

  /**
   * Transforms the submission object to prepare it for submission.
   * @param submission - The submission to transform.
   * @param form - The form object associated with the submission.
   * @returns The transformed submission.
   */
  public transformSubmissionForSubmit(submission: any, form: any) {
    const customComponentKeys = this.getCustomComponentKeys(form);

    // Iterate through each key in the submission object
    if (submission) {
      Object.keys(submission).forEach(key => {
        if (submission[key] && typeof submission[key] === 'object' && !customComponentKeys.includes(key)) {
          submission[key] = this.transformSubmissionForSubmit(submission[key], form);
        }

        // Check if the submission component is custom
        if (
          submission[key] &&
          (submission[key].componentValue || submission[key].component || submission[key].otherFormdata)
        ) {
          // Set the component value to the submission key
          submission[key] = submission[key].componentValue;
          // Recursively transform nested submissions for child screens
          submission[key] = this.transformSubmissionForSubmit(submission[key], form);
        }
      });
    }
    return submission;
  }

  /**
   * Transforms the submission object to prepare it for loading.
   * @param submission - The submission to transform.
   * @returns The transformed submission.
   */
  public transformSubmissionForLoad(submission: any, form: any) {
    // Get custom component keys in provided submission
    const customComponentKeys = this.getCustomComponentKeys(form);
    if (submission) {
      // Iterate through each key in the submission object
      Object.keys(submission).forEach(key => {
        if (submission[key] && typeof submission[key] === 'object') {
          submission[key] = this.transformSubmissionForLoad(submission[key], form);
        }

        // Check if the key corresponds to a custom component
        if (customComponentKeys.includes(key)) {
          // Wrap the component value in structure
          if (
            submission[key] &&
            (submission[key].componentValue || submission[key].component || submission[key].otherFormdata)
          ) {
            // Recursively transform nested submissions for child screens
            submission[key] = this.transformSubmissionForLoad(submission[key]?.componentValue, form);
          }
          FormioUtils.eachComponent(form?.components, (component: any) => {
            if (key === component.key) {
              submission[key] = {
                componentValue: submission[key],
                component: { ...component }
              };
            }
          });
          // submission[key] = { componentValue: submission[key] };
        }
      });
    }
    return submission;
  }

  /**
   * Generates criteria for a common filter based on a given filter object and fields.
   *
   * This function recursively processes a `commonFilterObject` to generate an array of criteria
   * that can be used for filtering data. It handles nested objects and special cases for
   * relational operators, particularly for date ranges.
   *
   * @param {Object} commonFilterObject - The filter object containing the criteria to be processed.
   * @param {Array} fields - Array of app object fields
   * @param {string} [parentKey=''] - The key for the parent property, used for building nested keys.
   *                                   Defaults to an empty string.
   * @param {number} [sequence=1] - The starting sequence number for generated criteria. Defaults to 1.
   * @returns {Array} An array of generated criteria objects based on the filter object and fields.
   *
   * Each generated criteria object will have the following properties:
   * - ConjuctionClause: A fixed value of 1.
   * - FieldID: The ID of the field based on the lookup text.
   * - Value: The value from the filter object, formatted as a string for arrays.
   * - fieldName: The name of the field from the filter object.
   * - RelationalOperator: The operator determined based on the app object field and value.
   * - ValueType: A fixed value of 1.
   * - Sequence: An incremental sequence number.
   * - GroupID: A fixed value of 1.
   * - FieldType: 2 for lookup fields, 1 for entity fields.
   * - LookUpDetail: The full key for nested lookups, or null if not applicable.
   *
   * @example
   * const commonFilter = {
   *   status: 'active',
   *   dateRange: ['2023-01-01', '2023-12-31']
   * };
   * const fields = [
   *   { lookUpText: 'status', id: 1 },
   *   { lookUpText: 'dateRange', id: 2 }
   * ];
   * const criteria = generateCriteriaForCommonFilter(commonFilter, fields);
   * The criteria will contain the processed filter criteria, including separate entries for date range.
   *
   * @throws {Error} Will throw an error if the filter object is malformed.
   *
   * @step-by-step
   * 1. Initialize an empty result array to hold the generated criteria.
   * 2. Check if the provided fields array is empty; if so, return the empty result array.
   * 3. Iterate through each key in the `commonFilterObject`.
   * 4. Build the full key path for nested properties.
   * 5. Find the corresponding app object field based on the full key comparing with lookup.
   * 6. Check if the current value is a nested object:
   *    - If yes, recursively call the function to process the nested object.
   * 7. If not a nested object, create a criteria object with appropriate properties:
   *    - Set the conjunction clause and field ID.
   *    - Format the value appropriately based on its type.
   *    - Determine the relational operator using a helper method.
   *    - Set fixed values for value type, group ID, and sequence.
   *    - Determine field type and lookup details based on the key.
   * 8. Handle special case for relational operator 17 (Between for dates):
   *    - If the value is an array with two dates, create two separate criteria objects.
   * 9. Push the generated criteria object(s) into the result array.
   * 10. Return the result array containing all generated criteria.
   */

  public generateCriteriaForCommonFilter(
    commonFilterObject: any,
    fields: any[],
    parentKey: string = '',
    sequence: number = 1
  ) {
    let result: any[] = []; // Initialize the result array
    if (fields.length == 0) {
      return result;
    }
    for (const key in commonFilterObject) {
      // Build the new key path
      const fullKey = parentKey ? `${parentKey}.${key}` : key;
      const currentAppField = fields.find(obj => obj.lookUpText === fullKey) || {};

      // Check if the current value is a nested object
      if (
        typeof commonFilterObject[key] === 'object' &&
        !Array.isArray(commonFilterObject[key]) &&
        commonFilterObject[key] !== null
      ) {
        // Recur for nested objects
        result.push(...this.generateCriteriaForCommonFilter(commonFilterObject[key], fields, fullKey, sequence));
      } else {
        // Create a flat map object for each key
        const object: any = {
          ConjuctionClause: 1, // Fixed conjunction clause - always 1
          FieldID: currentAppField.id ?? null, // Handle undefined id gracefully
          Value: Array.isArray(commonFilterObject[key]) ? commonFilterObject[key].join(',') : commonFilterObject[key], // derived from filter object
          fieldName: key, // For testing purposes
          RelationalOperator: this.determineRelationalOperator(currentAppField, commonFilterObject[key]), // based
          ValueType: 1, // always 1
          Sequence: sequence++, // Incremental sequence number
          GroupID: 1, // For now keep 1
          FieldType: fullKey.includes('.') ? 2 : 1, //   lookup 2 and entity 1
          LookUpDetail: fullKey.includes('.') ? fullKey : null //
        };

        // if RelationalOperator is 17 (Between for Date) then we will convert it into 2 separate objects with RelationalOperator 9 ( >= ) and 10 ( <= )
        // Split Date and change date format - YYYY-MM-DD
        // took reference from screen 9f454d69-0023-4674-bf53-7d4b7b3e18eb -  Workitem FIlter Panel - Pms (Apply Button - Button Custom Logic)
        if (object.RelationalOperator == 17) {
          const value = commonFilterObject[key];
          if (value && value.length == 2) {
            result.push({
              ...object,
              RelationalOperator: 9,
              Value: value[0] ? moment(value[0]).format('YYYY-MM-DD') : null
            });
            result.push({
              ...object,
              RelationalOperator: 10,
              Value: value[1] ? moment(value[1]).format('YYYY-MM-DD') : null
            });
          }
        } else {
          result.push(object);
        }
      }
    }

    return result; // Return the result array
  }

  // Helper function to determine relational operator based on field type
  // 1. Greater Than ( > )
  // 2. Less Than ( < )
  // 3. Equal To ( = )
  // 4. IN (Comma Separated Values)
  // 5. Not In (Comma Separated Values)
  // 6. Is Null ( = null )
  // 7. Is Not Null ( != null )
  // 8. Not Equal To ( != )
  // 9. Greater Than Or Equal To ( >= )
  // 10. Less Than Or Equal To ( <= )
  // 11. Contains ( like %value% )
  // 12. Not Contains ( not like %value% )
  // 13. Starts With ( like value% )
  // 14. Not Start With ( not like value% )
  // 15. Ends With ( like %value )
  // 16. Not Ends With ( not like %value )
  // 17. Between Special Static for Date -  Parth - Temp
  private determineRelationalOperator = (currentAppField: any, value?: any): number => {
    const fieldType = currentAppField?.fieldType || '';
    if (fieldType.toLowerCase() == 'date' || fieldType.toLowerCase() == 'datetime') {
      return 17; // Between
    } else if (fieldType == 'string') {
      if (Array.isArray(value)) {
        return 4; // IN
      }
      return 11; // Contains
    }

    return 11; // Contains
  };

  /**
   * Generates criteria for column search based on the provided column search parameters and fields.
   *
   * @param {Array<{ fieldName: string; filterType: number; value: any }>} columnSearch - An array of objects representing the column search criteria.
   * Each object should contain:
   * - `fieldName`: The name of the field to search on.
   * - `filterType`: The type of filter to apply (represented as a number).
   * - `value`: The value to filter against (can be of any type).
   *
   * @param {any[]} fields - An array of field objects that include metadata about each field.
   * Each field object should have at least a `lookUpText` property, which is used to match against `fieldName`.
   *
   * @returns {Array<Object>} An array of criteria objects constructed from the provided column search data.
   * Each object in the returned array will include:
   * - `ConjuctionClause`: A fixed value of 1.
   * - `FieldID`: The ID of the field, derived from the fields array (null if not found).
   * - `Value`: The formatted value based on the field type (date or datetime) or null.
   * - `fieldName`: The name of the field, for reference.
   * - `RelationalOperator`: The filter type or a default value of 11.
   * - `ValueType`: A constant value of 1.
   * - `Sequence`: An incremental sequence number starting from 1.
   * - `GroupID`: A constant value of 1.
   * - `FieldType`: A value indicating the type of the field (lookup or entity).
   * - `LookUpDetail`: Additional details for lookup fields or null.
   */
  public generateCriteriaForColumnSearch(
    columnSearch: Array<{ fieldName: string; filterType: number; value: any }>,
    fields: any[]
  ) {
    let result: any[] = [];
    if ((columnSearch || []).length == 0 || (fields || []).length == 0) {
      return result;
    }
    let sequence = 1;
    columnSearch.map(column => {
      const currentAppField = fields.find(obj => obj.lookUpText === column.fieldName) || {};
      result.push({
        ConjuctionClause: 1, // Fixed conjunction clause - always 1
        FieldID: currentAppField.id ?? null, // Handle undefined id gracefully
        Value: column.value
          ? 'date' == (currentAppField.fieldType || '').toLowerCase()
            ? moment(column.value).format('YYYY-MM-DD')
            : 'datetime' == (currentAppField.fieldType || '').toLowerCase()
              ? moment(column.value).format('YYYY-MM-DDTHH:mm:ssZ')
              : column.value
          : null, // derived from filter object
        fieldName: column.fieldName, // For testing purposes
        RelationalOperator: column.filterType || 11, // received from column or 11 (contains)
        ValueType: 1, // always 1
        Sequence: sequence++, // Incremental sequence number
        GroupID: 1, // For now keep 1
        FieldType: column.fieldName.includes('.') ? 2 : 1, //   lookup 2 and entity 1
        LookUpDetail: column.fieldName.includes('.') ? column.fieldName : null //
      });
    });
    return result;
  }

  /**
   * Recursively cleans an object by removing null, undefined, empty string, empty arrays,
   * and empty objects. If the input is not an object or is null, it returns the input as is.
   * @param obj - The object to be cleaned.
   * @returns A new object with only valid values.
   */
  public cleanObject = (obj: any): any => {
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }

    // Create a new object to store the cleaned values
    const cleaned: any = Array.isArray(obj) ? [] : {};

    for (const key in obj) {
      const value = obj[key];
      // Only add the key if the value is valid
      if (
        value !== null &&
        value !== undefined &&
        value !== '' &&
        !(Array.isArray(value) && value.length === 0) &&
        !(typeof value === 'object' && Object.keys(value).length === 0)
      ) {
        // If the value is an object (not an array), clean it
        if (typeof value === 'object' && !Array.isArray(value)) {
          cleaned[key] = this.cleanObject(value);
        } else {
          // Otherwise, just assign the value directly
          cleaned[key] = value;
        }
      }
    }

    return cleaned;
  };


  /**
   * Process the component extension based on the type and screen ID.
   * @param component - The component object
   * @param type - The type of the component
   * @param rootInstance - The root instance of the component
   * @returns The instance of the component extension or null if the component does not have an extension
   */
  public processComponentExtension(component: any, type: ComponentType, rootInstance: any, extensionDependencies: any) {
    // Check if the component has an extension based on the screen ID and type
    if (TabCoreComponents?.some((item: any) =>
      item?.screenId?.toLowerCase() === component?.screen?.toLowerCase() &&
      item.componentKey === component?.key &&
      item.type === type
    )) {
      // If the component has an extension, generate the extension
      return this.generateComponentExtension(component, type);
    } else {
      // If the component does not have an extension, generate a dynamic extension
      return this.generateDynamicComponentExtension(component?.extension, rootInstance, extensionDependencies);
    }
  }

  /**
   * Generates a component extension based on the given component type.
   *
   * @param component - The component object to find the extension for.
   * @param type - The type of the component (Grid, KanbanBoard, TreeView).
   * @returns The instance of the component extension if found, or null otherwise.
   */
  private generateComponentExtension(component: any, type: ComponentType) {
    let extensions: any = [];

    // Determine the appropriate extension registry based on the component type
    switch (type) {
      case ComponentType.Grid:
        // Get implementations for Grid extensions
        extensions = IGridExtensionRegistry?.GetImplementations();
        break;

      case ComponentType.KanbanBoard:
        // Get implementations for Kanban Board extensions
        extensions = IKanBanExtensionRegistry?.GetImplementations();
        break;

      case ComponentType.TreeView:
        // Get implementations for Tree View extensions
        extensions = ITreeViewExtensionRegistry?.GetImplementations();
        break;
    }

    // Iterate over the extensions to find a matching implementation
    for (let x = 0; x < extensions?.length; x++) {
      // Instantiate the current extension implementation
      const currentExtensionData = new extensions[x]();

      // Check if the current extension matches the component's screen ID and key
      if (
        currentExtensionData?.screenId?.toLowerCase() === component?.screen?.toLowerCase() &&
        currentExtensionData?.componentKey === component?.key
      ) {
        // Return the matching extension instance
        return currentExtensionData;
      }
    }

    // Return null if no matching extension is found
    return null;
  }

  /**
   * Generates a dynamic component for the given extension based on the provided code.
   * @param content The TypeScript code for the component.
   * @param rootInstance The view container to attach the component to.
   * @returns The instance of the new component. If the component could not be created, null is returned.
   */
  private generateDynamicComponentExtension(content: any, rootInstance: any, extensionDependencies: any): any {
    if (!content)
      return null;

    // Create a new component from the provided content
    const currentGridExtensionRef: any = this._runtimeCompilerService.createComponent(
      extensionDependencies,  // The dependencies for the component
      content,  // The TypeScript code for the component
      '',  // The HTML template for the component
      '',  // The CSS styles for the component
      rootInstance  // The view container to attach the component to
    );

    // Assign the instance of the new component to the currentGridExtension property
    return currentGridExtensionRef?.instance;
  }

  /**
    * Gets the selected value for a specific component from URL
    * @param selectionType - Type of selection (e.g., 'componentType - grid', 'formType - wizard')
    * @param elementId - Identifier for the element (componentKey for component - grids, screenId for form - wizard)
    * @returns Selected value (e.g., rowId, activeStep) or null if not found
    */
  getSelection(selectionType: string, elementId: string): string | null {
    const selectionsParam = this._activatedRoute.snapshot.queryParamMap.get('selections');

    if (!selectionsParam) {
      return null;
    }

    try {
      const selections = JSON.parse(decodeURIComponent(selectionsParam));
      return selections[selectionType]?.[elementId] || null;
    } catch {
      return null;
    }
  }

  /**
   * Updates the selection value in URL for a specific component
   * @param selectionType - Type of selection (e.g., 'componentType - grid', 'formType - wizard')
   * @param elementId - Identifier for the element (componentKey for component - grids, screenId for form - wizard)
   * @param selectedValue - Value to be stored (e.g., rowId, activeStep)
   */
  updateSelection(selectionType: string, elementId: string, selectedValue: any): void {
    const currentParams = this._activatedRoute.snapshot.queryParamMap;
    const currentSelectionsParam = currentParams.get('selections');
    const selections = currentSelectionsParam ? JSON.parse(decodeURIComponent(currentSelectionsParam)) : {};

    if (!selections[selectionType]) {
      selections[selectionType] = {};
    }

    selections[selectionType][elementId] = selectedValue;

    // Convert ParamMap to regular object
    const currentQueryParams: { [key: string]: string } = {};
    currentParams.keys.forEach(key => {
      currentQueryParams[key] = currentParams.get(key) as string;
    });
    
    const queryParams = {
      ...currentQueryParams,
      selections: JSON.stringify(selections)
    };

    this._router.navigate(
      [],
      {
        relativeTo: this._activatedRoute,
        queryParams,
        queryParamsHandling: 'merge'
      }
    );
  }
}

// Fetch the data from the DSQ using the DSQ ID and parameters
// this.fetchDSQData(dsqId, reqtokens, params)
//     .then((result: any) => {
//         // If the data is retrieved successfully, return the result
//         observer.next(result);
//         observer.complete();
//     })
//     .catch((error: any) => {
//         // If an error occurs, log the error and return null
//         console.error('An error occurred while fetching data from DSQ ID:', error);
//         observer.next(null);
//         observer.complete();
//     });

// /**
//  * Fetches data from the specified data source query (DSQ).
//  * It retrieves the application object and DSQ details using the DSQ ID.
//  * If the DSQ and application object are found, it creates a payload with the DSQ name,
//  * application object name, request tokens, and additional parameters.
//  * It then executes the DSQ with the payload using the tabGetService.
//  *
//  * @param {string} dsqId - The ID of the DSQ to fetch data from.
//  * @param {any} reqtokens - The request tokens to include in the payload.
//  * @param {any} params - Additional parameters to include in the payload.
//  * @returns {Promise<any>} A Promise that resolves to the result of the DSQ execution.
//  */
// private fetchDSQData(dsqId: string, reqtokens?: any, params?: any): Promise<any> {
//     const appObject = getAppObjectFromDataSourceQueryID(dsqId)?.ObjectName;
//     const dsq = getDataFromDataSourceQueryID(dsqId)?.QueryName;

//     if (!dsq || !appObject) {
//         throw new Error('DSQ or AppObject not found');
//     }

//     const payload = {
//         AppObjectName: appObject,
//         DSQName: dsq,
//         Reqtokens: reqtokens,
//         ...params
//     };

//     return firstValueFrom(this.tabGetService.executeRAWDSQWithName(payload));
// }

// return new Observable((observer) => {
//     // Process the CRUD app object from the given app object ID or DSQ ID
//     this.processCRUDAppObject(appObjectId, dataSourceQueryId)
//         .then((result: any) => {
//             // If the app object is retrieved successfully, emit the result and complete the Observable
//             observer.next(result);
//             observer.complete();
//         })
//         .catch((error: any) => {
//             // If an error occurs, log the error and emit null and complete the Observable
//             console.error('An error occurred while getting app object details:', error);
//             observer.next(null);
//             observer.complete();
//         });
// });

// /**
//  * Processes the CRUD app object from the given app object ID or DSQ ID.
//  * If the app object ID is provided, it retrieves the app object from the app object ID.
//  * If the DSQ ID is provided, it retrieves the app object from the DSQ ID.
//  * If the app object is invalid, it throws an error.
//  * If the app object is a CRUD app object, it retrieves the CRUD app object from the app object ID.
//  * @param {string} appObjectId - The ID of the app object to process.
//  * @param {string} dataSourceQueryId - The ID of the DSQ to process.
//  * @returns {Promise<any>} A Promise that resolves to the processed app object.
//  */
// private async processCRUDAppObject(appObjectId: any, dataSourceQueryId?: any) {
//     let appObject: any;

//     // Retrieve the app object from the app object ID or DSQ ID
//     if (appObjectId) {
//         appObject = getAppObject(appObjectId);
//     } else if (dataSourceQueryId) {
//         appObject = getAppObjectFromDataSourceQueryID(dataSourceQueryId);
//     }

//     // Check if the app object is invalid
//     if (!appObject) {
//         throw new Error('Invalid app object');
//     }

//     // If the app object is a CRUD app object, retrieve the CRUD app object from the app object ID
//     if (appObject?.ObjectType === 2) {
//         appObject = await getAppObject(appObject?.CRUDAppObjectId);
//     }

//     // Return the processed app object
//     return appObject;
// }
