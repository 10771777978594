import { inject, Injectable } from '@angular/core';
import { Observable, of, switchMap, throwError } from 'rxjs';
import { AuthUtils } from '../utils/auth.utils';
import { AuthService as UtilityAuthService, StorageConstants, LocalStorageService, SessionStorageService, TabGetService } from '@techextensor/tab-core-utility';
import { Constants } from '../const/constants';
import { HelperFunctionService } from './helper-function.service';
import { BroadcastChannelService } from './cross-tab-authentication.service';
import { environment } from '../../../environments/environment';
import { TokenRefreshService } from './token-refresh.service';
import { SessionInactivityService } from './session-inactivity.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
    public _authenticated: boolean = false;
    private _initializationComplete: boolean = false;
    private _localStorageService = inject(LocalStorageService);
    private _sessionStorageService = inject(SessionStorageService);
    private _authService = inject(UtilityAuthService);
    public _tabGetService = inject(TabGetService);
    public _helperFunctionService: HelperFunctionService = inject(HelperFunctionService);
    private _broadcastChannelService = inject(BroadcastChannelService);
    private _tokenRefreshService: TokenRefreshService = inject(TokenRefreshService);
    private _sessionInactivityService: SessionInactivityService  = inject(SessionInactivityService);

    set accessToken(token: string) {
        // sessionStorage.setItem('token', 'Bearer ' + token);
        this._sessionStorageService.setSessionStorage(StorageConstants.token, 'Bearer ' + token);
    }

    get accessToken(): string {
        // return sessionStorage.getItem('token') ?? '';
        return this._sessionStorageService.getSessionStorage(StorageConstants.token) ?? '';
    }


    constructor() {
        this._broadcastChannelService.authenticationEmitter.subscribe(isAuthenticated => {
            this._authenticated = isAuthenticated;
        })
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<any> {
        if (this._authenticated) {
            return throwError(() => new Error('User is already logged in.'));
        }
        return this._authService.signIn(Constants.LOGIN_URL, credentials).pipe(
            switchMap(async (res: any) => {
                const authData: any = {
                    userInfo: res.Result,
                    token: `Bearer ${res.Result.Token}`,
                    refreshToken: res?.Result?.RefreshToken,
                    refreshTokenExpiry: res?.Result?.RefreshTokenExpiry,
                };

                if(authData.appCode === environment.commonConfig.applicationCode){
                    this._sessionStorageService.setSessionStorage(StorageConstants.TenantId, res?.Result?.user?.DefaultTenantId);
                    this._sessionStorageService.setSessionStorage(StorageConstants.AppId, res?.Result?.user?.DefaultAppId);
                }

                this._broadcastChannelService.setAuthData(authData);
                
                await this._helperFunctionService.loadApp();
                
                this._broadcastChannelService.broadcastSignIn(authData);
                this._tokenRefreshService.resetServiceState();
                // this._sessionInactivityService.resetServiceState();

                return of(res.Result);
            })
        );


    }

    /**
     * Sign up a new tenant user
     *
     * @param credentials - The user's credentials including first name, last name, email, password, tenant name, and terms
     * @returns An Observable that emits the response from the server
     */
    signUp(credentials: {  AdminFirstName: string, AdminLastName: string, AdminEmail: string,
        Password: string, TenantName: string, terms: boolean}): Observable<any>{

        return this._authService.registerTenant(Constants.SIGNUP_URL, credentials).pipe(
            switchMap(async (res: any) => {                
                this._sessionStorageService.setSessionStorage(StorageConstants.TenantId, res?.user?.DefaultTenantId);
                // Store the response from the server in local storage
                this._localStorageService.setLocalStorage(StorageConstants.userInfo, res);
                // Update the access token
                this.accessToken = res.Token;
                // Mark the user as authenticated
                this._authenticated = true;
                // Load the app
                // await this._helperFunctionService.loadApp();
                // this._appHelper.storeSchemaIntoIDB();

                return of(res);
            })
        );
    }
    
    signInUsingToken(): Observable<any> {
        if (AuthUtils._verifyJWTToken(this.accessToken)) {
            this._authenticated = true;
            return of(true);
        }
        else {
            return of(false);
        }
    }

    /**
     * Sign out the user by removing the user info from local storage and the access token from session storage.
     * Also, clear the IndexedDB.
     *
     * @return {Observable<any>} A observable that emits true when the user is successfully signed out.
     */
    signOut(): Observable<any> {
        // Remove the user info from local storage
        // this._localStorageService.removeLocalStorage(StorageConstants.userInfo);

        // Remove the org info from local storage
        // this._localStorageService.removeLocalStorage("orgInfo");

        // Remove the access token from session storage
        // this._sessionStorageService.removeSessionStorage(StorageConstants.token);

        // Remove the application code from session storage
        // this._sessionStorageService.removeSessionStorage(StorageConstants.applicationCode);

        // Mark the user as unauthenticated
        // this._authenticated = false;

        this._broadcastChannelService.broadcastSignOut();
        this._tokenRefreshService.resetServiceState();
        // this._sessionInactivityService.resetServiceState();

        // this._sessionStorageService.clearSessionStorage();
        // this._localStorageService.clearLocalStorage();

        // Clear the IndexedDB
        // this._idbService.clearIndexedDB();

        // Return an observable that emits true
        return of(true);
    }

    check(): Observable<boolean> {
        if (!this._initializationComplete) {
            return new Observable((observer) => {
                this._broadcastChannelService.initialize().then((result: any) => {
                    this._initializationComplete = true;
                    observer.next(result);
                })
            });
        }
        
        if (this._authenticated) {
          this._sessionStorageService.removeSessionStorage(StorageConstants.staticToken);
            return of(true);
        }

        
        if (!this.accessToken) {
            this._sessionStorageService.setSessionStorage(StorageConstants.staticToken, Constants.staticToken);
            return of(false);
        }

        return this.signInUsingToken();
    }
}
