import { Component, ElementRef, HostListener, ViewChild, inject } from '@angular/core';
import { NgClass, NgForOf, NgIf } from '@angular/common';

import { LocalStorageService, SessionStorageService, StorageConstants } from '@techextensor/tab-core-utility';
import { HeadlessService } from '@novu/headless';

import { Constants } from '../../../core/const/constants';
import { HelperFunctionService } from '../../../core/services/helper-function.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-switcher',
  standalone: true,
  imports: [ NgIf, NgClass,NgForOf],
  templateUrl: './tab-app-switch.component.html',
  styleUrl: './tab-app-switch.component.scss'
})
export class AppSwtichComponent {
  private _sessionStorageService: SessionStorageService = inject(SessionStorageService);
  private _localStorageService: LocalStorageService = inject(LocalStorageService);
  public headlessService: HeadlessService;
  private readonly router: Router = inject(Router);   
  private readonly _helperFunctionService: HelperFunctionService = inject(HelperFunctionService);
  @ViewChild('popup') popup: ElementRef | undefined;
  selectedTenant: any;
  selectedAppId: any;
  isPopupVisible?: boolean;
  isLoading?: boolean;
  appList: any;
  isAppListVisible?: boolean;
  tenantList: any;


  /**
   * Creates a new instance of the HeadlessService.
   * This method is called only once, during the component's initialization.
   * It sets the application identifier, subscriber ID, backend URL, and socket URL
   * for the HeadlessService.
   * @returns {HeadlessService} The instance of the HeadlessService.
   */
  constructor() {
    let personId =  this.getPersonIdFromLocalStorage();
    this.headlessService = new HeadlessService({
      applicationIdentifier: Constants.applicationIdentifier,
      subscriberId: personId,
      backendUrl: Constants.backendUrl,
      socketUrl: Constants.socketUrl,
    });
  }
  /**
   * Retrieves the person ID of the currently logged in user from local storage.
   * If the user is not logged in, it returns null.
   * @returns {string | null} The person ID of the currently logged in user, or null if the user is not logged in.
   */
  getPersonIdFromLocalStorage() {
    let loginUserData = JSON.parse(this._localStorageService.getLocalStorage(StorageConstants.userInfo))?.user;
    return loginUserData?.Attributes?.PersonId?.toLowerCase() || null;    
  }

  ngOnInit(): void {
    this.selectedTenant = this._sessionStorageService.getSessionStorage('TenantId');
    this.selectedAppId = this._sessionStorageService.getSessionStorage('AppId');
    this._sessionStorageService.setSessionStorage(StorageConstants.environmentMode, "Development");
    this.getTenantListfromLoggedInUser();
  }
 
  getTenantListfromLoggedInUser() {
    // Parse the user details from local storage
    const userDetail = JSON.parse(this._localStorageService.getLocalStorage('userInfo'));
    // Show loader before making the API call
    this.isLoading = true;

    // Execute the API call
    this._helperFunctionService.getDSQData(Constants.List_UserwiseTenantsView,{Id: userDetail.user?.Attributes.Id }).subscribe((res: any) => {
      // Check if API call is successful
      if (res && res?.Result?.length > 0) {
        // Update organization list
        this.tenantList = res.Result;
        // Fetch the list of apps for the selected tenant
        this.getAppListByTenant(this.selectedTenant);
        // Hide loader after API call completes
        this.isLoading = false;
      }
    });
  }

  // Tenant shortener
  getInitials(phrase: string): string {
    return phrase
      .split(' ')                // Split the phrase into words
      .map(word => word[0])       // Get the first letter of each word
      .join('');                  // Join them into a single string
  }
  /**
   * Sets the selected organization and makes an API call to fetch tenant data.
   *
   * @param tenant - The selected tenant object
   */
  getAppListByTenant(tenant: any) {
    // Show loader before making the API call
    this.isLoading = true;

    // Execute the API call to fetch tenant data
    this._helperFunctionService.getDSQData(Constants.GetSubscribedApps_ActiveUserSubscriptionsView_Id, { TenantId: tenant })
      .subscribe((res: any) => {
        // Check if API call is successful
        if (res && res?.Result?.length > 0) {
          // Update app list
          this.appList = res.Result;
          this.showAppSwitcher()
          // Hide loader after API call completes
          this.isLoading = false;
        }
      });
  }
  async selectedApp(appId: any, tenatId: string) {
    // Set the app ID in session storage
    this._sessionStorageService.setSessionStorage(StorageConstants.AppId, appId.Id);
    // Set the tenant ID in session storage
    this._sessionStorageService.setSessionStorage(StorageConstants.TenantId, tenatId);
    // Set the app name in session storage
    this._sessionStorageService.setSessionStorage(StorageConstants.applicationCode, appId.AppName );
    // Set the app info in session storage
    this._sessionStorageService.setSessionStorage(Constants.appInfo, appId);
    // Load the app
    await this._helperFunctionService.loadApp();
    // Redirect to the screen
    window.location.pathname = '';
  }
   
  showAppSwitcher() {
    this.isAppListVisible = this.tenantList?.length > 1 || (this.tenantList?.length === 1 && this.appList?.length > 1);
  }
  toggleContentVisibility() {
    // Toggle the visibility of the popup content
    this.isPopupVisible = !this.isPopupVisible;
  }
  @HostListener('document:mousedown', ['$event'])
  public onOutsideClick(event: MouseEvent): void {
    if (this.isPopupVisible && this.popup && !this.popup.nativeElement.contains(event.target)) {
        this.isPopupVisible = false;
      }
  }
  addApp(){
    const applicationCode = this._sessionStorageService.getSessionStorage(StorageConstants.applicationCode);
    this.router.navigate([`${applicationCode + Constants.MARKETPLACE_SCREEN}`]);
  }
}
