import { Routes } from '@angular/router';
import { authGuard } from './core/guards/auth.guard';
import { noAuthGuard } from './core/guards/noAuth.guard';
import { LayoutComponent } from './layouts/layout.component';
import { DataResolver } from './core/resolver/data.resolver';
import { appCodeRedirectGuard } from './core/guards/app-code-redirect.guard';

export const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        canActivate: [appCodeRedirectGuard],
        children: [] // This empty children array is necessary for the canActivate to work on the root path
    },
    
    {
        path: ':appCode',
        canActivate: [noAuthGuard],
        canActivateChild: [noAuthGuard],
        children: [
            {
                path: 'sign-in',
                loadComponent: () => import('./features-modules/auth/sign-in/sign-in.component').then(m => m.SignInComponent),
            },
            {
                path: 'sign-up',
                loadComponent: () => import('./features-modules/auth/sign-up/sign-up.component').then(m => m.SignUpComponent),
            },
            {
                path: 'forgot-password',
                loadComponent: () => import('./features-modules/auth/forgot-password/forgot-password.component').then(m => m.ForgotPasswordComponent),
            },
            {
                path: 'reset-password',
                loadComponent: () => import('./features-modules/auth/reset-password/reset-password.component').then(m => m.ResetPasswordComponent),
            },
            { path:'', redirectTo: 'sign-in', pathMatch: 'full' }
        ]
    },
    
    {
        path: ':appCode',
        canActivate: [authGuard],
        canActivateChild: [authGuard],
        resolve: { resolvedData: DataResolver },
        component: LayoutComponent,
        children: [
            {
                path: 'change-password',
                loadComponent: () => import('./features-modules/auth/change-password/change-password.component').then(m => m.ChangePasswordComponent),
            },
            {
                path: 'screen',
                children: [
                    {
                        path: ':screenId',
                        loadComponent: () => import('./features-modules/formio/tab-form-renderer/tab-form-renderer.component').then(m => m.TabFormRendererComponent),
                        data: { screenRenderingMode: 'MainScreen' }
                    },
                    {
                        path: '',
                        loadComponent: () => import('./features-modules/formio/tab-form-renderer/tab-form-renderer.component').then(m => m.TabFormRendererComponent),
                        data: { screenRenderingMode: 'MainScreen' }
                    }
                ]
            }
        ]
    },

    {
        path: ':appCode',
        canActivate: [authGuard],
        canActivateChild: [authGuard],
        resolve: { resolvedData: DataResolver },
        children: [
            {
                path: 'org-setup/:screenId',
                loadComponent: () => import('./features-modules/formio/tab-form-renderer/tab-form-renderer.component').then(m => m.TabFormRendererComponent),
                data: { screenRenderingMode: 'MainScreen' }
            }
        ]
    },

    { 
        path: '**', 
        canActivate: [appCodeRedirectGuard],
        children: [] // This empty children array is necessary for the canActivate to work on the root path
    }
];