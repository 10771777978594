<div *ngIf="isUserPopupVisible" class="user-drop-down" #userPopup>
  <div class="welcome">
    <img src='https://api.dicebear.com/6.x/initials/svg?seed={{loginData?.FirstName}} {{loginData?.LastName}}' />
    <div class="user-name-layout">
      <h3>{{loginData?.FirstName}} {{loginData?.LastName}}</h3>
      <p>{{roleNames}}</p>
    </div>
  </div>
  <ul class="my-1">
    <!-- <li (click)="getProfile()"><i class="user-icon" ></i>Profile</li> -->
    <!-- <li><i class="e-settings e-icons" style="font-size: 18px;"></i>Setting</li> -->
    <li (click)="onResetPassword()"><i class="pwd-reset" style="font-size: 18px;"></i>{{'Reset Password' | Translate}}</li>
    <li (click)="onOraganizationSetting()"><i class="org-setup" style="font-size: 18px;"></i>{{'Organization Setup' | Translate}}</li>
    <!-- <li> -->
      <!-- <i class="e-chevron-left e-icons"></i> -->
      <!-- <span class="status online"></span>
        Status -->
      <!-- <ul>
        <li><span class="status online"></span>Online</li>
        <li><span class="status away"></span>Away</li>
        <li><span class="status busy"></span>Busy</li>
        <li><span class="status invis"></span>Invisible</li>
      </ul> -->
    <!-- </li> -->
  </ul>
  <ul class="signout-list-layout">
    <li (click)="onSignout()"><i class="signout-icon"></i>{{'Signout' | Translate}}</li>
  </ul>
</div>
