import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostListener, inject, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { getAppObject, SessionStorageService, StorageConstants, TabGetService } from '@techextensor/tab-core-utility';


// interface GroupedResults {
//   [key: string]: any;
// }

@Component({
  selector: 'app-global-search',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss'],
})
export class GlobalSearchComponent {
  @ViewChild('globalSearch') globalSearch!: ElementRef;
  private sanitizer : any = inject(DomSanitizer); // Inject DomSanitizer
  private sessionStorageService = inject(SessionStorageService);
  public  tabGetService: TabGetService = inject(TabGetService);
  public router: Router = inject(Router);

  searchResults: any; // Define the searchResults property
  selectedGroupIndex: any = 0;
  // filteredResults:any; // Define the filteredResults property
  // groupedResults: GroupedResults = {}; // Define the groupedResults property
  // currentGroup: string | null = null; // Define the currentGroup property

  isShowGlobalSearch: boolean = false;
  isFullScreen: boolean = false;
  searchTerm: string = '';
  isLoading: boolean = false;

  ngOnInit(): void {
    this.router.events.subscribe(event => { 
      if (event instanceof NavigationEnd) {
        this.clearSearch();
        this.isFullScreen = false;
        this.isShowGlobalSearch = false;
      }
    });
  }
  /**
   * Toggles the visibility of the global search component.
   * Sets the search component to non-fullscreen mode.
   */
  toggleGlobalSearch(): void {
    this.isShowGlobalSearch = !this.isShowGlobalSearch;
    this.isFullScreen = false;
  }

  
  /**
   * Called when the user submits a search query.
   * If the search query is longer than 2 characters, calls the Search API and
   * updates the `searchResults`, `filteredResults`, and `groupedResults` properties.
   * If the search query is shorter than 2 characters, clears the search results.
   */
  onSearch(): void {
    if (this.searchTerm.length >= 3) {
      // Show the loading spinner
      this.isLoading = true;

    // Set limit based on isFullScreen
    const limit = this.isFullScreen ? 1000 : 5;

    // Call the Search API with the appropriate limit
        this.tabGetService.getSearchData(this.searchTerm, limit).subscribe({
        next: (response: any) => {
          // Hide the loading spinner
          this.isLoading = false;

          // If the response contains results, process them and update the component state
          if (response?.Result) {
            this.searchResults = this.processData(response.Result);
            // this.filteredResults = this.searchResults;
            // this.groupedResults = this.searchResults;
            // this.currentGroup = this.groupedResults[0];
            // this.groupedResults = this.groupSearchResults(this.searchResults);
            // this.currentGroup = null;
          } else {
            // If the response does not contain results, clear the search results
            this.searchResults = [];
          }
        },
        error: (error:any) => {
          // Hide the loading spinner
          this.isLoading = false;

          // Log any errors that occurred while fetching search results
          console.error('Error fetching search results:', error);
        },
      });
    } else {
      // Clear the search results if the search query is shorter than 2 characters
      this.clearSearch();
    }
  }

  // /**
  //  * Groups the search results by AppObject (e.g. 'Task', 'Project', etc.).
  //  * @param results The search results to group.
  //  * @returns An object with AppObject names as keys and arrays of search results as values.
  //  */
  // groupSearchResults(results: any): GroupedResults {
  //   return results.reduce((acc: any, item: any) => {
  //     // If the AppObject is not already a key in the accumulator, add it
  //     if (!acc[item.AppObject]) {
  //       acc[item.AppObject] = [];
  //     }
  //     // Add the current search result to the array of results for the AppObject
  //     acc[item.AppObject].push(item);
  //     // Return the updated accumulator
  //     return acc;
  //   }, {} as GroupedResults);
  // }


  /**
   * Filters the search results by the given AppObject (e.g. 'Task', 'Project', etc.).
   * @param group The AppObject to filter the search results by.
   */
  filterByGroup(index: number): void {
    // Set the current group to the given AppObject
    // this.currentGroup = group;
    // Get the search results for the given AppObject
    // this.filteredResults = this.groupedResults[group] || [];
    this.selectedGroupIndex = index;
  }

  // /**
  //  * Gets all search results, without filtering by AppObject.
  //  */
  // getAllResults(): void {
  //   // Clear the current group, so that all search results are shown
  //   // this.currentGroup = null;
  //   // Set the filtered results to the complete list of search results
  //   // this.filteredResults = this.searchResults;
  // }

  /**
   * Clears the search results and resets the search form.
   */
  clearSearch(): void {
    this.searchTerm = '';
    this.searchResults = [];
    this.selectedGroupIndex = 0;
    // this.filteredResults = [];
    // this.groupedResults = {};
    // this.currentGroup = null;
    this.isLoading = false;
  }

  /**
   * Toggles the full-screen search results view.
   * Hides the compact search results view and shows the full-screen search results view.
   */
  viewAllResults(): void {
    this.searchResults = [];
    this.selectedGroupIndex = 0;
    this.isFullScreen = true;
    this.isShowGlobalSearch = false;
    this.onSearch();
  }

  
  /**
   * Closes the full-screen search results view.
   * Hides the full-screen search results view and shows the compact search results view.
   */
  closeFullScreen(): void {
    this.searchResults = [];
    this.selectedGroupIndex = 0;
    this.isFullScreen = false;
    this.isShowGlobalSearch = true;
    this.onSearch();
  }

  /**
   * Handles clicks outside the global search component.
   * Closes the search component if it's visible and the click occurred outside of it.
   * 
   * @param event - The MouseEvent triggered by the click.
  */
  @HostListener('document:mousedown', ['$event'])
  public onOutsideClick(event: MouseEvent): void {
    // Check if the global search is visible, not in fullscreen, and the click is outside the search area
    if (
      this.isShowGlobalSearch &&
      !this.isFullScreen &&
      this.globalSearch?.nativeElement &&
      !this.globalSearch.nativeElement.contains(event.target)
    ) {
      // Close the global search and clear the search results
      this.isShowGlobalSearch = false;
      this.clearSearch();
    }
  }


  /**
   * Processes the search results by mapping over the array and extracting the AppObject and SearchInfo from each item.
   * @param searchResults - The array of search results from the API.
   * @returns An array of objects with AppObject and SearchInfo properties.
   */
  processData(searchResults: any): any[] {
    return searchResults.map((item: any) => {
      const appObjectDetails = getAppObject(item.AppObjectId);
      item.AppObject = appObjectDetails?.SystemDBTableName;
      return item;
      // /**
      //  * Extract the AppObject and SearchInfo from the item and return a new object with those properties.
      //  */
      // return {
      //   AppObject: appObjectDetails.SystemDBTableName,
      //   SearchInfo: item.GlobalSearchInfo,
      // };      
    });
  }


  /**
   * Opens a link in a new tab based on the provided RedirectUrl.
   * The link is constructed by prepending the base URL and appending the RedirectUrl.
   * If the RedirectUrl is empty, the link is not opened and the search results are not cleared.
   * @param RedirectUrl - The URL to be opened in the new tab.
   */
  openLink(RedirectUrl: string): void {
    if (RedirectUrl) {
      const appCode = this.sessionStorageService.getSessionStorage(StorageConstants.applicationCode);
      const link = `${window.location.origin}/${appCode}/screen/${RedirectUrl}`;
      window.open(link, '_blank');

      // Close the search results and clear the search field
      // this.isShowGlobalSearch = false;
      // this.isFullScreen = false;
      // this.clearSearch();
    }
  }

  getSafeHtml(content: string) {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }
}