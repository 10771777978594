import { Injectable, Component, ViewEncapsulation, ViewContainerRef, EnvironmentInjector, createComponent, inject, Provider, ComponentRef } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RuntimeCompilerService {
  
  private environmentInjector: EnvironmentInjector = inject(EnvironmentInjector);

  constructor() { }

  createComponent(
    dependencies: { name: string; dependency: any }[] = [],
    componentCode: string,
    template: string,
    styles: string,
    viewContainerRef: ViewContainerRef,
  ) {
    try {
      const componentType = this.createComponentType(dependencies, componentCode, template, styles);
      const componentRef: ComponentRef<any> = createComponent(componentType, { environmentInjector: this.environmentInjector });
  
      if (componentRef?.instance) {
        viewContainerRef.insert(componentRef?.hostView);
        return componentRef;
      }
  
      return null;
    }
    catch (error) {
      console.error(error);
      return null;
    }
  }

  private createComponentType(
    dependencies: { name: string; dependency: any }[],
    componentCode: string,
    template: string,
    styles: string,
  ){
    const typescript = (window as any).ts;
    const result = typescript.transpileModule(componentCode, {
      compilerOptions: {
        module: typescript.ModuleKind.ESNext,
        target: typescript.ScriptTarget.ES2020
      }
    });

    const code = result.outputText;
    const providersMap: Record<string, Provider> = dependencies.reduce((acc, dep) => (
      {
        ...acc,
        [dep.name]: dep.dependency,
      }
    ),{});

    const providersConsts = dependencies.map((dep) => `const ${dep.name} = providersMap['${dep.name}'];`).join('\n');
    const bodyCode = `${providersConsts} return ${code};`;
    const componentClass = new Function( 'inject', 'providersMap', bodyCode )(inject, providersMap);
        
    return Component({
      selector: `tab-screen-extension-${Math.floor(10000 + Math.random() * 90000)}`,
      template,
      styles,
      encapsulation: ViewEncapsulation.Emulated,
    })(componentClass);
  }
}
