import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApiStatus, ApiStatusService } from '../services/api-status.service';

@Injectable()
export class ApiStatusInterceptor implements HttpInterceptor {
  constructor(private apiStatusService: ApiStatusService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const shouldTrack = request.headers.has('X-Track-API-Details');
    if (!shouldTrack) {
      return next.handle(request); // Skip tracking if the header is absent
    }

    const startTime = Date.now();

    return next.handle(request).pipe(
      tap(
        event => {
          if (event instanceof HttpResponse) {
            const endTime = Date.now();
            const executionTime = endTime - startTime;

            const responseSize = this.calculateResponseSize(event);

            const status: ApiStatus = {
              url: request.url,
              status: event.statusText,
              statusCode: event.status,
              executionTime,
              size: responseSize
            };

            this.apiStatusService.setLatestStatus(status);
          }
        },
        error => {
          const endTime = Date.now();
          const executionTime = endTime - startTime;
          const status: ApiStatus = {
            url: request.url,
            status: error.status ? error.statusText : 'Internal Server Error',
            statusCode: error.status || 500,
            executionTime,
            error: error.message,
            size: 'N/A'
          };

          this.apiStatusService.setLatestStatus(status);
        }
      )
    );
  }

  // Helper method to calculate the response size in KB or MB and return as string
  private calculateResponseSize(response: HttpResponse<any>): string {
    let sizeInBytes = 0;

    if (response.body) {
      // Convert the body to JSON string and get the byte length
      const responseBodyString = JSON.stringify(response.body);
      sizeInBytes = new TextEncoder().encode(responseBodyString).length;
    }

    // Convert bytes to KB or MB
    const sizeInKB = sizeInBytes / 1024;
    if (sizeInKB >= 1024) {
      // Convert to MB if the size is greater than or equal to 1024 KB
      return `${(sizeInKB / 1024).toFixed(2)} MB`;
    } else {
      return `${sizeInKB.toFixed(2)} KB`;
    }
  }
}
