<!-- <div class="main-container">
  <div class="left-container" [ngClass]="isPanelOpen ? 'openLeftPanel' : 'closeLeftPanel' ">
    <div class="menu-top-logo">
      <img src="../../assets/images/login-logo.svg">
    </div>
    <div class="left-navigation" >
        <app-left-navigation></app-left-navigation>
    </div>
  </div>
  <div class="right-container" [ngClass]="isPanelOpen ? 'openLeftPanel' : 'closeLeftPanel' ">
    <app-top-header (panelOpenClose)="panelOpenClose($event)"></app-top-header>
    <app-form-designer></app-form-designer>
  </div>
</div> -->

<div class="main-container" [ngClass]="(organizationInfo?.ShowHorizontalLayout == 'false' || organizationInfo?.ShowHorizontalLayout == false) ? 'left-sidbar-active':'left-sidbar-deActive'">
  <!-- *ngIf="organizationInfo?.ShowHorizontalLayout == 'false' || organizationInfo?.ShowHorizontalLayout == false " -->
  <div class="left-container" [ngClass]="panelStatus ? 'closeLeftPanel' : 'openLeftPanel' " *ngIf="organizationInfo?.ShowHorizontalLayout == 'false' || organizationInfo?.ShowHorizontalLayout == false ">
    <div class="menu-bar-btn-layout" (click)="leftMenuSlide()">
      <i class="e-icons" [ngClass]="panelStatus ? 'e-chevron-left-3' : 'e-chevron-right-4' "></i>
    </div>
    <div class="menu-top-logo"  #popup>
      <!-- <app-switcher></app-switcher> -->
      <img [src]="organizationInfo?.Org_Menu_Logo" style="max-width: 50px;">
      <!-- Static Logo bind -->
      <!-- <img src="../../assets/images/app/techapp_force.svg" style="max-width: 205px;"> -->
    </div>
    <div class="left-navigation" >
      <app-menu-bar></app-menu-bar>
    </div>
  </div>
  <div class="right-container" [ngClass]="panelStatus ? 'openRightContainer' : 'closeRightContainer' ">
    <!-- (panelOpenClose)="panelOpenClose($event)"   -->
    <app-header></app-header>
    <div class="left-space-container">
      <!-- <app-form-designer></app-form-designer> -->
      <router-outlet></router-outlet>
    </div>
  </div>
</div>



<!-- <router-outlet></router-outlet>
<app-header></app-header>
<app-menu-bar></app-menu-bar> -->
