import { Component, ViewChild, inject } from '@angular/core';
import { TreeViewAllModule } from '@syncfusion/ej2-angular-navigations';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Constants } from '../../core/const/constants';
import { HelperFunctionService } from '../../core/services/helper-function.service';
import { SessionStorageService, StorageConstants } from '@techextensor/tab-core-utility';

@Component({
  selector: 'app-menu-bar',
  standalone: true,
  imports: [TreeViewAllModule],
  templateUrl: './menu-bar.component.html',
  styleUrl: './menu-bar.component.scss',
})
export class MenuBarComponent {
  public activeScreen: any;
  public fields: any;
  @ViewChild('menuTree') menuTree: any;
  private readonly router: Router = inject(Router);                 
  public readonly route: ActivatedRoute = inject(ActivatedRoute);
  private helperFunctionService: HelperFunctionService = inject(HelperFunctionService);
  private sessionStorageService: SessionStorageService = inject(SessionStorageService);
  constructor() {}

  ngOnInit(): void {
    // Update the active screen based on the current URL
    this.router.events.subscribe(event => { 
      if (event instanceof NavigationEnd) {
        this.updateActiveScreen();
      }
    });

    //Load menu data
    // this.helperFunctionService.getDSQData(Constants.Default_TABMD_Menu_Items_Id,{AppId:this.sessionStorageService.getSessionStorage('AppId')?this.sessionStorageService.getSessionStorage('AppId'):''})
    this.helperFunctionService.getDSQData(Constants.Default_TABMD_Menu_Items_Id)
    .subscribe((response: any) => {
      this.fields = this.generateMenuFieldsFromData(response?.Result ?? []);  
      // Update the selected menu item based on the current URL
      setTimeout(() => {
        this.updateActiveScreen();
      }, 500);
    });
  }

  /**
   * Updates the active screen based on the current URL.
   * If the URL has a screenId, it will select the corresponding menu item.
   * If the URL does not have a screenId, it will clear the previous selection.
   */
  private updateActiveScreen() {
    let currentRoute = this.route;
    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
    }

    // Get the screenId from the URL
    const screenId = currentRoute.snapshot.paramMap.get('screenId');

    // If the URL has a screenId, select the corresponding menu item
    if (screenId && screenId !== '') {
      // Clear the previous selection
      this.clearPreviousSelection(this.activeScreen, screenId);
      // Set the active screen to the new screenId
      setTimeout(() => {
        this.activeScreen = screenId;
        // Select the active screen
        this.selectActiveScreen();
      }, 500);
    }
    // If the URL does not have a screenId, clear the previous selection
    else if (!screenId && this.activeScreen) {
      delete this.activeScreen;
      this.clearPreviousSelection(null);
    }
  }

  /**
   * Generates the menu fields from the given data.
   * @param data - The data used to generate the menu fields.
   * @returns The generated menu fields.
   */
  generateMenuFieldsFromData(data: any) {
    let fieldData: any = [];
    data.sort((a: any, b: any) => a.Sequence - b.Sequence);

    // Mapping logic simplified
    fieldData = data?.map((item: any) => ({
      id: item['Id'],
      name: item['DisplayName'],
      expanded: false,
      hasChildren: data.some((x: any) => x['ParentID'] === item['Id']),
      parentID: item['ParentID'],
      icon: `e-icons ${item['Icon']}`,
      screenId: item?.ScreenID ?? null,
      selected: false,
    }));
    return {
      dataSource: fieldData,
      id: 'id',
      text: 'name',
      child: 'child',
      parentID: 'parentID',
      hasChildren: 'hasChildren',
      isChecked: 'isChecked',
    };
  }

  /**
   * Handles the click event on a menu item.
   * If the menu item has a link, navigates to that link.
   * @param args - The event arguments containing the clicked menu item.
   */
  public onMenuItemClicked(args: any) {
    // Get the data of the clicked menu item
    const nodeData = this.menuTree.getTreeData(args.node)[0];

    // If the menu item has a link, navigate to that link
    if (nodeData?.screenId && nodeData?.screenId != '') {
      this.sessionStorageService.removeSessionStorage(StorageConstants.screenContext);
      const applicationCode = this.sessionStorageService.getSessionStorage(StorageConstants.applicationCode);
      this.router.navigate([`/${applicationCode}/screen/${nodeData?.screenId}`]);
    }
  }

  /**
   * Selects the active screen in the menu tree.
   * If the active screen is not set or there are no menu items, does nothing.
   */
  private selectActiveScreen() {
    if (!this.activeScreen || !this.fields?.dataSource?.length) return;

    // Find the menu item that matches the active screen
    const selectedNode = this.fields.dataSource.find((item: { screenId: string }) => item.screenId === this.activeScreen);
    if (selectedNode) {
      // Set the selected nodes to the ID of the selected node
      this.menuTree.selectedNodes = [selectedNode.id];

      // Expand all the parent nodes of the selected node
      this.menuTree.expandAll(this.getParentListId(selectedNode.id));
    }
  }

  /**
   * Returns a list of parent node IDs for the given parent node ID.
   * Used to expand all the parent nodes of the selected node.
   * @param parentID - The ID of the parent node.
   * @returns A list of parent node IDs.
   */
  private getParentListId(parentID: any): number[] {
    const parentList: number[] = [];
    
    let parentNode = this.fields?.dataSource?.find((item: any) => item?.id === parentID);
    while (parentNode) {
      parentList.push(parentNode.id);
      parentNode = this.fields?.dataSource?.find((item: any) => item?.id === parentNode?.parentID); 
    }
    return parentList;
  }

  /**
   * Clears the previous selection in the menu tree by collapsing all the parent nodes of the active screen.
   * If the next screen is provided, it will collapse all the parent nodes of the active screen that are not also parents of the next screen.
   * @param activeScreen - The ID of the active screen.
   * @param nextScreen - The ID of the next screen.
   */
  private clearPreviousSelection(activeScreen: any, nextScreen?: any) {
    // Clear the selection
    this.menuTree.selectedNodes = [];
    
    if(activeScreen){
      // Find the active screen node
      const selectedNode = this.fields?.dataSource?.find((item: any) => item?.screenId === activeScreen);
      
      // Find the next screen node if provided
      const nextScreenNode =  nextScreen ? this.fields?.dataSource?.find((item: any) => item?.screenId === nextScreen) : undefined;

      // Get the parent IDs of the active screen node
      const selectedParents = this.getParentListId(selectedNode?.id);
      
      // If the next screen node is provided, get its parent IDs
      const nextScreenParents = nextScreenNode ? this.getParentListId(nextScreenNode?.id) : [];
      
      // Get the parent IDs that are not shared with the next screen
      const parentIds = selectedParents.filter((parentId: any) => !nextScreenParents.includes(parentId)); 
      
      // Collapse all the parent nodes of the active screen that are not also parents of the next screen
      this.menuTree.collapseAll(parentIds);
    } else {
      // If no active screen is provided, collapse all the nodes
      this.menuTree.collapseAll();
    }
  }
}
// /**
//  * Updates the selected menu item based on the current URL.
//  */
// private processActiveMenu() {
//   if (!this.activeScreen || !this.fields?.dataSource?.length) return;

//   // Find the menu item that matches Screen
//   const selectedNode = this.fields?.dataSource?.find((item: any) => item?.screenId === this.activeScreen);
//   if (selectedNode) {
//     // Set new selection
//     selectedNode.selected = true;
//     this.expandParents(selectedNode.parentID);
//   }
// }

// /**
//  * Expands the parent nodes of the selected node.
//  * @param parentID - The parent ID of the selected node.
//  */
// private expandParents(parentID: any) {
//   if (parentID) {
//     const parentNode = this.fields?.dataSource?.find((item: any) => item?.id === parentID);
//     if (parentNode) {
//       parentNode.expanded = true;
//       this.expandParents(parentNode?.parentID);
//     }
//   }
// }

// /**
//  * Clears the previous active menu.
//  */
// private clearPreviousActiveMenu() {
//   this.fields?.dataSource?.forEach((item: any) => {
//     item.isSelected = false;
//     item.expanded = false;
//   });
// }