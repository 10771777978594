import { Component, OnInit, ViewChild, HostListener, ElementRef, inject, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { NgClass, NgIf } from '@angular/common';
// import { Title } from '@angular/platform-browser';

import { MenuBarComponent } from './menu-bar/menu-bar.component';
import { HeaderComponent } from './header/header.component';
import { AppSwtichComponent } from './header/tab-app-switch/tab-app-switch.component';

import { LocalStorageService, SessionStorageService, StorageConstants } from '@techextensor/tab-core-utility';
import { Constants } from '../core/const/constants';
import { HelperFunctionService } from '../core/services/helper-function.service';
import { OrgAppInfoHelperService } from '../core/services/org-app-info-helper.service';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [RouterOutlet, MenuBarComponent, HeaderComponent, NgClass, NgIf,AppSwtichComponent],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
})
export class LayoutComponent implements OnInit {
  tenantList?: any = [];
  appList?: any = [];
  isPopupVisible: boolean = false; // Add a variable to control content visibility
  selectedTenant?: any;
  selectedAppId?: string;
  isLoading: boolean = false; // Flag to track loading state
  @ViewChild('popup') popup: ElementRef | undefined;
  private localStorageService: LocalStorageService = inject(LocalStorageService);
  private sessionStorageService: SessionStorageService = inject(SessionStorageService);
  private helperFunctionService: HelperFunctionService = inject(HelperFunctionService); 
  private readonly orgAppInfoHelper: OrgAppInfoHelperService = inject(OrgAppInfoHelperService);
  public organizationInfo: any = {};
  private readonly router: Router = inject(Router);
  public readonly route: ActivatedRoute = inject(ActivatedRoute);
  // isPanelOpen: boolean = true;
  panelStatus: boolean = false;
  @Output() panelOpenClose = new EventEmitter<boolean>();

  // public favIcon: HTMLLinkElement = document.querySelector(
  //   '#appIcon'
  // ) as HTMLLinkElement;
  // private title: Title = inject(Title);
  isAppListVisible: boolean = false;
  /**
   * Initialize the component after Angular initializes the data-bound input properties.
   * The ngOnInit() method is called once, after the constructor, and immediately after the
   * first ngOnChanges().
   * It is an appropriate place to put initialization code.
   */
  ngOnInit(): void {
    this.selectedTenant = this.sessionStorageService.getSessionStorage('TenantId');
    this.selectedAppId = this.sessionStorageService.getSessionStorage('AppId');
    this.organizationInfo = JSON.parse(this.orgAppInfoHelper.getOrgInfo())?.LocaleSettings;
    // Retrieve organization information from local storage
    // this.getOrgInfo();
    // this.loadTitleAndFaviconIfNeeded();
    // Fetch the list of organizations for the logged-in user
    // this.getTenantListfromLoggedInUser();
    this.router.events.subscribe(event => { 
      if (event instanceof NavigationEnd) {
        this.updateActiveScreen();
      }
    });
    this.getMenuLogo();
  }

  async getMenuLogo(){
    this.organizationInfo.Org_Menu_Logo = await this.orgAppInfoHelper.getOrgLogo(this.organizationInfo?.Org_Menu_Logo)
  }

  private updateActiveScreen() {
    let currentRoute = this.route;
    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
    }
    
    const screenId = currentRoute.snapshot.paramMap.get('screenId');
        
    if (screenId && screenId !== '') {
      if(this.panelStatus){
        this.panelStatus = false;
        this.panelOpenClose.emit(this.panelStatus);
      }
    }
  }

  getOrgInfo() {
    // Retrieve the 'orgInfo' key from the local storage and parse its value as JSON
    // this.organizationInfo = JSON.parse(
    //   this.localStorageService.getLocalStorage(StorageConstants.orgInfo)
    // );
  }

  public loadTitleAndFaviconIfNeeded() {
    // Set the title and favicon based on the organization name
    // if(!this.title.getTitle()){
    //   this.favIcon.href = this.organizationInfo?.FavIcon;
    //   this.title.setTitle(this.organizationInfo?.OrganizationName);
    // }
  }

  /**
   * Fetches the list of organizations for the logged-in user from the API.
   * On success, it retrieves the tenant details and hides the loader.
   */
  getTenantListfromLoggedInUser() {
    // Parse the user details from local storage
    const userDetail = JSON.parse(this.localStorageService.getLocalStorage('userInfo'));
    // Show loader before making the API call
    this.isLoading = true;

    // Execute the API call
    this.helperFunctionService.getDSQData(Constants.List_UserwiseTenantsView,{Id: userDetail.user?.Attributes.Id }).subscribe((res: any) => {
      // Check if API call is successful
      if (res && res?.Result?.length > 0) {
        // Update organization list
        this.tenantList = res.Result;
        // Fetch the list of apps for the selected tenant
        this.getAppListByTenant(this.selectedTenant);
        // Hide loader after API call completes
        this.isLoading = false;
      }
    });
  }

  /**
   * Sets the selected organization and makes an API call to fetch tenant data.
   *
   * @param tenant - The selected tenant object
   */
  getAppListByTenant(tenant: any) {
    // Show loader before making the API call
    this.isLoading = true;

    // Execute the API call to fetch tenant data
    this.helperFunctionService.getDSQData(Constants.GetSubscribedApps_ActiveUserSubscriptionsView_Id, { TenantId: tenant })
      .subscribe((res: any) => {
        // Check if API call is successful
        if (res && res?.Result?.length > 0) {
          // Update app list
          this.appList = res.Result;
          this.showAppSwitcher()
          // Hide loader after API call completes
          this.isLoading = false;
        }
      });
  }
  showAppSwitcher() {
    this.isAppListVisible = this.tenantList?.length > 1 || (this.tenantList?.length === 1 && this.appList?.length > 1);
  }
  /**
   * Sets the app ID and tenant ID in session storage and redirects to the screen.
   *
   * @param appId - The ID of the app to select
   */
  async selectedApp(appId: any, tenatId: string) {
    // Set the app ID in session storage
    this.sessionStorageService.setSessionStorage(StorageConstants.AppId, appId.Id);
    // Set the tenant ID in session storage
    this.sessionStorageService.setSessionStorage(StorageConstants.TenantId, tenatId);
    // Set the app name in session storage
    this.sessionStorageService.setSessionStorage(StorageConstants.applicationCode, appId.AppName );
    // Load the app
    await this.helperFunctionService.loadApp();
    // Redirect to the screen
    window.location.pathname = '';
  }

  /**
   * Toggles the panel open/close state.
   *
   * @param event - A boolean indicating whether the panel should be open or closed.
   */
  // panelOpenClose(event: boolean) {
  //   this.isPanelOpen = event;
  // }

  /**
   * Toggles the visibility of the popup content.
   * 
   * This function toggles the visibility of the popup content by changing the value of the `isPopupVisible` property.
   * If the popup is currently visible, it will be hidden. If the popup is currently hidden, it will be shown.
   */
  toggleContentVisibility() {
    // Toggle the visibility of the popup content
    this.isPopupVisible = !this.isPopupVisible;
  }

  @HostListener('document:mousedown', ['$event'])
  /**
   * Listen for a click event on the document and check if the click was outside the notification popup.
   * If the click was outside the popup, close the popup.
   *
   * @param event - The click event.
   */
  onOutsideClick(event: any) {
    // Check if the notification popup is visible and the click is outside the popup
    if (this.isPopupVisible && this.popup && !this.popup.nativeElement.contains(event.target)) {
      this.isPopupVisible = false;
    }
  }

  leftMenuSlide() {
    this.panelStatus = !this.panelStatus;
    this.panelOpenClose.emit(this.panelStatus);
  }

}
