import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Pipe({
  name: 'Translate',
  standalone: true,
  pure: false // This makes the pipe impure, so it will update on every change detection cycle
})
export class TranslatePipe implements PipeTransform {
  private langChangeSub: Subscription;

  constructor(private translate: TranslateService) {
    // Subscribe to language change
    this.langChangeSub = this.translate?.onLangChange?.subscribe(() => {
      // When the language changes, Angular will automatically trigger the pipe
    });
  }

  /**
   * Transforms the given value using translation.
   * @param value The value to translate
   * @returns The translated value, or an empty string if value is falsy
   */
  transform(value: string): string {
    // Return the translated value, or an empty string if value is falsy
    return value ? this.translate.instant(value) : '';
  }

  /**
   * Unsubscribes from the language change subscription
   * to prevent memory leaks
   */
  ngOnDestroy(): void {
    if (this.langChangeSub) {
      this.langChangeSub?.unsubscribe();
    }
  }
}
