import { Injectable, inject } from '@angular/core';
import { BroadcastChannelService } from './cross-tab-authentication.service';
import { SessionStorageService, StorageConstants } from '@techextensor/tab-core-utility';
import { debounceTime, fromEvent } from 'rxjs';
import { ConfirmationDialogService } from './confirmation-dialog.service';

@Injectable({
    providedIn: 'root'
})
export class SessionInactivityService {

    private warnigDisplayed = false;
    private idleTimer: any = null;
    private logoutTimer: any = null;
    
    private readonly IDLE_TIMEOUT = 60 * 60 * 1000;    // 1 hour
    private readonly LOGOUT_TIMEOUT = 5 * 60 * 1000;    // 5 minutes
    
    private readonly activityEvents = ['keypress','click'];

    private boundUserActivity: () => void;

    private readonly _broadcastChannelService: BroadcastChannelService = inject(BroadcastChannelService);
    private readonly _sessionStorageService: SessionStorageService = inject(SessionStorageService);
    private readonly _confirmationDialogService: ConfirmationDialogService = inject(ConfirmationDialogService);

    constructor() {
        // this.setupEventListeners();
        // this.startMonitoring();
        this.boundUserActivity = this.onUserActivity.bind(this);
    }

    /**
     * Reset session inactivity monitoring
     * Use this method after important user actions or API calls
     * to ensure the session stays active
     */
    public resetServiceState(): void {
        this.startMonitoring();
        this.warnigDisplayed = false;
    }

    private setupEventListeners() {
        // Handle authentication state changes
        this._broadcastChannelService.authenticationEmitter.subscribe((isAuthenticated: boolean) => {
           if (isAuthenticated) {
                this.startMonitoring();
            } else {
                this.stopMonitoring();
            }
        });
    }

    /**
     * Start monitoring user activity in this tab
     */
    public startMonitoring(): void {
        if (!this._sessionStorageService.getSessionStorage(StorageConstants.token)) return;

        this.stopMonitoring();
        this.startIdleTimer();

        // Add event listeners for user activity
        this.activityEvents.forEach(event => {
            fromEvent(document, event).pipe(debounceTime(5000)).subscribe(this.boundUserActivity);
        });
    }

    /**
     * Stop monitoring user activity
     */
    public stopMonitoring(): void {
        if (this.idleTimer) {
            clearTimeout(this.idleTimer);
            this.idleTimer = null;
        }
        if (this.logoutTimer) {
            clearTimeout(this.logoutTimer);
            this.logoutTimer = null;
        }

        this.removePopup();

        this.activityEvents.forEach(event => {
            document.removeEventListener(event, this.boundUserActivity);
        });
    }

    /**
     * Handler for user activity
     */
    private onUserActivity = (): void => {
        // Only reset timer if warning isn't shown
        if (!this.warnigDisplayed) {
            this.startIdleTimer();
        }
    };

    /**
     * Start/Reset the idle timer
     */
    private startIdleTimer(): void {
        if (this.idleTimer) {
            clearTimeout(this.idleTimer);
        }
        this.idleTimer = setTimeout(() => {
            if (this._sessionStorageService.getSessionStorage(StorageConstants.token)){
                // Show warning popup
                this.showWarningPopup();
            }
        }, this.IDLE_TIMEOUT);
    }

    /**
     * Show warning popup and start logout timer
     */
    private showWarningPopup(): void {
        this.warnigDisplayed = true;
        this._confirmationDialogService.confirm({
            title: 'Are you still there?',
            message: "if not, we'll close this session.",
            primaryOption: "I'm here",
            displayCloseButton: false, 
            displaySecondaryButton: false
        }).subscribe((result: boolean) => {
            if(result) {
                this.keepSessionAlive();
            }
        });

        // Start logout timer
        this.logoutTimer = setTimeout(() => {
            this.signout();
        }, this.LOGOUT_TIMEOUT);

    }

    /**
     * Keep session active when user interacts
     */
    private keepSessionAlive(): void {
        if (this.logoutTimer) {
            clearTimeout(this.logoutTimer);
            this.logoutTimer = null;
        }
        this.removePopup();
        this.startIdleTimer();
    }

    /**
     * Remove warning popup
     */
    private removePopup(): void {
        this.warnigDisplayed = false;
        this._confirmationDialogService.closeDialog();
    }

    /**
     * Logout user when session expires
     */
    private signout(): void {
        this._broadcastChannelService.broadcastSignOutCurrentTab();
    }
}