import { Injectable, inject } from "@angular/core";
import { Router } from "@angular/router";
import { SessionStorageService, StorageConstants } from "@techextensor/tab-core-utility";
import { Constants } from "../const/constants";

@Injectable({
    providedIn:'root'
})
export class ScreenNavigationMappingService {
    private readonly _sessionStorageService: SessionStorageService = inject(SessionStorageService);
    private readonly _router: Router = inject(Router);

    /**
     * Adds the current screen mapping to the session storage.
     * 
     * @param {string} currentScreen - The current screen to add the mapping for.
     */
    public addCurrentScreenNavigationMapping(currentScreen: string) {
        try{
            // Get the segments of the router URL
            const segments = this._router.parseUrl(this._router.url).root.children['primary'].segments;
            // Get the parent screen from the last segment
            const parentScreen = segments[segments.length - 1].path;
            if(parentScreen){
                // Get the current screen mapping from the session storage or initialize it as an empty object
                let screenMapping = this._sessionStorageService.getSessionStorage(Constants.screenNavigationMapping);    
                screenMapping = screenMapping ? JSON.parse(screenMapping) : {};
                // Add the mapping between the current screen and the parent screen to the screen mapping
                screenMapping[currentScreen] =  parentScreen;
                // Set the updated screen mapping in the session storage
                this._sessionStorageService.setSessionStorage(Constants.screenNavigationMapping, screenMapping);
            }
        }
        catch(error) {
            // Log an error message if there is an error parsing the screen mapping from the router
            console.error('Failed to parse screenMapping from router', error);
        }
    }

    /**
     * Redirects to the parent screen of the given current screen.
     * 
     * @param {string} currentScreen - The current screen to redirect from.
     */
    public redirectToParentScreen(currentScreen: string) {
        // Get the screen mapping from the session storage
        const screenMappingStr = this._sessionStorageService.getSessionStorage(Constants.screenNavigationMapping);

        if (screenMappingStr) {
            try {
                // Parse the screen mapping from the session storage
                const screenMapping: Record<string, string> = JSON.parse(screenMappingStr);
                
                if(screenMapping.hasOwnProperty(currentScreen))
                { 
                    // Get the parent screen for the current screen from the screen mapping
                    const parentScreen = screenMapping[currentScreen];

                    // Delete the current screen from the screen mapping
                    delete screenMapping[currentScreen];

                    // Update the screen mapping in the session storage
                    this._sessionStorageService.setSessionStorage(Constants.screenNavigationMapping, JSON.stringify(screenMapping));

                    const applicationCode = this._sessionStorageService.getSessionStorage(StorageConstants.applicationCode);

                    // Navigate to the parent screen
                    this._router.navigate([`${applicationCode}/screen`, parentScreen]);
                }
            }
            catch (error) {
                // Log an error message if there is an error parsing the screen mapping from the session storage
                console.error('Failed to parse screenMapping from session storage', error);
            }
        }
    }

    /**
     * Checks if a parent mapping exists for the given screen.
     * 
     * @param {string} screen - The screen to check.
     * @returns {boolean} True if a parent mapping exists, false otherwise.
     */
    public doseHaveParentMapping(screen: string): boolean {
        // Get the screen mapping from the session storage
        const screenMappingStr = this._sessionStorageService.getSessionStorage(Constants.screenNavigationMapping);
        // Parse the screen mapping from the session storage
        const screenMapping = JSON.parse(screenMappingStr ?? '{}');

        // Check if a parent screen exists in the screen mapping
        // for the given screen
        return screenMapping?.[screen] ?? false;
    }

}