import { inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import formioButton from "../../features-modules/formio/component json/formio-button";
import formioDropdown from "../../features-modules/formio/component json/formio-dropdown";
import { SessionStorageService, StorageConstants } from "@techextensor/tab-core-utility";
import { ScreenNavigationMappingService } from "./screen-navigation-mapping.service";

@Injectable({
    providedIn: 'root'
})
export class EventActionService {
    private readonly router: Router = inject(Router);
    private readonly sessionStorageService: SessionStorageService = inject(SessionStorageService);
    private screenNavigationMappingService: ScreenNavigationMappingService = inject(ScreenNavigationMappingService);

    /**
     * Dispatches a custom event to the window with the given name and detail object.
     * @param {string} name - The name of the custom event.
     * @param {any} detail - The detail object to be passed to the event listener.
     */
    public broadcastEvent(name: string, detail: any) {
        const customEvent = new CustomEvent(name, { detail: detail });
        window.dispatchEvent(customEvent);
    }

    /**
     * Opens a screen in the specified mode.
     *
     * @param {string} mode - The mode in which to open the screen.
     *                        Possible values: 'sidebar', 'popup', 'navigationInSameTab', and 'navigationInNewTab'.
     * @param {string} screen - The ID of the screen to open.
     * @param {any} parameters - (Optional) The parameters to pass to the screen.
     * @param {any} submission - (Optional) The submission data to pass to the screen.
     */
    public openScreen(mode: string, screen: string, parameters?: any, submission?: any, otherConfiguration?: any) {
        switch (mode) {
            // Open the screen in the sidebar
            case 'sidebar':
                // Dispatch a custom event to open the screen in the sidebar
                this.broadcastEvent('openTabSidebar', {
                    screenId: screen, // The ID of the screen to open
                    record: parameters, // The parameters to pass to the screen
                    submission: submission, // The submission data to pass to the screen
                    ...otherConfiguration
                });
                break;

            // Open the screen in a popup
            case 'popup':
                // Dispatch a custom event to open the screen in a popup
                this.broadcastEvent('openTabModal', {
                    screenId: screen, // The ID of the screen to open
                    record: parameters, // The parameters to pass to the screen
                    submission: submission, // The submission data to pass to the screen
                    ...otherConfiguration
                });
                break;

            // Open the screen in the same tab
            case 'navigationInSameTab': 
                {
                    this.screenNavigationMappingService.addCurrentScreenNavigationMapping(screen);
                    const applicationCode = this.sessionStorageService.getSessionStorage(StorageConstants.applicationCode);
                    // Navigate to the screen in the same tab
                    this.router.navigate([`/${applicationCode}/screen`, screen], {
                        queryParams: parameters // The parameters to pass to the screen
                    });
                }              
                break;

            // Open the screen in a new tab
            case 'navigationInNewTab':
                {
                    this.screenNavigationMappingService.addCurrentScreenNavigationMapping(screen);
                    const applicationCode = this.sessionStorageService.getSessionStorage(StorageConstants.applicationCode);
                    // Open the screen in a new tab
                    if (parameters) {
                        const queryParams = Object.keys(parameters)[0] + '=' + parameters[Object.keys(parameters)[0]];
                        window.open(`/${applicationCode}/screen/` + screen + '?' + queryParams, '_blank');
                    } else {
                        window.open(`/${applicationCode}/screen/` + screen, '_blank');
                    }
                }
                break;
        }
    }

    /**
     * Returns a JSON object for a Formio button with properties based on the given trigger component and parameters.
     * @param {any} triggerComponent - The trigger component object containing information about the button.
     * @param {any} parameters - The parameters object to be passed to the button's event listener.
     * @returns {any} - The JSON object representing the Formio button.
     */
    public getFormioButtonJSON(triggerComponent: any, parameters: any): any {
        // Create a shallow copy of the formioButton object
        const button: any = { ...formioButton };

        // Set the label, key, leftIcon, action, and event properties of the button object
        button.label = triggerComponent?.placeHolder;
        button.key = triggerComponent?.placeHolder;
        button.leftIcon = triggerComponent.icon;
        button.action = "event";
        button.event = 'onTriggerComponentClick';

        // Set the configuration property of the button object to the parameters object
        button.configuration = parameters;

        // Return the button object
        return button;
    }

    /**
     * Returns a JSON object for a Formio dropdown component with properties based on the given trigger component and parameters.
     * 
     * @param {any} triggerComponent - The trigger component object containing information about the dropdown.
     * @param {any} parameters - The parameters object to be passed to the dropdown's event listener.
     * @returns {any} - The JSON object representing the Formio dropdown component.
     */
    public getFormioDropdownJSON(triggerComponent: any, parameters: any): any {
        // Create a shallow copy of the formioDropdown object
        const dropdown: any = { ...formioDropdown };  

        // Set the label, key, hideLabel, data, defaultValue, configuration, and searchEnabled properties of the dropdown object
        dropdown.label = triggerComponent?.configScreen?.componentValue?.placeHolder; // Set the label property to the placeholder of the trigger component
        dropdown.key =  triggerComponent?.configScreen?.componentValue?.placeHolder; // Set the key property to the placeholder of the trigger component
        dropdown.hideLabel = true; // Set the hideLabel property to true to hide the label
        dropdown.data = this.getDropdownData(triggerComponent); // Set the data property to the dropdown data based on the trigger component
        dropdown.defaultValue = this.getDropdownDefaultValue(triggerComponent); // Set the defaultValue property to the dropdown default value based on the trigger component
        dropdown.configuration = parameters; // Set the configuration property to the parameters object
        dropdown.searchEnabled = false; // Set the searchEnabled property to false to disable search functionality
        dropdown.customClass = triggerComponent.configScreen.componentValue.customClass
        return dropdown; // Return the dropdown object
    }

    /**
     * Returns the data for a Formio dropdown component based on the given trigger component.
     * If the dropdown mode is 'custom', it returns an object with the custom data values.
     * Otherwise, it returns null.
     * 
     * @param {any} triggerComponent - The trigger component object containing information about the dropdown.
     * @returns {object|null} - The data object for the Formio dropdown component or null if the dropdown mode is not 'custom'.
     */
    private getDropdownData(triggerComponent: any) {
        // Check if the dropdown mode is 'custom'
        if(triggerComponent.configScreen.componentValue.mode === 'custom') {
            // Return an object with the custom data values
            return { values: triggerComponent.configScreen.componentValue.customData };
        }
        
        // Return null if the dropdown mode is not 'custom'
        return null;
    }

    /**
     * Returns the default value for a Formio dropdown component based on the given trigger component.
     * If the dropdown mode is 'custom', it searches for the custom data value that has the 'isDefault' property set to true.
     * If a matching value is found, it returns the 'value' property of that value. Otherwise, it returns an empty string.
     * If the dropdown mode is not 'custom', it returns an empty string.
     * 
     * @param {any} triggerComponent - The trigger component object containing information about the dropdown.
     * @returns {string} - The default value for the Formio dropdown component.
     */
    private getDropdownDefaultValue(triggerComponent: any) {
        // Check if the dropdown mode is 'custom'
        if(triggerComponent.configScreen.componentValue.mode === 'custom') {
            // Search for the custom data value that has the 'isDefault' property set to true
            const defaultValue = triggerComponent.configScreen.componentValue.customData.find((data: any) => data.isDefault);
            
            // Return the 'value' property of the matching value, or an empty string if no matching value is found
            return defaultValue?.value || '';
        }
        
        // Return an empty string if the dropdown mode is not 'custom'
        return '';
    }
}