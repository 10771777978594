<div class="global-search" *ngIf="isShowGlobalSearch && !isFullScreen" #globalSearch>
  <div class="search-container">
    <div class="search-input">
      <input type="text" [(ngModel)]="searchTerm" (input)="onSearch()" placeholder="Search...">
      <span *ngIf="searchTerm" (click)="clearSearch()" class="e-close e-icons"
        style="font-size: 11px;position: absolute;right: 10px;top: 50%;transform: translateY(-50%);opacity: 0.5;cursor: pointer;"></span>
      <!-- <button class="clear-button" *ngIf="searchTerm" (click)="clearSearch()">
        <p>X</p>
      </button> -->
    </div>

    <!-- Loading spinner -->
    <div *ngIf="isLoading" class="loading-spinner">
      <div class="spinner"></div>
    </div>

    <!-- Search Results -->
    <div class="search-results" *ngIf="!isLoading && searchTerm.length >= 3">
      <div class="search-results-category" *ngIf="searchResults?.length > 0">
        <div class="category-tags">
          <span class="tag blue" [class.selected]="selectedGroupIndex === i"
            *ngFor="let item of searchResults; let i = index" (click)="filterByGroup(i)">
            {{ item?.AppObject }} ({{ item?.GlobalSearchInfo?.length }})
          </span>
        </div>
        <ul>
          <li class="search-result-item" *ngFor="let searchItem of searchResults?.[selectedGroupIndex]?.GlobalSearchInfo??[]">
            <div class="move-details-page" (click)="openLink(searchItem.RedirectUrl)">
              <div class="search-history-layout">
                <!-- <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="27" height="27" rx="4" fill="#E7EFFF" />
                  <g clip-path="url(#clip0_78_24)">
                    <path
                      d="M15.4467 9.33333L18.6667 12.5533V18.6667H9.33333V9.33333H15.4467ZM15.4467 8H9.33333C8.6 8 8 8.6 8 9.33333V18.6667C8 19.4 8.6 20 9.33333 20H18.6667C19.4 20 20 19.4 20 18.6667V12.5533C20 12.2 19.86 11.86 19.6067 11.6133L16.3867 8.39333C16.14 8.14 15.8 8 15.4467 8ZM10.6667 16H17.3333V17.3333H10.6667V16ZM10.6667 13.3333H17.3333V14.6667H10.6667V13.3333ZM10.6667 10.6667H15.3333V12H10.6667V10.6667Z"
                      fill="#1967be" />
                  </g>
                  <defs>
                    <clipPath id="clip0_78_24">
                      <rect width="16" height="16" fill="white" transform="translate(6 6)" />
                    </clipPath>
                  </defs>
                </svg> -->
                <div class="task-title" [innerHTML]="searchItem.Template"></div>
              </div>
            </div>
            <!-- <span class="action-icon" (click)="openLink(searchItem.RedirectUrl)">⎘</span> -->
          </li>
        </ul>
      </div>
      <div class="view-more" *ngIf="searchResults?.length > 0">
        <button (click)="viewAllResults()">View more search results...</button>
      </div>

      <!-- No Results Found Message -->
      <div *ngIf="searchResults.length === 0">
        <p class="no-results-message">No results found for "{{ searchTerm }}"</p>
      </div>
    </div>
  </div>
</div>


<!-- Full-page Search Results -->

<div class="full-page-search" *ngIf="isFullScreen">
  <div class="search-content">
    <aside class="search-sidebar">
      <h6>Total Results: {{ searchResults.length }}</h6>
      <ul>
        <li *ngFor="let item of searchResults; let i = index" [class.selected]="selectedGroupIndex === i"
          (click)="filterByGroup(i)">
          <span class="icon">{{ item?.AppObject }}: </span>
          <span class="count">{{ item?.GlobalSearchInfo?.length }} </span>
        </li>
      </ul>
    </aside>

    <section class="full-page-search-results">
      <div class="result-header-layout">
        <h5>Search Results {{ searchResults?.length && searchResults?.[selectedGroupIndex]?.AppObject ? ' - ' + searchResults?.[selectedGroupIndex]?.AppObject : '' }}</h5>
        <div class="search-header">
          <span (click)="closeFullScreen()" class="e-close e-icons"
            style="font-size: 11px;opacity: 0.5;cursor: pointer;"></span>
        </div>
      </div>
      <div class="list-card-layout">
        <ul class="result-list">
          <li class="result-item" *ngFor="let searchItem of searchResults?.[selectedGroupIndex]?.GlobalSearchInfo??[]">
            <!-- <div class="result-avatar">
              <img src="../../../../assets/images/app/defaultImage.png" alt="Assignee" class="avatar">
            </div>
            <div class="result-content">
              <h4 (click)="openLink(searchItem.URL)" [innerHTML]="searchItem.Template"></h4>
            </div> -->
            <div class="move-details-page" (click)="openLink(searchItem.RedirectUrl)">
              <div class="search-history-layout-details">
                <!-- <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="27" height="27" rx="4" fill="#E7EFFF" />
                  <g clip-path="url(#clip0_78_24)">
                    <path
                      d="M15.4467 9.33333L18.6667 12.5533V18.6667H9.33333V9.33333H15.4467ZM15.4467 8H9.33333C8.6 8 8 8.6 8 9.33333V18.6667C8 19.4 8.6 20 9.33333 20H18.6667C19.4 20 20 19.4 20 18.6667V12.5533C20 12.2 19.86 11.86 19.6067 11.6133L16.3867 8.39333C16.14 8.14 15.8 8 15.4467 8ZM10.6667 16H17.3333V17.3333H10.6667V16ZM10.6667 13.3333H17.3333V14.6667H10.6667V13.3333ZM10.6667 10.6667H15.3333V12H10.6667V10.6667Z"
                      fill="#1967be" />
                  </g>
                  <defs>
                    <clipPath id="clip0_78_24">
                      <rect width="16" height="16" fill="white" transform="translate(6 6)" />
                    </clipPath>
                  </defs>
                </svg> -->
                <div class="task-title" [innerHTML]="searchItem.Template"></div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
  </div>
</div>