import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ConfirmationDialogService } from '../../core/services/confirmation-dialog.service';

@Component({
  standalone: true,
  selector: 'app-confirmation-dialog',
  imports: [CommonModule],
  templateUrl: './confirmation-dialog.component.html',
  styleUrl: './confirmation-dialog.component.scss'
})

export class ConfirmationDialogComponent {

  isVisible = false;
  config: any;
  private confirmationDialogService : ConfirmationDialogService = inject(ConfirmationDialogService);
  
  /**
   * The constructor of the confirmation dialog component.
   * It subscribes to the dialog emitter of the confirmation dialog service
   * and updates the config and visibility of the dialog whenever a new
   * configuration is emitted.
   */
  constructor() {
    this.config = this.getDefaultConfig();
    this.confirmationDialogService.dialogEmitter.subscribe((config: any) => {
      this.config = {
        ...this.config,
        ...config ?? {}
      };

      this.isVisible = true;
    })

    this.confirmationDialogService.removeEmitter.subscribe(() => {
      this.resetDefaltConfig();
    })
  }

  /**
   * Emits a confirmation event and hides the confirmation dialog.
   * This event is emitted when the user clicks the confirm button.
   */
  onConfirm() {
    this.confirmationDialogService.conformationEmitter.emit(true);
    this.resetDefaltConfig();
  }

  /**
   * Emits a cancelation event and hides the confirmation dialog.
   * This event is emitted when the user clicks the cancel button.
   */
  onCancel() {
    this.confirmationDialogService.conformationEmitter.emit(false);
    this.resetDefaltConfig();
  }

  private resetDefaltConfig() {
    this.isVisible = false;
    this.config = this.getDefaultConfig();
  }

  private getDefaultConfig() {
    return {
      title : "Are you sure you want to Delete", 
      message :  "You are about to delete the selected record. Are you sure you want to proceed?",
      primaryOption : "Ok",
      secondaryOption : "Cancel",
      displayHeader : true,
      displayFooter : true,
      displayBody : true,
      displayPrimaryButton : true,
      displaySecondaryButton : true,
      displayCloseButton : true
    };
  }
  
  // ngOnDestroy() {
  //   this.confirmationDialogService.dialogEmitter.unsubscribe();
  // }
}