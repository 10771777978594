import { inject, Injectable } from "@angular/core";
import { HelperFunctionService } from "./helper-function.service";
import { AccessPermission, AccessType, Common, LocalStorageService, SessionStorageService, StorageConstants } from "@techextensor/tab-core-utility";
import { Constants, UserRole } from "../const/constants";

@Injectable({
    providedIn: 'root'
})
export class PermissionService {

    private readonly helperFunctionService: HelperFunctionService = inject(HelperFunctionService);
    private readonly localStorageService: LocalStorageService = inject(LocalStorageService);
    private readonly sessionStorageService: SessionStorageService = inject(SessionStorageService);

    constructor() { }

    /**
     * Checks if the current user has the specified data access permission for the given app object or DSQ.
     * @param accessType The type of access to check for.
     * @param appObjectId The ID of the app object to check the permission for.
     * @param dsqId The ID of the DSQ to check the permission for. Optional.
     * @returns A boolean indicating whether the user has the specified data access permission.
     */
    public doesHaveDataAccessPermission(accessType: AccessType, appObjectId: any, dsqId?: any): boolean {
        // Get the app object from the given app object ID or DSQ ID
        const appObject = this.helperFunctionService.getCRUDAppObject(appObjectId, dsqId);
    
        // Get the roles from the user info in local storage
        const roles = JSON.parse(this.localStorageService?.getLocalStorage(StorageConstants.userInfo) ?? '[]')?.user?.Roles;
    
        // If the app object or roles are invalid, return false
        if (!appObject || !roles?.length) {
            return false;
        }
    
        // Check if any of the roles have the specified access permission and return the result
        return appObject.AccessList?.some((access: any) =>
            access?.IsEnabled &&
            roles.some((role: any) => access?.RoleID?.toLowerCase() === role?.ID?.toLowerCase()) &&
            access[accessType] === AccessPermission.All
        ) ?? false;
    }
    
    /**
     * Checks if the user has the specified configured permission.
     * @param configuredPermissionId The ID of the configured permission to check for.
     * @returns A boolean indicating whether the user has the specified configured permission.
     */
    public doesHaveConfiguredPermission(...args: any[]): boolean {    
        // Flatten the array and remove any nested arrays
        const configuredPermissions = args?.filter(arg => arg != null)?.flat(Infinity)?.map(String);
    
        // If the tabUserPermissions object is invalid or empty, return false
        if (
            !Common?.tabUserPermissions || !Common.tabUserPermissions?.length ||
            !configuredPermissions || !configuredPermissions?.length
        ) {
            return false;
        }
    
        // Convert all permissions to lowercase for case-insensitive comparison
        const lowerCaseUserPermissions = Common.tabUserPermissions?.map((permission: any) => 
            permission?.PermissionId?.toLowerCase()
        );
    
        // Check if any of the configured permissions match the user permissions
        return configuredPermissions?.some((permission: any) => 
            lowerCaseUserPermissions?.includes(permission?.toLowerCase())
        );
    }
    
    /**
     * Checks if the user is a System Administrator.
     * @returns A boolean indicating whether the user is a System Administrator.
     */
    public doesUserIsSystemAdministrator(): boolean {
        // Check if the user has the System Administrator role
        return this.doesUserIs(UserRole.SystemAdministrator);
    }

    /**
     * Checks if the user is a TAB Administrator.
     * @returns A boolean indicating whether the user is a TAB Administrator.
     */
    public doesUserIsTabAdministrator(): boolean {
        // Check if the user has the TAB Administrator role
        return this.doesUserIs(UserRole.TABAdministrator);
    }
    
    /**
     * Checks if the user has any of the specified roles.
     * @param roles The IDs or names of the roles to check for.
     * @returns A boolean indicating whether the user has any of the specified roles.
     */
    public doesUserIs(...roles: any): boolean {
        // Get the roles from the user info in local storage
        const loggedInUserRoles = JSON.parse(this.localStorageService?.getLocalStorage(StorageConstants.userInfo) ?? '{}')?.user?.Roles ?? [];
        
        // Check if any of the roles have the specified ID or name and return the result
        return roles?.some((role: any) => loggedInUserRoles?.some((loggedInUserRole: any) => 
            loggedInUserRole?.ID?.toLowerCase() === role?.toLowerCase() || loggedInUserRole?.RoleName === role
        ));
    }

    /**
     * Checks if the user is the owner of the app.
     * @param userId The user ID to check.
     * @returns A boolean indicating whether the user is the owner of the app.
     */
    public doesUserIsAppOwner(userId: string): boolean {
        // Get the app info from session storage
        const appInfo = JSON.parse(this.sessionStorageService.getSessionStorage(Constants.appInfo) ?? '{}');
        if (userId && appInfo && appInfo?.RecordInfo?.OWNER) {
            // Check if the user ID matches the owner ID of the app
            return appInfo.RecordInfo.OWNER.toLowerCase() === userId.toLowerCase();
        }
        return false;
    }
}

// /**
//  * Process the permission to check if the user has the specified data access permission.
//  * @param accessType The type of access to check for.
//  * @param appObjectId The app object ID to check the permission for.
//  * @param dsqId The data source query ID to check the permission for.
//  * @returns A boolean indicating whether the user has the specified data access permission.
//  */
// private async processPermission(accessType: AccessType,  appObjectId: any, dsqId?: any): Promise<boolean> {
//     // Get the app object using the getCRUDAppObject helper function
//     const appObject:any = await firstValueFrom(this.helperFunctionService.getCRUDAppObject(appObjectId, dsqId));

//     // Get the roles from the user info in local storage
//     const roles: any = JSON.parse(this.localStorageService?.getLocalStorage(StorageConstants.userInfo) ?? '{}')?.user?.Roles;

//     // If the app object and roles are valid, filter the access list to only include roles that have the specified access type and are enabled
//     if (appObject && roles) {
//         const accessList = appObject?.AccessList?.filter((access: any) =>
//             roles.some((role: any) => access?.RoleID?.toLowerCase() === role?.ID?.toLowerCase() && access?.IsEnabled)
//         );

//         // If the access list is not empty, loop through it and return true if any of the roles have the specified access permission
//         if (accessList?.length > 0) {
//             for (let i = 0; i < accessList?.length; i++) {
//                 if (accessList[i][accessType] === AccessPermission.All)
//                     return true;
//             }
//         }
//     }

//     // If the access list is empty or none of the roles have the specified access permission, return false
//     return false;
// }

// /**
//  * Checks if the user has the specified role.
//  * @param roleId The ID of the role to check for.
//  * @returns A boolean indicating whether the user has the specified role.
//  */
// public matchUserRole(roleId: string): boolean {
//     // Get the roles from the user info in local storage
//     const roles = JSON.parse(this.localStorageService?.getLocalStorage(StorageConstants.userInfo) ?? '{}')?.user?.Roles ?? [];

//     // Check if any of the roles have the specified ID and return the result
//     return roles?.some((role: any) => role?.ID?.toLowerCase() === roleId?.toLowerCase());
// }