// api-status.service.ts
import { Injectable } from '@angular/core';

export interface ApiStatus {
  url: string;
  status: string;
  statusCode: number;
  executionTime: number;
  error?: string;
  size: string;
}

@Injectable({
  providedIn: 'root'
})
export class ApiStatusService {
  private maxHistorySize = 5; // Maximum number of stored statuses
  private statusMap: Map<string, ApiStatus> = new Map(); // Key: URL, Value: ApiStatus

  // Set the latest status for a URL (replaces any previous status)
  setLatestStatus(status: ApiStatus): void {
    // Check if the map already has the maximum allowed entries
    if (this.statusMap.size >= this.maxHistorySize) {
      // Remove the oldest entry (the first one inserted in the Map)
      const firstKey = this.statusMap.keys().next().value;
      this.statusMap.delete(firstKey || ''); // Delete the oldest entry
    }

    // Update the map with the new status for the given URL
    this.statusMap.set(status.url, status);
  }

  // Get the latest status for a URL (most recent status)
  getLatestStatus(url: string): ApiStatus | null {
    return this.statusMap.get(url) || null; // Return null if no status exists for the URL
  }

  // Get the status for a specific URL
  getStatusByUrl(url: string): ApiStatus | null {
    return this.statusMap.get(url) || null; // Return null if no status exists for the URL
  }
}
