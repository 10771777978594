<div class="top-header">
  <!-- (click)="leftMenuSlide()" -->

  <!-- *ngIf="!isHorizontalLayout" -->
  <!-- <div class="menu-bar-btn" (click)="leftMenuSlide()">
    <i class="e-line-normal e-icons"></i>
  </div> -->
  <!-- App switcher -->
  <div class="col-2 d-flex p-0">
    <app-switcher></app-switcher>
    <div class="horizontallogo">
     <!-- Dynamic logo bind -->
     <!-- <img [src]="orgInfo?.Org_Menu_Logo" style="max-width: 24px;margin-left: 1.2rem;top: 2px;position: relative;"> -->
     <!-- Static Logo bind -->
     <!-- <img src="../../assets/images/app/techapp_force.svg" style="max-width: 150px;margin-left: 1.2rem;top: 2px;position: relative;"> -->
     <img [src]="orgInfo?.Org_Menu_Logo ? orgInfo?.Org_Menu_Logo : '../../assets/images/app/techapp_force.svg'" style="max-width: 150px;margin-left: 1.2rem;top: 2px;position: relative;">
   
    </div>
  </div>
  <!--horizontal menu -->
  <div class="menu-control horizontal-menu col-7 p-0" *ngIf="isHorizontalLayout">
    <ejs-menu
    #menuObj
    class="ejsMenu"

    [items]="menuItems"
    [enableScrolling]="enablescroll"
    [cssClass]="menuCssClass"

    (select)="onMenuItemClicked($event)"
    (beforeOpen)="onMenuItemBeforeOpen($event)"
  ></ejs-menu>


  </div>
  <!-- end -->
  <div class="top-header-right">
    <!-- <div>
      <select (change)="onChangeLanguage($event)" class="form-control">
        @for(language of languageList; track languageList) {
          <option [value]="language.SystemValue" [selected]="language.SystemValue === selectedLanguage">{{ language.DisplayValue }}</option>
        }
      </select>
    </div> -->
    <div>
      <select class="form-control" (change)="onChangeEnvironment($event)" *ngIf="isBuildAndDevelopeModeUsercanAccess()">
        <option value="Development" selected>{{'Development' | Translate}}</option>
        <option value="QA">{{"QA" | Translate}}</option>
        <option value="Production" >{{"Production" | Translate}}</option>
      </select>
    </div>
    <div class="top-header-icon" (click)="rebuildApp()" *ngIf="isBuildAndDevelopeModeUsercanAccess()">
      <i [ngClass]="showLoader? 'loader-build' : '' " class="refresh-icon"></i>
    </div>
    <div class="top-header-icon" (click)="onFullScreenClick()">
      <i
        [ngClass]="{
          'expand-icon': !fullScreen,
          'collapse-icon': fullScreen
        }">
      </i>
    </div>
    <div class="top-header-icon" (click)="showSearch()">
      <i style="font-size: 17px" class="e-search e-icons"></i>
    </div>
    <!-- (click)="showNotification()" -->
    <div class="top-header-icon" (click)="showNotification()">
      <i style="font-size: 24px" class="notification-bell"></i>
      <!-- Badge for notification count -->
      <span class="badge-container">
        <span class="badge">{{ notificationCount }}</span>
        <!-- <span class="badge">12</span> -->
      </span>
    </div>
    <!-- (click)="userDropDown()" -->
    <div class="top-header-icon user-dd-menu" (click)="userDropDown()">
      <i style="font-size: 24px" class="user-icon"></i>
    </div>
  </div>
</div>
<app-global-search #globalSearch></app-global-search>
<app-notification *ngIf="isNotificationListVisible" [headlessService]="headlessService"
(removeNotificationEvent)="handleRemoveNotification($event)"
(markAsReadEvent)="handleMarkAsRead($event)"
(markAllLoadUnreadCount)="loadUnreadCount()"
>
</app-notification>
<app-user-drop-down *ngIf="isUserDropDownVisible"></app-user-drop-down>
