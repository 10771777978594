import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { SessionStorageService, StorageConstants } from '@techextensor/tab-core-utility';
import { environment } from '../../../environments/environment';

/**
 * Guard that redirects to the appropriate app code URL.
 * Extracts app code from session storage, URL, or environment config.
 * @returns UrlTree with the correct app code path
 */
export const appCodeRedirectGuard: CanActivateFn = (): UrlTree => {
    const router = inject(Router);
    const sessionStorageService =  inject(SessionStorageService);
    const url = document?.location?.pathname;
    const match = url?.match(/^\/([^\/]+)/);
    const appCode = sessionStorageService.getSessionStorage(StorageConstants.applicationCode) ?? match?.[1] ?? environment.commonConfig.applicationCode;
    
    return router.createUrlTree([appCode+'/screen/']);
};