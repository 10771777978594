import { EventEmitter, inject, Injectable } from '@angular/core';
import { firstValueFrom, map, race, take, timer } from 'rxjs';
import { SessionStorageService, LocalStorageService, StorageConstants, IdbService, Common } from '@techextensor/tab-core-utility';
import { Router } from '@angular/router';
import { Constants } from '../const/constants';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { OrgAppInfoHelperService } from './org-app-info-helper.service';
import { HelperFunctionService } from './helper-function.service';

@Injectable({
  providedIn: 'root'
})
export class BroadcastChannelService {
  public tabId: string;
  private channel: BroadcastChannel;

  private readonly authDataSubject: EventEmitter<any> = new EventEmitter();
  public readonly authenticationEmitter: EventEmitter<boolean> = new EventEmitter();
  public readonly tokenRefreshEmitter: EventEmitter<any> = new EventEmitter();

  private readonly router: Router = inject(Router);
  private readonly idbService: IdbService = inject(IdbService);
  private readonly tostrService: ToastrService = inject(ToastrService);
  private readonly sessionStorageService: SessionStorageService = inject(SessionStorageService);
  private readonly localStorageService: LocalStorageService = inject(LocalStorageService);
  private readonly orgAppInfoHelper: OrgAppInfoHelperService = inject(OrgAppInfoHelperService);
  private readonly helperFunctionService: HelperFunctionService = inject(HelperFunctionService);

  /** Initializes the BroadcastChannelService */
  constructor() {
    this.tabId = this.generateTabId();
    this.channel = new BroadcastChannel('auth_channel');
    this.channel.onmessage = this.handleMessage.bind(this);
  }

  /**
   * Initializes the service and checks authentication status
   * @returns {boolean} Whether the user is authenticated or not
   */
  async initialize(): Promise<boolean> {
    const appCode = window.location.pathname.match(/^\/([^\/]+)/)?.[1];
    const sessionAppCode = this.sessionStorageService.getSessionStorage(StorageConstants.applicationCode);

    // If the user is not authenticated, load the organization information, set the tenant ID, and request the authentication data
    if (!this.sessionStorageService.getSessionStorage(StorageConstants.token)) {
      const orgInfo = await this.orgAppInfoHelper.loadOrgInfo();
      if(orgInfo?.Id){
        this.sessionStorageService.setSessionStorage(StorageConstants.TenantId, orgInfo?.Id);
      } else if (!this.sessionStorageService.getSessionStorage(StorageConstants.TenantId)){
        this.sessionStorageService.setSessionStorage(StorageConstants.TenantId, environment.commonConfig.tenantId);
      }

      const appDetail = await this.orgAppInfoHelper.loadAppInfo();

      // If the organization ID matches the tenant ID, request the authentication data
      if (orgInfo?.Id?.toLowerCase() === appDetail?.TenantId?.Id?.toLowerCase()) {
        this.requestAuthData();
        const result = await firstValueFrom(race(
          this.authDataSubject.pipe(take(1), map((data: any) => {
            this.setAuthData(data);
            return true;
          })),
          timer(200).pipe(map(() => false))
        ));

        this.authenticationEmitter.next(result);
        return result;
      } else {
        if(!appDetail && !sessionAppCode) {
          this.orgAppInfoHelper.setAppInfo({
            AppName: environment.commonConfig.applicationCode,
            Id: environment.commonConfig.applicationId
          });
        }
        this.router.navigate(['']);
        return false;
      }
    }

    // If the app code is different from the session app code, clear the app cache and load the app
    else if(appCode && sessionAppCode && appCode !== sessionAppCode) {
      const appDetail = await this.orgAppInfoHelper.loadAppInfo();
      const tenantId = this.sessionStorageService.getSessionStorage(StorageConstants.TenantId);

      if (tenantId?.toLowerCase() === appDetail?.TenantId?.Id?.toLowerCase()) {
        this.clearAppCache();
        await this.helperFunctionService.loadApp();
        return true;
      } else {
        this.tostrService.error('You do not have access to this application', 'Error', { positionClass: 'toast-bottom-right' });
        // this.handleSignOut(true);        
        this.router.navigate(['']);
        return false;
      }
    }

    // If the user is authenticated, return true
    return true;
  }

  /**
   * Clears the application cache by resetting relevant properties and clearing IndexedDB.
   */
  private clearAppCache(): void {
    // Reset tab JSON data
    Common.tabJson = null;
    
    // Reset user personalized data
    Common.tabUserPersonalizedData = null;
    
    // Reset user permissions
    Common.tabUserPermissions = null;
    
    // Clear all data from IndexedDB
    this.idbService.clearIndexedDB();
  }

  /** Generates a unique tab ID */
  private generateTabId(): string {
    return Date?.now()?.toString(36) + Math?.random()?.toString(36)?.substr(2);
  }

  /** Requests authentication data from other tabs */
  private requestAuthData(): void {
    this.channel.postMessage({ type: 'AUTH_REQUEST', tabId: this.tabId });
  }

  /**
   * Handles incoming messages from other tabs
   * @param event - The event object containing the message data
   */
  private handleMessage(event: MessageEvent) {
    const { type, data, tabId } = event.data ?? {};
    switch (type) {
      // Another tab requested authentication data
      // If the user is authenticated, send the authentication data
      case 'AUTH_REQUEST':
        if (this.sessionStorageService.getSessionStorage(StorageConstants.token)) {
          this.sendAuthData(tabId);
        }
        break;

      // Received authentication data from another tab
      // If the tab ID matches the current tab, emit the authentication data
      case 'AUTH_DATA':
        if (tabId === this.tabId) {
          this.authDataSubject.emit(data);
        }
        break;

      // Received a sign out event from another tab
      // Handle sign out
      case 'SIGN_OUT':
        this.handleSignOut();
        break;

      // Received a logged in event from another tab
      // Handle logged in
      case 'LOGGED_IN':
        this.handleLoggedIn(data);
        break;

      // Received a token refresh event from another tab
      // Emit the token refresh event
      case 'TOKEN_REFRESH':
        this.tokenRefreshEmitter.emit(data);
        break;
    }
  }

  /** Sends authentication data to the requesting tab */
  private sendAuthData(requestingTabId: string) {
    this.channel.postMessage({
      type: 'AUTH_DATA',
      data: this.getAuthData(),
      tabId: requestingTabId
    });
  }

  /** Sets the authentication data in storage */
  public setAuthData(data: any) {
    const sessionStorage = ['token', 'refreshToken', 'refreshTokenExpiry'];
    sessionStorage.forEach((key) => {
      if (data?.[key]) {
        this.sessionStorageService.setSessionStorage(key, data[key]);
      }
    });

    if(data.userInfo) {
      this.localStorageService.setLocalStorage(StorageConstants.userInfo, data.userInfo);
    }
  }

  /**
   * Handles the sign out event
   *
   * Clears the authentication data from storage and navigates to the sign in page
   * @param activeTab - Whether the current tab is active or not
   */
  private handleSignOut(activeTab?: any) {
    // const tenantId = this.sessionStorageService.getSessionStorage(StorageConstants.TenantId);
    const appCode = this.sessionStorageService.getSessionStorage(StorageConstants.applicationCode);
    // const appId = this.sessionStorageService.getSessionStorage(StorageConstants.AppId);

    // Notify the application that the user has signed out
    this.authenticationEmitter.next(false);

    // Clear the authentication data from storage
    this.clearData();
    // this.clearAppCache();
    
    // Set the tenant ID, app code, and app ID in session storage
    // this.sessionStorageService.setSessionStorage(StorageConstants.TenantId, tenantId);
    // this.sessionStorageService.setSessionStorage(StorageConstants.applicationCode, appCode);
    // this.sessionStorageService.setSessionStorage(StorageConstants.AppId, appId);

    // Determine the URL to navigate to based on the current location
    let url = `/${appCode}/sign-in`;
    const urlParts = window.location.pathname.split(appCode + '/screen/');

    if (urlParts?.[1]) {
      // If the current location is a screen, append the screen ID to the URL
      url += `?redirectURL=${encodeURIComponent(urlParts[1])}`;
    }

    if (activeTab) {
      // If the current tab is active, navigate to the sign in page using the router
      this.router.navigate([url]);
    } else {
      // If the current tab is not active, navigate to the sign in page using window.location
      // window.location.href = url;
    }
  }

  /** Clears the authentication data from storage */
  private clearData(): void {
    // this.sessionStorageService.clearSessionStorage();
    // this.idbService.clearIndexedDB();
    this.sessionStorageService.removeSessionStorage(StorageConstants.token);
    this.sessionStorageService.removeSessionStorage(Constants.refreshToken);
    this.sessionStorageService.removeSessionStorage(Constants.refreshTokenExpiry);
    this.localStorageService.removeLocalStorage(StorageConstants.userInfo);
  }

  /**
   * Handles the logged in event
   *
   * @param {any} data - The authentication data to set in storage
   * @param {any} activeTab - Indicates whether the current tab is active or not
   */
  public handleLoggedIn(data: any, activeTab?: any) {
    // Set the authentication data in storage
    this.setAuthData(data);

    // Notify the application that the user has logged in
    this.authenticationEmitter.next(true);

    const appCode = this.sessionStorageService.getSessionStorage(StorageConstants.applicationCode);

    // Determine the URL to navigate to based on the current location
    let url = `/${appCode}/screen/`;

    // Check if the current location has a redirect URL
    const redirectURL = document?.URL?.toString()?.split('redirectURL=')?.[1];

    if (redirectURL && redirectURL.trim() !== '') {
      // Append the screen ID to the URL
      url += redirectURL;
    }

    if (activeTab) {
      // Navigate to the sign in page using the router
      if(appCode === 'PMS' && !redirectURL){
        url += Constants.DashboardScreen;  
      }

      this.router.navigate([url]);
    } else {
      // Navigate to the sign in page using window.location
      // window.location.href = url;
    }
  }


  /** Broadcasts the sign in event to other tabs */
  broadcastSignIn(data: any) {
    this.handleLoggedIn(data, true);
    this.channel.postMessage({ type: 'LOGGED_IN', data });
  }

  /** Broadcasts the sign out event to other tabs */
  broadcastSignOut() {
    this.handleSignOut(true);
    this.channel.postMessage({ type: 'SIGN_OUT' });
  }

  /** Broadcasts the sign out event to other tabs */
  broadcastSignOutCurrentTab() {
    this.handleSignOut(true);
  }

  /** Broadcasts a token refresh event to other tabs */
  broadcastTokenRefresh(data: any) {
    this.channel.postMessage({ type: 'TOKEN_REFRESH', data });
  }

  /** the authentication data */
  private getAuthData() {
    return {
      userInfo: this.localStorageService.getLocalStorage(StorageConstants.userInfo),
      token: this.sessionStorageService.getSessionStorage(StorageConstants.token),
      refreshToken: this.sessionStorageService.getSessionStorage(Constants.refreshToken),
      refreshTokenExpiry: this.sessionStorageService.getSessionStorage(Constants.refreshTokenExpiry),
    };
  }
}