import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { API_URL, Common, Workflow_URL } from '@techextensor/tab-core-utility';
import { provideAuth } from './core/providers/auth.provider';
import { provideToastr } from 'ngx-toastr';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CacheInterceptor } from './core/interceptors/cache.interceptor';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { ApiStatusInterceptor } from './core/interceptors/api-status.interceptor'; // Update the path as needed

import { environment } from '../environments/environment';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { i18n } from './core/const/constants';
import { Observable, of } from 'rxjs';

export function initApp() {
  return () => Common.init(environment.commonConfig);
}

/**
 * Custom Translate Loader
 * This method will extract the translations JSON from Constant File and return it
 */
export class CustomTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return of(i18n[lang]); // Return the translations for the specified language
  }
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi()), // HTTP client with interceptors
    provideAuth(),
    provideToastr(), // Toastr for notifications

    // Init App
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      multi: true
    },

    // API Configurations
    {
      provide: API_URL,
      useValue: environment.apiUrl
      // useValue: 'http://27.109.17.250:8449/api/v1/'
      // useValue: 'https://pmsapi.techextensor.com/api/v1/'
    },
    {
      provide: Workflow_URL,
      useValue: environment.Workflow_URL
      // useValue: 'https://elsa.techextensor.com/'
    },

    // HTTP Interceptors
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true // Allow multiple interceptors
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true // Allow multiple interceptors
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiStatusInterceptor, // Add the ApiInterceptor here
      multi: true // Allow multiple interceptors
    },
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useClass: CustomTranslateLoader
        }
      })
    )
  ]
};
