import { Injectable, inject } from '@angular/core';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService, SessionStorageService, StorageConstants } from '@techextensor/tab-core-utility';
import { BroadcastChannelService } from './cross-tab-authentication.service';
import { AuthUtils } from '../utils/auth.utils';
import { Constants } from '../const/constants';
import { DOCUMENT } from '@angular/common';

/**
 * Service responsible for managing JWT token refresh across browser tabs
 * Handles token refresh scheduling, cross-tab communication, and session expiry notifications
 */
@Injectable({
  providedIn: 'root'
})
export class TokenRefreshService {
  // Timer reference for scheduling token refreshes
  private refreshTimer: any;
  // Flag to track if refresh token has expired
  private isRefreshTokenExpired: boolean = false;
  // Flag to prevent concurrent refresh operations
  private isRefreshInProgress: boolean = false;

  // Subscription to token refresh events from other tabs
  private refreshTokenSubscription: any;

  // Reference to session expiry popup element
  private popupElement: HTMLElement | null = null;

  // Refresh token 5 minutes before expiration
  private readonly REFRESH_BEFORE = 5 * 60 * 1000; // 5 minutes
  // Maximum random delay for refresh attempts to prevent race conditions
  private readonly MAX_REFRESH_DELAY = 5000; // 5 seconds

  // Injectable dependencies
  private document = inject(DOCUMENT);
  private authService = inject(AuthService);
  private sessionStorageService = inject(SessionStorageService);
  private broadcastChannelService = inject(BroadcastChannelService);

  constructor() {
    this.setupEventListeners();
    this.startRefreshCycle();
  }

  /** 
   * Sets up event listeners for:
   * - Authentication state changes
   * - Token refresh events from other tabs
   * - Online status changes
   * - Tab visibility changes
   */
  private setupEventListeners() {
    // Handle authentication state changes
    this.broadcastChannelService.authenticationEmitter.subscribe((isAuthenticated: boolean) => {
      this.resetServiceState();
    });
  }

  /** 
   * Processes token refresh events received from other tabs
   * @param data Event data containing action and optional token
   */
  private handleTokenRefreshEvent(data: any) {
    switch (data.action) {
      case 'tokenRefreshed': // Another tab successfully refreshed the token
        this.handleTokenRefreshed(data.token);
        break;
      case 'tokenExpired': // Refresh token has expired
        this.handleRefreshTokenExpiration();
        break;
      case 'refreshInProgress': // Another tab is attempting refresh
        this.isRefreshInProgress = true;
        break;
      case 'refreshCompleted': // Refresh attempt completed
        this.isRefreshInProgress = false;
        break;
    }
  }

  /** 
   * Initializes service state from session storage
   * Schedules refresh if valid token exists
   */
  private initializeFromStorage() {
    const token = this.sessionStorageService.getSessionStorage(StorageConstants.token);
    if (token) {
      this.scheduleTokenRefresh();
    }
  }

  /** 
   * Schedules next token refresh based on token expiration
   * Refreshes 5 minutes before expiration if tab is visible
   */
  private scheduleTokenRefresh() {
    // Skip if refresh token is already expired
    if (this.isRefreshTokenExpired) return;

    // Clear existing timer if any
    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer);
    }

    const token = this.sessionStorageService.getSessionStorage(StorageConstants.token);
    if (!token) return;

    const expirationDate = AuthUtils._getTokenExpirationDate(token);
    if (!expirationDate) return;
    
    // Calculate time until refresh needed
    const timeUntilExpiry = expirationDate.getTime() - Date.now();
    const timeUntilRefresh = Math.max(timeUntilExpiry - this.REFRESH_BEFORE, 0);

    this.refreshTimer = setTimeout(() => {
      this.attemptTokenRefresh();
    }, timeUntilRefresh);
  }

  /** 
   * Attempts token refresh with random delay to prevent race conditions
   * Only attempts if no refresh is currently in progress
   */
  private attemptTokenRefresh() {
    if (this.isRefreshInProgress) return;

    const randomDelay = Math.floor(Math.random() * this.MAX_REFRESH_DELAY);
    setTimeout(() => {
      if (!this.isRefreshInProgress) {
        this.isRefreshInProgress = true;
        this.broadcastRefreshInProgress();
        this.refreshToken().subscribe(() => {
          this.isRefreshInProgress = false;
          this.broadcastRefreshCompleted();
        });
      }
    }, randomDelay);
  }

  /** 
   * Performs actual token refresh operation
   * Validates refresh token expiry before attempting
   * @returns Observable<boolean> indicating success/failure
   */
  private refreshToken(): any {
    if (this.isRefreshTokenExpired) {
      return of(false);
    }

    const token = this.sessionStorageService.getSessionStorage(StorageConstants.token)?.slice(7);
    const refreshToken = this.sessionStorageService.getSessionStorage(Constants.refreshToken);
    const refreshTokenExpiry = this.sessionStorageService.getSessionStorage(Constants.refreshTokenExpiry);

    if(!token || !refreshToken || !refreshTokenExpiry) return of(false);

    // Check if refresh token is expired
    if (this.isTokenExpired(refreshTokenExpiry)) {
      this.handleRefreshTokenExpiration();
      this.broadcastTokenExpired();
      return of(false);
    }

    // Attempt token refresh
    return this.authService.refreshToken({
      Token: token, RefreshToken: refreshToken
    })?.pipe(
      switchMap((response: any) => {
        if (response?.Result?.Token) {
          this.handleTokenRefreshed(response?.Result?.Token);
          this.broadcastTokenRefreshed(response?.Result?.Token);
          return of(true);
        }
        this.handleRefreshTokenExpiration();
        this.broadcastTokenExpired();
        return of(false);
      })
    );
  }

  /** 
   * Checks if a token is expired based on expiry date string
   * @param expiryDateString ISO date string of token expiry
   * @returns boolean indicating if token is expired
   */
  private isTokenExpired(expiryDateString: string | null): boolean {
    if (!expiryDateString) return true;
    const expiryTimestamp = Date.parse(expiryDateString);
    return isNaN(expiryTimestamp) || Date.now() > expiryTimestamp;
  }

  /** 
   * Handles refresh token expiration
   * Shows expiry popup and stops refresh cycle
   */
  private handleRefreshTokenExpiration() {
    if (!this.isRefreshTokenExpired) {
      this.isRefreshTokenExpired = true;
      this.stopRefreshCycle();
      this.showPopup();
    }
  }

  /** 
   * Processes successfully refreshed token
   * Updates storage and schedules next refresh
   * @param token New JWT token
   */
  private handleTokenRefreshed(token: string) {
    this.sessionStorageService.setSessionStorage(StorageConstants.token, 'Bearer ' + token);
    this.scheduleTokenRefresh();
  }

  private startRefreshCycle() {
    // Handle token refresh events from other tabs
    this.refreshTokenSubscription = this.broadcastChannelService.tokenRefreshEmitter.subscribe(this.handleTokenRefreshEvent.bind(this));
    // Initialize from storage
    this.initializeFromStorage();
  }

  /** 
   * Stops the token refresh cycle
   * Cleans up timers and event listeners
   */
  private stopRefreshCycle() {
    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer);
    }
    this.isRefreshInProgress = false;
    if(this.refreshTokenSubscription) {
      this.refreshTokenSubscription?.unsubscribe();
      this.refreshTokenSubscription = null;
    }
  }

  /** 
   * Broadcasts refresh in progress status to other tabs
   */
  private broadcastRefreshInProgress() {
    this.broadcastChannelService.broadcastTokenRefresh({ action: 'refreshInProgress' });
  }

  /** 
   * Broadcasts refresh completion status to other tabs
   */
  private broadcastRefreshCompleted() {
    this.broadcastChannelService.broadcastTokenRefresh({ action: 'refreshCompleted' });
  }

  /** 
   * Broadcasts refreshed token to other tabs
   * @param token New JWT token
   */
  private broadcastTokenRefreshed(token: string) {
    this.broadcastChannelService.broadcastTokenRefresh({
      action: 'tokenRefreshed',
      token: token
    });
  }

  /** 
   * Broadcasts token expired status to other tabs
   */
  private broadcastTokenExpired() {
    this.broadcastChannelService.broadcastTokenRefresh({ action: 'tokenExpired' });
  }

  /** 
   * Resets service to initial state
   * Used when re-authenticating
   */
  public resetServiceState() {
    this.isRefreshTokenExpired = false;
    this.isRefreshInProgress = false;
    this.stopRefreshCycle();
    this.startRefreshCycle();
    this.removePopup();
  }

  /** 
   * Shows session expiry popup
   * Auto-dismisses after 3 minutes
   */
  private showPopup() {
    if (this.popupElement) return;

    // Create popup element with warning message and login link
    this.popupElement = this.document.createElement('div');
    this.popupElement.innerHTML = `<div style="position: fixed; top: 0; left: 0; right: 0; background-color: #FFF4E5; border-bottom: 1px solid #FFB800; padding: 6px 16px; z-index: 9999; display: flex; justify-content: space-between; align-items: center;">
      <div style="display: flex; align-items: center;">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#FFB800" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
          <line x1="12" y1="9" x2="12" y2="13"></line>
          <line x1="12" y1="17" x2="12.01" y2="17"></line>
        </svg>
        <span style="margin-left: 12px; color: #533F04; font-size: 14px;">Your session is about to expire. <a id="sessionLoginLink" style="text-decoration: underline; color: #0052CC; font-weight: 500; cursor: pointer;">Click here to log-in</a></span>
      </div>
      <button id="closePopupBtn" style="background: none; border: none; cursor: pointer; padding: 0;">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#6B778C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </button>
    </div>`;

    this.document.body.appendChild(this.popupElement);

    // Add click handler for login link
    const loginLink = this.popupElement.querySelector('#sessionLoginLink');
    if (loginLink) {
      loginLink.addEventListener('click', (e) => {
        e.preventDefault();
        this.redirectToLogin();
      });
    }

    // Add click handler for close button
    const closeBtn = this.popupElement.querySelector('#closePopupBtn');
    if (closeBtn) {
      closeBtn.addEventListener('click', () => this.removePopup());
    }

    // Auto-dismiss after 3 minutes
    setTimeout(() => this.removePopup(), 3 * 60 * 1000);
  }

  /** 
   * Redirects to login page and broadcasts sign-out
   */
  private redirectToLogin() {
    this.broadcastChannelService.broadcastSignOut();
    this.removePopup();
  }

  /** 
   * Removes session expiry popup
   */
  private removePopup() {
    if (this.popupElement && this.popupElement.parentNode) {
      this.popupElement.parentNode.removeChild(this.popupElement);
      this.popupElement = null;
    }
  }
}