import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { SessionStorageService, StorageConstants } from '@techextensor/tab-core-utility';

/**
 * Guard that checks if the user is authenticated.
 * If authenticated, allows access to the route.
 * If not authenticated, redirects to the sign-in page with an optional redirect URL.
 */
export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const sessionStorageService = inject(SessionStorageService);

  return authService.check().pipe(
    map(authenticated => {
      if (authenticated) {
        return true;
      } else {

        const appCode = sessionStorageService.getSessionStorage(StorageConstants.applicationCode);
        let url = `/${appCode}/sign-in`;
        const urlParts = window?.location?.pathname?.split(appCode + '/screen/');
                        
        if (urlParts?.[1]) {
          url += `?redirectURL=${encodeURIComponent(urlParts[1])}`;
        }
        
        return router.parseUrl(url);
      }
    })
  );
};